import styled from "styled-components";
import BackgroundInit from '../../../assets/images/mobileInitBackground.png'
import BackgroundWall from '../../../assets/images/black-brick-wall-textured-background1.png'
import BackgroundConnectivity from '../../../assets/images/ConnectivyMobile.png'
import BackgroundCOnnectivityIpad from '../../../assets/images/ConnectivityIpad.png'
import { createGlobalStyle } from "styled-components";

/*Fonts*/
import Montserrat from '../../../assets/fonts/Montserrat-Bold.ttf'
import Nunito from '../../../assets/fonts/Nunito-Regular.ttf'
import NunitoBold from '../../../assets/fonts/Nunito-Bold.ttf'

console.log(Montserrat)

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: Montserrat;
    font-style: bold;
    font-weight: 700;
    src: url(${Montserrat});
  }

  @font-face{
    font-family: Nunito;
    font-style: regular;
    src: url(${Nunito});
  }
  @font-face{
    font-family: Nunito;
    font-style: bold;
    font-weight: 700;
    src: url(${NunitoBold});
  }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #E5E5E5;
    width: 100%;
`

export const ContainerInitMobile = styled.div`
    background: url(${BackgroundInit});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
`

export const ContentMobile = styled.div`
    width: 90%;
    max-width: 500px;
    display: flex;  
    flex-direction: column;
    @media(min-width: 760px){
        width: 700px;
        max-width: 700px;
    }
    @media(min-width: 1000px){
        width: 900px;
        max-width: 900px;
    }
`

export const TextContentInitMobile = styled.div`
    height: 100vh;
    width: 90%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    @media(min-width: 760px){
        width: 700px;
        max-width: 700px;
    }
    @media(min-width: 1000px){
        width: 900px;
        max-width: 900px;
    }
`

export const TitleHeader = styled.div`
`

export const TitleHeaderFlex = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: -40px;
    margin-bottom: 10px;
    text-align: left;
    align-items: flex-end;
    @media(min-width: 760px){
        flex-direction: column;
    }
    @media(min-width: 900px){
        display: flex;
        flex-direction: row;
    }
`

export const TitleMobile = styled.h1`
    color: #414141;
    font-size: 6vw;
    font-family: 'Montserrat';
    @media(min-width: 900px){
        font-size: 50px;
    }
`

export const TitleWithGradientMobile = styled.h1`
    font-family: 'Montserrat';
    font-size: 6vw;
    background: -webkit-linear-gradient(45deg, #3E93D8, #4CB931);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media(min-width: 900px){
        font-size: 50px;
    }
` 

export const TextMobile = styled.p`
    font-size: 4.5vw;
    font-family: 'Nunito';
    color: #414141;
    @media(min-width: 900px){
        font-size: 37px;
    }
`

export const TextWithGradientMobile = styled.p`
    font-weight: 800;
    font-size: 4.5vw;
    font-family: 'Nunito';
    background: -webkit-linear-gradient(45deg, #3E93D8, #4CB931);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media(min-width: 900px){
        font-size: 35px;
    }
`

export const Lines = styled.img`
    width: 90vw;
    margin-top: 50px;
    @media(min-width: 768px){
        width: 500px;
        height: 350px;
    }
    @media(min-width: 1000px){
        width: 700px;
        heigth: 630px;
    }
`
export const LinesBottom = styled.img`
    width: 70%;
    position: absolute; 
    margin-left: 10%; 
    margin-top: 0px;
    @media(min-width: 768px){
        width: 400px;
        height: 350px;
        margin-left: 20%;
    }
    @media(min-width: 1000px){
        width: 500px;
        heigth: 630px;
        margin-top: -50px;
    }
`

export const OriginBtnsContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;  
    align-items: center;
    position: relative;
`

export const OriginBtnRobotMobile = styled.button<{ borderSize: number}>`
    width: 13vw;
    height: 13vw;
    border-radius: 50%;
    background: #EFEFEF;
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px #CBCBCB;
    border: none;
    border: ${props=>(props.borderSize)}px solid #66C9ED;
    cursor: pointer;
    position: absolute;
    left: 7vw;
    top: 23vw;
    :hover{
        border: 1px solid rgba(102, 201, 237, 0.5);
    }
    @media(min-width: 410px),
    @media(min-height: 736px){
        left: 7vw;
        top: 21vw;
    }
    @media(min-width: 768px){
        width: 80px;
        height: 80px;
        top: 100px;
        left: 130px;
    }
    @media(min-width: 1000px){
        width: 100px;
        height: 100px;
        top: 85px;
        left: 160px;
    }
`

export const OriginBtnBrainMobile = styled.button<{ borderSize: number}>`
    width: 13vw;
    height: 13vw;
    border-radius: 50%;
    background: #EFEFEF;
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px #CBCBCB;
    border: none;
    border: ${props=>(props.borderSize)}px solid #66C9ED;
    cursor: pointer;
    position: absolute;
    right: 25vw;
    top: 10vw;
    :hover{
        border: 1px solid rgba(102, 201, 237, 0.5);
    }
    @media(min-width: 374px),
    @media(min-height: 660px){
        right: 25vw;
        top: 7vw;
    }
    @media(min-width: 410px),
    @media(min-height: 736px){
        right: 25vw;
        top: 6vw;
    }
    @media(min-width: 768px){
        width: 80px;
        height: 80px;
        top: 10px;
        right: 235px;
    }
    @media(min-width: 1000px){
        width: 100px;
        height: 100px;
        top: 16px;
        right: 293px;
    }
`


export const OriginBtnWifiMobile = styled.button<{ borderSize: number}>`
    width: 13vw;
    height: 13vw;
    border-radius: 50%;
    background: #EFEFEF;
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px #CBCBCB;
    border: none;
    border: ${props=>(props.borderSize)}px solid #66C9ED;
    cursor: pointer;
    position: absolute;
    left: 25vw;
    top: 47vw;
    :hover{
        border: 1px solid rgba(102, 201, 237, 0.5);
    }
    @media(min-width: 374px),
    @media(min-height: 660px){
        left: 23vw;
        top: 46vw;
    }
    @media(min-width: 410px),
    @media(min-height: 736px){
        left: 23vw;
        top: 45vw;
    }
    @media(min-width: 768px){
        width: 80px;
        height: 80px;
        top: 250px;
        left: 230px;
    }
    @media(min-width: 1000px){
        width: 100px;
        height: 100px;
        top: 235px;
        left: 285px;
    }
`

export const OriginBtnCloudMobile = styled.button<{ borderSize: number}>`
    width: 13vw;
    height: 13vw;
    border-radius: 50%;
    background: #EFEFEF;
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px #CBCBCB;
    border: none;
    border: ${props=>(props.borderSize)}px solid #66C9ED;
    cursor: pointer;
    position: absolute;
    right: 8vw;
    top: 41vw;
    :hover{
        border: 1px solid rgba(102, 201, 237, 0.5);
    }
    @media(min-width: 768px){
        width: 80px;
        height: 80px;
        top: 210px;
        right: 140px;
    }
    @media(min-width: 1000px){
        width: 100px;
        height: 100px;
        top: 210px;
        right: 170px;
    }
`

export const IconBtn = styled.img`
    width: 5vw;
    height: 5vw;
    color: #282828;
    @media(min-width: 768px){
        width: 40px;
        height: 40px;
    }
`

export const Card = styled.div`
    width: 90%;
    height: 50%;
    background: #EFEFEF;
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px #CBCBCB;
    border-radius: 30px;   
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    @media(min-width: 900px){
        height: 40%;
    }
`

export const CardTitle = styled.p`
    font-family: 'Montserrat';
    font-size: 4.85vw;
    margin: 20px;
    margin-top: 50px;
    background: -webkit-linear-gradient(45deg, #3E93D8, #4CB931);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media(min-width: 900px){
        font-size: 40px;
    }
`

export const CardText = styled.p`
    font-size: 4.4vw;
    font-family: 'Nunito';
    width: 90%;
    text-align: center;
    margin-top: 30px;
    line-height: 1.4;
    @media(min-width: 500px){
        font-size: 3.5vw;
    }
    @media(min-width: 900px){
        font-size: 35px;
    }
`
export const LargeText = styled.text`
    font-size: 8vw;
    color: #414141;
    margin-top: 150px;
    font-family: 'Montserrat';
    @media(min-width: 760px){
        margin-top: 250px;
    }
    @media(min-width: 900px){
        font-size: 50px;
        margin-top: 300px;
    }

`

export const LargeTextWithGradient = styled.text`
    font-size: 9vw;
    background: -webkit-linear-gradient(45deg, #3E93D8, #4CB931);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat';
    font-weight: 700;
    @media(min-width: 900px){
        font-size: 60px;
    }

`

export const SmartFactoryScroll = styled.div`
    width: 100%;
    overflow-x: hidden;
`

export const SmartFactory = styled.div<{value: number}>`
    width: 300vw;
    transform: translateX(-${props=>props.value}vw);
    transition: .1s linear;
`

export const LargeCircle = styled.div`
    width: 40vw;
    height: 40vw;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #EFEFEF;
    margin: 20px;
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px #CBCBCB;
`

export const PercentageText = styled.div`
    color:#4CB931;
    font-size: 12vw;
    font-weight: 700;
    font-family: 'Montserrat';
`

export const PercentageContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @media(min-width: 760px){
        margin-top: 100px;
    }
`

export const SecurityContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1C1C1C;
    width: 100%;
`

export const SecurityShild = styled.div`
    width: 35vw;
    height: 35vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    border-radius: 50%;
    background: #1C1C1C;
    box-shadow: -10px -10px 30px #252525, 10px 10px 30px #0D0D0D;

`

export const Img = styled.img`
    z-index: 2000;
`


export const SecurityTitle = styled.h1`
    color: #FFF;
    font-family: 'Montserrat';
    margin: 3vh;
    font-size: 10vw;
    text-align: center;
    @media(min-width: 768px){
        font-size: 8vw;
    }
    @media(min-width: 1000px){
        font-size: 70px;
    }
`

export const SecurityText = styled.p`
    font-size: 5vw;
    color: #FFF;
    font-family: 'Nunito';
    margin: 20px;
    text-align: center;
    @media(min-width: 1000px){
        font-size: 40px;
        margin-top: 50px;
    }
`
export const SecurityItemTitle = styled.p`
    color: #FFF;
    font-weight: 700;
    font-size: 4vw;
    font-family: 'Montserrat';
    margin-bottom: 20px;
    width: 55vw;
`

export const SecurityItemText = styled.p`
    font-size: 3.6vw;
    color: #FFF;
    font-family: 'Nunito';
    width: 55vw;
`

export const SecurityCarouselContent = styled.div`
    display: flex;
    transition: .5s linear;
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none; 
    }
`

export const SecurityCard = styled.div`
    width: 60vw;
    margin: 5vw;
`

export const ContainerWithWall = styled.div`
    background: url(${BackgroundWall});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    @media(max-width: 760px){
        object-fit: contain;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    @media(min-width: 760px){
        object-fit: contain;
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media(min-width: 1000px){
        object-fit: contain;
        padding-top: 130px;
        padding-bottom: 130px;
    }
`


export const ContainerConnectivity = styled.div`
    background: url(${BackgroundConnectivity});
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    position: relative;
    @media(min-width: 760px){
        background: url(${BackgroundCOnnectivityIpad});
        background-repeat: no-repeat;
        background-size: cover;
    }
`

export const ConnectivityTitleScroll = styled.h1`
    color: #FFF;
    font-family: 'Montserrat';
    margin: 20px;
    margin-top: 30px;
    font-size: 9vw;
    top: 384px;
    z-index: 90;
`

export const CarouselAppContainer = styled.div`
    background: #E5E5E5;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 9vh;
    `

export const CarouselBtnsContent = styled.div`
    margin-top: -5px;
`

export const CarouselBtn = styled.button<{color: string}>`
    width: 3vw;
    height: 3vw;
    border-radius: 50%;
    cursor: pointer;
    margin: 5px;
    border: none;
    background: ${props => props.color};
`

export const CarouselImg = styled.img`
    width: 40%;
    margin: 30px;
    object-fit: contain;
    @media(min-width: 760px){
        margin-top: 45px;
        margin-bottom: 45px;
    }
    @media(min-width: 760px){
        margin-top: 55px;
        margin-bottom: 55px;
    }
`

export const CarouselCard = styled.div`
    display: flex;
    flex-direction: row;
    width: 55vw;
    margin-top: 10vh;
    height: 50vh;
    justify-content: space-between;
    position: relative;
`

export const CarouselInfoContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const CarouselCardTextContent = styled.div`
    width: 100%;
`

export const CarouselTitle = styled.h1`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 6vw;
    color: rgba(65, 65, 65, 1);
    text-align: left;
    margin-top: 10px;
    @media(min-width: 760px){
        font-size: 40px;
    }
    @media(min-width: 1000px){
        font-size: 45px;
    }
`

export const ConceptText = styled.p`
    font-family: 'Montserrat';
    color: rgba(153, 153, 153, 1);
    font-size: 3vw;
    text-transform: uppercase;
`

export const CarouselText = styled.p`
    margin-top: 10px;
    font-family: 'Nunito';
    color: rgba(65, 65, 65, 1);
    font-size: 4vw;
    text-align: left;
`


export const ContactBtnIcon = styled.img`
    height: 3vw;
    margin-right: 20px;
`
export const ContactBtnWhatsAppIcon = styled.img`
    height: 3.25vw;
    margin-right: 20px;
    margin-left: -1.5vw;
`

export const MailBtn = styled.div`
    height: 13vw;
    width: 50vw;
    border: 2px solid rgba(62, 147, 216, 0.4);
    background: none;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    box-shadow: -10px -10px 30px #F9F9F9, 10px 10px 20px rgba(217, 217, 217, 0.25);
    :hover{
        border-color: rgba(62, 147, 216, 1);
    }
`

export const WhatsappBtn = styled.div`
    height: 13vw;
    width: 50vw;
    margin: 20px;
    border: 2px solid rgba(76, 185, 49, 0.4);
    background: none;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    box-shadow: -10px -10px 30px #F9F9F9, 10px 10px 20px rgba(217, 217, 217, 0.25);
    :hover{
        border-color: rgba(76, 185, 49, 1);
    }
`

export const ContactBtnText = styled.p`
    color: #000;
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 3vw;
`

export const FooterContainer = styled.div`
    width: 100%;

    background-color: #3E93D8;
    padding: 2vw 15vw;
    box-sizing: border-box;
`

export const CompanyCopyright = styled.p`
    font-family: 'Nunito';
    color: #FFFFFF;
    font-size: 14px;
`

export const ArtCopyright = styled.a`
    font-family: 'Nunito';
    color: #FFFFFF;
    font-size: 12px;
    text-decoration: none;
`