import React, { useEffect, useRef, useState } from 'react'
import * as Body from './Styles'
import SmartFactory from '../../../components/svg/smartFactory/SmartFactory'
import Shild from '../../../assets/icons/shild.svg'
import BarbedWire from '../../../assets/images/barbed-wire.png'
import CloudComputingAbstract from '../../../assets/icons/cloud-computing-security-abstract.svg'
import EmailIcon from '../../../assets/icons/Email.svg'
import WhatsappIcon from '../../../assets/icons/Whatsapp.svg'

import Carousel1Image from '../../../assets/images/carousel/Carousel1.png'
import Carousel2Image from '../../../assets/images/carousel/Carousel2.png'
import Carousel3Image from '../../../assets/images/carousel/Carousel3.png'
import Carousel4Image from '../../../assets/images/carousel/Carousel4.png'
import Carousel5Image from '../../../assets/images/carousel/Carousel5.png'
import Carousel6Image from '../../../assets/images/carousel/Carousel6.png'

import Lines from  '../../../assets/images/Lines.png'

//Icons
import CloudIcon from '../../../assets/icons/cloud-solid.svg'
import WifiIcon from '../../../assets/icons/wifi-solid.svg'
import BrainIcon from '../../../assets/icons/brain-solid.svg'
import RobotIcon from '../../../assets/icons/robot-solid.svg'
import CloudSelectedIcon from '../../../assets/icons/cloud-solid-selected.svg'
import WifiSelectedIcon from '../../../assets/icons/wifi-solid-selected.svg'
import BrainSelectedIcon from '../../../assets/icons/brain-solid-selected.svg'
import RobotSelectedIcon from '../../../assets/icons/robot-solid-selected.svg'
import IndustryIllustration from '../../../components/svg/industry4.0Ilustration/IndustryIllustration'

function Init(){
    return(
        <Body.ContainerWithImage>
            <Body.Content style={{width: '90vw'}}>
                <Body.TextContentInit>
                    <Body.TitleHeader>
                        <Body.Title style={{letterSpacing: 5, fontSize: '3vw'}}>INFRAESTRUTURA 4.0</Body.Title>
                        <Body.TitleWithGradient style={{fontSize: '2.7vw'}}>Sua indústria. Inteligente.</Body.TitleWithGradient>
                    </Body.TitleHeader>

                    <Body.Text>
                        <strong> A última revolução industrial transformou a forma com que as indústrias operam</strong>, trazendo mais
                        produtividade e ganhos com o auxílio das tecnologias digitais.
                    </Body.Text>

                    <div>
                    <Body.WithoutBrokenLine>
                        <Body.Text>
                            Saiba mais como nós do
                        </Body.Text>
                        <Body.TextWithGradient style={{marginLeft: 10, fontSize: '2vw'}}>
                            Grupo Tectrol e Dínamo</Body.TextWithGradient>
                    </Body.WithoutBrokenLine>
                        <Body.Text>
                            estamos trabalhando para trazer essa realidade na sua indústria.
                        </Body.Text>
                    </div>
                </Body.TextContentInit>
            </Body.Content>
        </Body.ContainerWithImage>
    )
}

function Origin(){

    const [dataCard, setDataCard] = useState(1)
    const [cloudIcon, setCloudIcon] = useState(CloudIcon)
    const [robotIcon, setRobotIcon] = useState(RobotIcon)
    const [brainIcon, setBrainIcon] = useState(BrainSelectedIcon)
    const [wifiIcon, setWifiIcon] = useState(WifiIcon)

    useEffect(()=>{
        if(dataCard === 1){
            setBrainIcon(BrainSelectedIcon)
            setCloudIcon(CloudIcon)
            setRobotIcon(RobotIcon)
            setWifiIcon(WifiIcon)
        }
        if(dataCard === 2){
            setBrainIcon(BrainIcon)
            setCloudIcon(CloudIcon)
            setRobotIcon(RobotSelectedIcon)
            setWifiIcon(WifiIcon)
        }
        if(dataCard === 3){
            setBrainIcon(BrainIcon)
            setCloudIcon(CloudIcon)
            setRobotIcon(RobotIcon)
            setWifiIcon(WifiSelectedIcon)
        }
        if(dataCard === 4){
            setBrainIcon(BrainIcon)
            setCloudIcon(CloudSelectedIcon)
            setRobotIcon(RobotIcon)
            setWifiIcon(WifiIcon)
        }

        if(dataCard !== helpCurrent.current){
            helpCurrent.current = dataCard
            clear()
        }
    },[dataCard])

    const helpCurrent = useRef(1)
    const intervalId = useRef<NodeJS.Timeout>()

    useEffect(()=>{
        const interval = setInterval(()=>{
            if(helpCurrent.current < 4){
                helpCurrent.current += 1;
                setDataCard(helpCurrent.current)
            }else{
                helpCurrent.current = 1
                setDataCard(1)
            }
        }, 10000)

        intervalId.current = interval

        return function cleanUp(){
            clearInterval(intervalId.current!)
        }
    },[])

    const clear = () => {
        clearInterval(intervalId.current!)

        const interval = setInterval(()=>{
            if(helpCurrent.current < 4){
                helpCurrent.current += 1;
                setDataCard(helpCurrent.current)
            }else{
                helpCurrent.current = 1
                setDataCard(1)
            }
        }, 10000)

        intervalId.current = interval

    } 


    const getDataCard = () => {
        switch(dataCard){
            case 1:
                return(
                    <>
                        <Body.CardTitle>Inteligência Artificial</Body.CardTitle>
                        <Body.CardText>
                            O computador <strong>aprende como sua indústria funciona</strong>, e é capaz de dar sugestões preditivas e até mesmo realizar ações imediatas de forma a <strong>melhorar o seu processo produtivo</strong>
                        </Body.CardText>
                    </>
                )
            case 2:
                return(
                    <>
                        <Body.CardTitle>Robótica</Body.CardTitle>
                        <Body.CardText>
                            Máquinas e robôs <strong>guiados através de algoritmos de aprendizagem de máquina</strong> podem atuar de maneira mais efetiva na sua indústria, trazendo <strong>ganhos inimagináveis</strong>
                        </Body.CardText>
                    </>
                )
            case 3:
                return(
                    <>
                        <Body.CardTitle>Internet das Coisas</Body.CardTitle>
                        <Body.CardText>
                            Saiba tudo o que acontece na sua indústria <strong>onde quiser e quando quiser</strong>. Conecte seu maquinário em uma rede interligada que <strong>aprende sozinha</strong> e fornece dados de <strong>monitoramento contínuo</strong>.
                        </Body.CardText>
                    </>
                )
            case 4:
                return(
                    <>
                        <Body.CardTitle>Cloud Computing</Body.CardTitle>
                        <Body.CardText>
                        <strong> Esqueça o investimento em aquisição e manutenção de servidores</strong> e vários computadores locais que só ocupam espaço e poeira na sua indústria. Com o 4.0, <strong>todo o processamento e armazenamento é feito na internet</strong>.
                        </Body.CardText>
                    </>
                )
        }
    }

    return(
        <>
        <Body.Content style={{alignItems: 'start'}}>
            <Body.ContentRowOrigin style={{marginBottom: '20vh'}}>
                <Body.DivisionContent style={{height: 200}}>
                    <Body.TitleHeader style={{display: 'flex', flexDirection: 'row', justifyContent : 'center'}}>
                        <Body.Title>A origem do </Body.Title>
                        <Body.TitleWithGradient style={{marginLeft: 10}}>4.0</Body.TitleWithGradient>
                    </Body.TitleHeader>
                </Body.DivisionContent>
                <Body.DivisionContent style={{height: 200}}>
                    <Body.Text style={{marginLeft: '10%',width: '90%'}}>
                    O termo 4.0 vem da <strong>Quarta Revolução Industrial</strong>, que se refere ao uso de tecnologias digitais
                    avançadas como <strong>inteligência artificial, robótica, internet das coisas</strong> e <strong>computação em nuvem</strong>.
                    </Body.Text>
                </Body.DivisionContent>
            </Body.ContentRowOrigin>

            <Body.ContentRowOrigin>
                <Body.DivisionContent style={{left: 0, justifyContent: 'flex-end'}}>
                    <Body.OriginBtnsContent>
                        <Body.Img src={Lines} style={{ width: '60%'}}/>
                        <Body.OriginBtn borderSize={(dataCard === 1 ? 2 : 0)} style={{top: '-5%', right: '30%'}} onClick={() => setDataCard(1)}>
                            <Body.IconBtn src={brainIcon} />
                        </Body.OriginBtn>
                        <Body.OriginBtn borderSize={(dataCard === 2 ? 2 : 0)} style={{top: '15%', left: '5%'}} onClick={() => setDataCard(2)}>
                            <Body.IconBtn src={robotIcon} />
                        </Body.OriginBtn>
                        <Body.OriginBtn borderSize={(dataCard === 3 ? 2 : 0)} style={{top: '33%', right: '30%'}} onClick={() => setDataCard(3)}>
                            <Body.IconBtn src={wifiIcon} />
                        </Body.OriginBtn>
                        <Body.OriginBtn borderSize={(dataCard === 4 ? 2 : 0)} style={{top: '53%', left: '5%'}} onClick={() => {setDataCard(4)}}>
                            <Body.IconBtn src={cloudIcon} />
                        </Body.OriginBtn>
                    </Body.OriginBtnsContent>
                </Body.DivisionContent>
                <Body.DivisionContent>
                    <Body.Card>
                        {getDataCard()}
                    </Body.Card>
                </Body.DivisionContent>
            </Body.ContentRowOrigin>
            <Body.Title style={{fontSize: 48, fontWeight: 400}}>
                Juntas, elas se tornam uma poderosa ferramenta para mudar sua operação
                fabril. É por isso que aqui, nós gostamos de chamar a indústria 4.0 de
            </Body.Title>
            <Body.TitleWithGradient style={{fontSize: 60}}>
                Indústria Inteligente
            </Body.TitleWithGradient>
        </Body.Content>
        <SmartFactory />
        </>
    )
}

function OneSolution (){
    return(
        <Body.Content >
            <Body.ContentRowOrigin>
                <Body.DivisionContent style={{display: 'flex', flexDirection: 'column'}}>
                    <Body.Title>Uma solução</Body.Title>
                    <Body.TitleHeader style={{display: 'flex', flexDirection: 'row'}}>
                        <Body.Title>para</Body.Title>
                        <Body.TitleWithGradient style={{marginLeft: 10}}>todos</Body.TitleWithGradient>
                    </Body.TitleHeader>
                </Body.DivisionContent>
                <Body.DivisionContent >
                    <Body.Text style={{ width: '90%'}}>
                    Não importa o grau de automação da sua indústria,<strong> nós temos o que é necessário para torná-la uma indústria inteligente</strong>. Nossos equipamentos são compatíveis com <strong>qualquer padrão de automação existente</strong>.
                    </Body.Text>
                </Body.DivisionContent>
            </Body.ContentRowOrigin>

            <Body.PercentageContent>
                <Body.LargeCircle>
                    <Body.Text style={{fontSize: '1.5vw'}}>
                        aumento de
                    </Body.Text>
                    <Body.PercentageText>
                        25%
                    </Body.PercentageText>
                    <Body.Text style={{fontSize: '1.5vw'}}>
                        em produtividade
                    </Body.Text>
                </Body.LargeCircle>
                <Body.LargeCircle>
                    <Body.Text style={{fontSize: '1.5vw'}}>
                        redução de
                    </Body.Text>
                    <Body.PercentageText>
                        40%
                    </Body.PercentageText>
                    <Body.Text style={{fontSize: '1.5vw'}}>
                        em manutenção
                    </Body.Text>
                </Body.LargeCircle>
                
                <Body.LargeCircle>
                    <Body.Text style={{fontSize: '1.5vw'}}>
                        redução de
                    </Body.Text>
                    <Body.PercentageText>
                        50%
                    </Body.PercentageText>
                    <Body.Text style={{fontSize: '1.5vw'}}>
                        em máquina parada
                    </Body.Text>
                </Body.LargeCircle>
            </Body.PercentageContent>

            <Body.ContentRowOrigin style={{margin: 50}}>
                <Body.DivisionContent >
                    <IndustryIllustration />
                </Body.DivisionContent>
                <Body.DivisionContent style={{alignItems: 'center'}}>
                    <Body.Title style={{fontSize: '3.5vw', textAlign: 'right', width: '90%', marginTop: 50}}>
                        Deixe o trabalho pesado para as máquinas       
                    </Body.Title>
                </Body.DivisionContent>
            </Body.ContentRowOrigin>
            <Body.TextAlignCenter>
                Com a <strong>inteligência artificial</strong>, nossos algoritmos conseguem trabalhar em tempo real na sua
                indústria, ajustando padrões e criando novos que você nem sabia que existia – <strong>tudo isso
                para maximizar a sua produtividade</strong>
            </Body.TextAlignCenter>
            <Body.TextAlignCenter style={{marginBottom: 70}}>
                E tudo isso é feito praticamente <strong>sem intervenção
                humana</strong>, então você não precisa mais se preocupar em ficar com alguém analisando seus
                equipamentos 24/7 para garantir que eles funcionem
            </Body.TextAlignCenter>
        </Body.Content>
    )
}

function Security(){
    return(
        <Body.SecurityContainer>
            <Body.Content style={{alignItems: 'center'}}>
                <Body.SecurityShild>
                    <Body.Img src={Shild} style={{width: '11vw'}}/>
                </Body.SecurityShild>
                <Body.SecurityTitle>Segurança em primeiro lugar</Body.SecurityTitle>
                <Body.SecurityText>
                    Nós aplicamos algoritmos de monitoramento de anomalias <strong>todos os pontos do nosso sistema</strong>,
                    permitindo que eles identifiquem possíveis <strong>ataques de invasores</strong>, <strong>uso incorreto do
                    equipamento</strong> ou até mesmo <strong>funcionamento inadequado</strong>
                </Body.SecurityText>

                <Body.ContentRowSecurity>
                    <Body.DivisionContentSecurity style={{textAlign: 'left'}}>
                        <Body.SecurityItemTitle>
                            Anos de experiência aplicada em automação
                        </Body.SecurityItemTitle>
                        <Body.SecurityItemText>
                            Nossos algoritmos foram desenvolvidos baseado nos <strong>mais de 25 anos de experiência</strong> no mercado de automação e manutenção, garantindo confiabilidade nos resultados aplicados
                        </Body.SecurityItemText>
                    </Body.DivisionContentSecurity>
                    <Body.DivisionContentSecurity style={{textAlign: 'right'}}>
                        <Body.SecurityItemTitle>
                            Uma equipe virtual dentro das máquinas
                        </Body.SecurityItemTitle>
                        <Body.SecurityItemText>
                            Quando algo imprevisivel acontecer, <strong>o próprio sistema se encarrega de tentar solucionar o problema </strong>sem
                            intervenção humana, garantindo que seus equipamentos funcionem sem problemas
                        </Body.SecurityItemText>
                    </Body.DivisionContentSecurity>
                </Body.ContentRowSecurity>
                <Body.ContentRowSecurity>
                    <Body.DivisionContentSecurity style={{textAlign: 'left'}}>
                        <Body.SecurityItemTitle>
                         Seus dados guardados a sete chaves
                        </Body.SecurityItemTitle>
                        <Body.SecurityItemText>
                            De ponta a ponta, <strong>todos os dados  transmitidos do seu equipamento trafegam em um canal seguro</strong>, e são <strong>criptografados</strong> utilizando as últimas tecnologias em <strong>ofuscamento e segurança digital</strong>
                        </Body.SecurityItemText>
                    </Body.DivisionContentSecurity>
                    <Body.DivisionContentSecurity style={{textAlign: 'right'}}>
                        <Body.SecurityItemTitle>
                            Fique sabendo de tudo o que acontece
                        </Body.SecurityItemTitle>
                        <Body.SecurityItemText>
                            Em qualquer situação de emergência, até mesmo que aquelas que resolvemos automaticamente, <strong>você e sua equipe serão notificados instantaneamente do ocorrido</strong>
                        </Body.SecurityItemText>
                    </Body.DivisionContentSecurity>
                </Body.ContentRowSecurity>

                <Body.SecurityRow>
                    <Body.Img src={CloudComputingAbstract} style={{width: '14vw'}} />
                    <Body.SecurityTitle style={{fontSize: '1.9vw', width: '70%'}}>
                        Assim fica fácil ficar relaxado,<br/> não é mesmo?
                    </Body.SecurityTitle>
                </Body.SecurityRow>
            </Body.Content>
            <Body.ContainerWithWall>
                <Body.Img src={BarbedWire} style={{width: '100%', height: 50, position: 'absolute', top: -30}}/>
                <Body.Content>
                    <Body.ContentRowOrigin style={{alignItems: 'center'}}>
                        <Body.DivisionContent>
                            <Body.SecurityTitle >
                                Deixe os bisbilhoteiros pra fora
                            </Body.SecurityTitle>
                            <Body.TitleWithGradient style={{margin: '3vh', fontSize: '1.5vw'}}> 
                                Você em total controle dos dados compartilhados
                            </Body.TitleWithGradient> 
                        </Body.DivisionContent>
                        <Body.DivisionContent>
                            <Body.SecurityText style={{fontSize: '1.5vw'}}>
                                Nós do Grupo Tectrol e Dínamo
                                acreditamos na <strong>privacidade dos seus dados</strong>, e por isso nem mesmo nós temos acesso às suas
                                informações sem sua explícita permissão.<br/>
                                <strong>Você é quem decide</strong> quem da sua empresa, ou da
                                nossa, vai ter acesso aos seus dados.
                            </Body.SecurityText>
                        </Body.DivisionContent>
                    </Body.ContentRowOrigin>
                </Body.Content>
            </Body.ContainerWithWall>
        </Body.SecurityContainer>
    )
}

function Connectivity(){
    return(
        <Body.ContainerConnectivity>
            <Body.ConnectivityTitle>
                Conectividade sem limites
            </Body.ConnectivityTitle>
            <Body.ConnectivityText>
                Não importa se você e sua equipe estão na indústria ou fazendo uma caminhada nas montanhas, <strong>nós sempre iremos notificar</strong> para vocês um estado geral de como está sua indústria <strong>onde quer que vocês estejam</strong>.
            </Body.ConnectivityText>
            <Body.ConnectivityText style={{position: 'absolute', bottom: 10}}>
                E mesmo que você não tenha uma rede WiFi na sua indústria, nossos
                produtos também são equipados com redes 3G, 4G, e até mesmo <strong>5G</strong>, para conseguir
                máxima conectividade com nossos servidores e garantir monitoramento contínuo dos seus
                equipamentos.
            </Body.ConnectivityText>
        </Body.ContainerConnectivity>
    )
}

function App(){
    const [currentCard, setCurrentCard] = useState(1)
    const helpCurrent = useRef(1)
    const intervalIdCarousel = useRef<NodeJS.Timeout>()

    useEffect(()=>{
        const interval = setInterval(()=>{
            if(helpCurrent.current < 6){
                helpCurrent.current += 1;
                setCurrentCard(helpCurrent.current)
            }else{
                helpCurrent.current = 1
                setCurrentCard(1)
            }
        }, 6000)

        intervalIdCarousel.current = interval

        return function cleanUp(){
            clearInterval(interval)
        }
    },[])

    const clear = () =>{
        clearInterval(intervalIdCarousel.current!)

        const interval = setInterval(()=>{
            if(helpCurrent.current < 6){
                helpCurrent.current += 1;
                setCurrentCard(helpCurrent.current)
            }else{
                helpCurrent.current = 1
                setCurrentCard(1)
            }
        }, 6000)

        intervalIdCarousel.current = interval
    }

    useEffect(()=>{
        if(currentCard !== helpCurrent.current){
            helpCurrent.current = currentCard
            clear()
        }
    }, [currentCard])

    const getImage = () => {
        switch(currentCard){
            case 1:
                return Carousel1Image
            case 2:
                return Carousel2Image
            case 3:
                return Carousel3Image
            case 4:
                return Carousel4Image
            case 5:
                return Carousel5Image
            case 6:
                return Carousel6Image
        }
    }

    const getText = () => {
        switch(currentCard){
            case 1:
                return(
                    <>
                        <Body.ConceptText>conceito 1</Body.ConceptText>
                        <Body.CarouselTitle>Cards inteligentes</Body.CarouselTitle>
                        <Body.CarouselText>
                            O aplicativo possui cards que agregam informações de <strong>vários sensores</strong>, sendo capaz de exibir informações <strong>complementares e inteligentes</strong>
                        </Body.CarouselText>
                    </>
                )
            case 2:
                return(
                    <>
                        <Body.ConceptText>conceito 2</Body.ConceptText>
                        <Body.CarouselTitle>Da visão macro...</Body.CarouselTitle>
                        <Body.CarouselText>
                            A <strong>visualização rápida</strong> dos dados dos sensores será através de cards <strong>padronizados</strong>, mantendo a mesma identidade em cada dispositivo
                        </Body.CarouselText>
                    </>
                )
            case 3:
                return(
                    <>
                        <Body.ConceptText>conceito 3</Body.ConceptText>
                        <Body.CarouselTitle>...ao detalhe</Body.CarouselTitle>
                        <Body.CarouselText>
                            A visualização completa dos dados dos sensores será através <strong>gráficos e histórico de funcionamento</strong>, customizados para cada tipo de sensor 
                        </Body.CarouselText>
                    </>
                )
            case 4:
                return(
                    <>
                        <Body.ConceptText>conceito 4</Body.ConceptText>
                        <Body.CarouselTitle>Customização</Body.CarouselTitle>
                        <Body.CarouselText>
                            Você poderá selecionar os dados disponíveis para visualização e <strong>agrupá-los de acordo sua preferência</strong>
                        </Body.CarouselText>
                    </>
                )
            case 5:
                return(
                    <>
                        <Body.ConceptText>conceito 5</Body.ConceptText>
                        <Body.CarouselTitle>Notificações</Body.CarouselTitle>
                        <Body.CarouselText>
                            Você poderá <strong>personalizar quais avisos deseja receber</strong>, e em quais situações
                        </Body.CarouselText>
                    </>
                )
            case 6:
                return(
                    <>
                        <Body.ConceptText>conceito 6</Body.ConceptText>
                        <Body.CarouselTitle>Multiplataforma</Body.CarouselTitle>
                        <Body.CarouselText>
                        O aplicativo funciona em <strong>diversos dispositivos</strong>, e as costumizações realizadas em um, se aplicam aos outros <strong>automaticamente</strong>. 
                        </Body.CarouselText>
                    </>
                )
        }
    }

    return(
        <>
            <Body.Content>
                <Body.ContentRowOrigin style={{margin: '9vh'}}>
                    <Body.DivisionContent>
                        <Body.Title >
                            Uma aplicação
                        </Body.Title>
                        <Body.TextRowContent>
                            <Body.Title >
                                para
                            </Body.Title>
                            <Body.TitleWithGradient style={{marginLeft: 10}}>
                                todas
                            </Body.TitleWithGradient>
                        </Body.TextRowContent>
                        <Body.Title >
                            aplicações
                        </Body.Title>
                    </Body.DivisionContent>
                    <Body.DivisionContent style={{display: 'flex',alignItems: 'center'}}>
                        <Body.Text style={{width: '90%'}}>
                            Nós desenvolvemos nosso <strong>app de monitoramento da indústria inteligente</strong> para funcionar de maneira integrada com todos os seus <strong>sensores e atuadores</strong>
                        </Body.Text>
                    </Body.DivisionContent>
                </Body.ContentRowOrigin>
            </Body.Content>
            <Body.CarouselAppContainer>
                <Body.CarouselTitle style={{width: '55vw'}}>Conheça como nossos apps funcionam</Body.CarouselTitle>
                <Body.CarouselCard > 
                    <Body.CarouselCardTextContent>
                        {getText()}
                        <Body.CarouselBtnsContent>
                            <Body.CarouselBtn color={(currentCard === 1 ? "#66C9ED" : "#AFAFAF")} onClick={()=>setCurrentCard(1)} />
                            <Body.CarouselBtn color={(currentCard === 2 ? "#66C9ED" : "#AFAFAF")} onClick={()=>setCurrentCard(2)} />
                            <Body.CarouselBtn color={(currentCard === 3 ? "#66C9ED" : "#AFAFAF")} onClick={()=>setCurrentCard(3)} />
                            <Body.CarouselBtn color={(currentCard === 4 ? "#66C9ED" : "#AFAFAF")} onClick={()=>setCurrentCard(4)} />
                            <Body.CarouselBtn color={(currentCard === 5 ? "#66C9ED" : "#AFAFAF")} onClick={()=>setCurrentCard(5)} />
                            <Body.CarouselBtn color={(currentCard === 6 ? "#66C9ED" : "#AFAFAF")} onClick={()=>setCurrentCard(6)} />
                        </Body.CarouselBtnsContent>
                    </Body.CarouselCardTextContent>
                    <Body.CarouselImg src={getImage()} />
                </Body.CarouselCard>
            </Body.CarouselAppContainer>
        </>
    )
}

function Contact(){
    return(
        <Body.ContactContainer >
            <Body.Content style={{alignItems: 'center', margin: '7vh'}}>
                <Body.TextRowContent>
                    <Body.Title>A</Body.Title>
                    <Body.TitleWithGradient style={{marginLeft:10}}>mudança</Body.TitleWithGradient>
                    <Body.Title style={{marginLeft:10}}>começa com </Body.Title>
                    <Body.TitleWithGradient style={{marginLeft:10}}>você</Body.TitleWithGradient>
                </Body.TextRowContent>
                <Body.TextAlignCenter>
                    <strong>As possibilidades com as tecnologias de Indústria Inteligente são infinitas.</strong> E uma coisa é
                    certa: elas fazem sua indústria crescer e alcançar o pico de <strong>produtividade e ganhos.</strong>
                </Body.TextAlignCenter>
                <Body.TextAlignCenter>
                    <strong>Converse com nossos especialistas para saber como você pode implementar nossas
                    tecnologias na sua indústria</strong>
                </Body.TextAlignCenter>
                <Body.ContactBtnsContent >
                <a href="mailto:vendas@tectrolnet.com.br?subject=Ind%C3%BAstria%20Inteligente&body=Ol%C3%A1!%0D%0A%0D%0AGostaria%20de%20saber%20mais%20sobre%20a%20Ind%C3%BAstria%20Inteligente." style={{textDecoration: 'none'}}>
                    <Body.MailBtn>
                        <Body.ContactBtnIcon src={EmailIcon} />
                        <Body.ContactBtnText>vendas@tectrolnet.com.br</Body.ContactBtnText>
                    </Body.MailBtn>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=5543991244301&text=Ol%C3%A1!%20Estou%20interessado%20mais%20em%20saber%20mais%20sobre%20a%20Ind%C3%BAstria%20Inteligente." style={{textDecoration: 'none'}}>
                    <Body.WhatsappBtn>
                        <Body.ContactBtnIcon src={WhatsappIcon} />
                        <Body.ContactBtnText>(43) 99124-4301</Body.ContactBtnText>
                    </Body.WhatsappBtn>
                </a>
                </Body.ContactBtnsContent>
            </Body.Content>
        </Body.ContactContainer>
    )
}

function Footer(){
    return(
        <Body.FooterContainer>
            <Body.CompanyCopyright>® 2021-2022 Tectrol Automação Industrial. Todos os direitos reservados.</Body.CompanyCopyright>
            <Body.CompanyCopyright>® 2021-2022 Dínamo Automação Industrial. Todos os direitos reservados.</Body.CompanyCopyright>
            <Body.ArtCopyright target="_blank" rel="noopener noreferrer" href="https://br.freepik.com/fotos-vetores-gratis/tecnologia">Tecnologia vetor criado por jcomp e pch.vector - br.freepik.com</Body.ArtCopyright><br/>
            <Body.ArtCopyright target="_blank" rel="noopener noreferrer" href="https://br.freepik.com/fotos-vetores-gratis/infografico">Infográfico vetor criado por jcomp - br.freepik.com</Body.ArtCopyright><br/>
            <Body.ArtCopyright target="_blank" rel="noopener noreferrer" href="https://br.freepik.com/fotos-vetores-gratis/nuvem">Nuvem vetor criado por vectorjuice - br.freepik.com</Body.ArtCopyright><br/>
            <Body.ArtCopyright target="_blank" rel="noopener noreferrer" href="http://fontawesome.io">Font Awesome by Dave Gandy</Body.ArtCopyright>
        </Body.FooterContainer>
    )
}

const Web = () => {
    return(
        <Body.Container>
            <Init />
            <Origin />
            <OneSolution />
            <Security />
            <Connectivity />
            <App />
            <Contact />
            <Footer />
        </Body.Container>
    )
}

export default Web