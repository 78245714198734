import React, {useState, useEffect, useRef} from "react";
import * as Body from './Styles'
import LinesBtn from '../../../assets/images/LinesBtnMobile.png'
import LinesMobile from '../../../assets/images/LinesMobile2.png'
import SmartFactory from '../../../components/svg/smartFactory/SmartFactory'
import IndustryIllustration from '../../../components/svg/industry4.0Ilustration/IndustryIllustration'

import EmailIcon from '../../../assets/icons/Email.svg'
import WhatsappIcon from '../../../assets/icons/Whatsapp.svg'
import BarbedWire from '../../../assets/images/barbed-wire.png'
import CloudComputingAbstract from '../../../assets/icons/cloud-computing-security-abstract.svg'
import Shild from '../../../assets/icons/shild.svg'

import Carousel1Image from '../../../assets/images/carousel/Carousel1.png'
import Carousel2Image from '../../../assets/images/carousel/Carousel2.png'
import Carousel3Image from '../../../assets/images/carousel/Carousel3.png'
import Carousel4Image from '../../../assets/images/carousel/Carousel4.png'
import Carousel5Image from '../../../assets/images/carousel/Carousel5.png'
import Carousel6Image from '../../../assets/images/carousel/Carousel6.png'

//Icons
import CloudIcon from '../../../assets/icons/cloud-solid.svg'
import WifiIcon from '../../../assets/icons/wifi-solid.svg'
import BrainIcon from '../../../assets/icons/brain-solid.svg'
import RobotIcon from '../../../assets/icons/robot-solid.svg'
import CloudSelectedIcon from '../../../assets/icons/cloud-solid-selected.svg'
import WifiSelectedIcon from '../../../assets/icons/wifi-solid-selected.svg'
import BrainSelectedIcon from '../../../assets/icons/brain-solid-selected.svg'
import RobotSelectedIcon from '../../../assets/icons/robot-solid-selected.svg'

function InitMobile(){
        return(
        <Body.ContainerInitMobile>
            <Body.ContentMobile style={{alignItems: 'center'}}>
                <Body.TextContentInitMobile>
                    <Body.TitleHeader>
                        <Body.TitleMobile style={{fontSize: '6vw', letterSpacing: 3}}>
                            INFRAESTRUTURA 4.0
                        </Body.TitleMobile>
                        <Body.TitleWithGradientMobile style={{fontSize: '5vw'}}>
                            Sua indústria. Inteligente.
                        </Body.TitleWithGradientMobile>
                    </Body.TitleHeader>
                    <Body.TextMobile>
                        <strong> A última revolução industrial transformou a forma com que as indústrias operam</strong>, trazendo mais
                        produtividade e ganhos com o auxílio das tecnologias digitais.
                    </Body.TextMobile>

                    <div>
                        <Body.TextMobile>Saiba mais como nós do</Body.TextMobile>
                        <Body.TextWithGradientMobile>Grupo Tectrol e Dínamo</Body.TextWithGradientMobile>
                        <Body.TextMobile>estamos trabalhando para trazer</Body.TextMobile>
                        <Body.TextMobile>essa realidade na sua indústria.</Body.TextMobile>
                    </div>

                </Body.TextContentInitMobile>
            </Body.ContentMobile>
        </Body.ContainerInitMobile>
    )
}

function OriginMobile(){
    const [dataCard, setDataCard] = useState(1)
    const [cloudIcon, setCloudIcon] = useState(CloudIcon)
    const [robotIcon, setRobotIcon] = useState(RobotIcon)
    const [brainIcon, setBrainIcon] = useState(BrainSelectedIcon)
    const [wifiIcon, setWifiIcon] = useState(WifiIcon)

    useEffect(()=>{
        if(dataCard === 1){
            setBrainIcon(BrainSelectedIcon)
            setCloudIcon(CloudIcon)
            setRobotIcon(RobotIcon)
            setWifiIcon(WifiIcon)
        }
        if(dataCard === 2){
            setBrainIcon(BrainIcon)
            setCloudIcon(CloudIcon)
            setRobotIcon(RobotSelectedIcon)
            setWifiIcon(WifiIcon)
        }
        if(dataCard === 3){
            setBrainIcon(BrainIcon)
            setCloudIcon(CloudIcon)
            setRobotIcon(RobotIcon)
            setWifiIcon(WifiSelectedIcon)
        }
        if(dataCard === 4){
            setBrainIcon(BrainIcon)
            setCloudIcon(CloudSelectedIcon)
            setRobotIcon(RobotIcon)
            setWifiIcon(WifiIcon)
        }

        if(dataCard !== helpCurrent.current){
            helpCurrent.current = dataCard
            clear()
        }
    },[dataCard])

    const helpCurrent = useRef(1)
    const intervalId = useRef<NodeJS.Timeout>()

    useEffect(()=>{
        const interval = setInterval(()=>{
            if(helpCurrent.current < 4){
                helpCurrent.current += 1;
                setDataCard(helpCurrent.current)
            }else{
                helpCurrent.current = 1
                setDataCard(1)
            }
        }, 10000)

        intervalId.current = interval

        return function cleanUp(){
            clearInterval(intervalId.current!)
        }
    },[])

    const clear = () => {
        clearInterval(intervalId.current!)

        const interval = setInterval(()=>{
            if(helpCurrent.current < 4){
                helpCurrent.current += 1;
                setDataCard(helpCurrent.current)
            }else{
                helpCurrent.current = 1
                setDataCard(1)
            }
        }, 10000)

        intervalId.current = interval

    } 


    const getDataCard = () => {
        switch(dataCard){
            case 1:
                return(
                    <>
                        <Body.CardTitle>Inteligência Artificial</Body.CardTitle>
                        <Body.CardText>
                            O computador <strong>aprende como sua indústria funciona</strong>, e é capaz de dar sugestões preditivas e até mesmo realizar ações imediatas de forma a <strong>melhorar o seu processo produtivo</strong>
                        </Body.CardText>
                    </>
                )
            case 2:
                return(
                    <>
                        <Body.CardTitle>Robótica</Body.CardTitle>
                        <Body.CardText>
                            Máquinas e robôs <strong>guiados através de algoritmos de aprendizagem de máquina</strong> podem atuar de maneira mais efetiva na sua indústria, trazendo <strong>ganhos inimagináveis</strong>
                        </Body.CardText>
                    </>
                )
            case 3:
                return(
                    <>
                        <Body.CardTitle>Internet das Coisas</Body.CardTitle>
                        <Body.CardText>
                            Saiba tudo o que acontece na sua indústria <strong>onde quiser e quando quiser</strong>. Conecte seu maquinário em uma rede interligada que <strong>aprende sozinha</strong> e fornece dados de <strong>monitoramento contínuo</strong>.
                        </Body.CardText>
                    </>
                )
            case 4:
                return(
                    <>
                        <Body.CardTitle>Cloud Computing</Body.CardTitle>
                        <Body.CardText>
                        <strong> Esqueça o investimento em aquisição e manutenção de servidores</strong> e vários computadores locais que só ocupam espaço e poeira na sua indústria. Com o 4.0, <strong>todo o processamento e armazenamento é feito na internet</strong>.
                        </Body.CardText>
                    </>
                )
        }
    }

    const anchorSmartFactory = useRef(document.getElementById("anchor")?.getBoundingClientRect().top!)
    const smartFactoryPosition = useRef(document.getElementById("SmartFactory")?.getBoundingClientRect().top!)
    const [translateValue, setTranslateValue] = useState(0)
    const distance = useRef(1)

    useEffect(()=>{
        document.addEventListener('scroll', function(e){
            anchorSmartFactory.current = document.getElementById("anchor")?.getBoundingClientRect().top!
            smartFactoryPosition.current = document.getElementById("SmartFactory")?.getBoundingClientRect().top!

            if(anchorSmartFactory.current < 0 && smartFactoryPosition.current >= 0){
                setTranslateValue((200 * -anchorSmartFactory.current) / distance.current);
            }else if(anchorSmartFactory.current > 0){
                setTranslateValue(0);
                distance.current = smartFactoryPosition.current - anchorSmartFactory.current;
            }else if(anchorSmartFactory.current < 0){
                setTranslateValue(200);
                distance.current = Math.abs(anchorSmartFactory.current) - Math.abs(smartFactoryPosition.current);
            }
        })

        return document.removeEventListener('scroll', function(e){
        })
    })

    return(
        <> 

            <Body.ContentMobile>
                <Body.TitleHeader style={{display: 'flex', marginTop: 35, marginBottom: 10}}>
                    <Body.TitleMobile>A origem do</Body.TitleMobile>
                    <Body.TitleWithGradientMobile style={{marginLeft: 7}}>4.0</Body.TitleWithGradientMobile>
                </Body.TitleHeader>
                <Body.TextMobile>
                    O termo 4.0 vem da <strong>Quarta Revolução Industrial</strong>, que se refere ao uso de tecnologias digitais
                    avançadas como <strong>inteligência artificial, robótica, internet das coisas</strong> e <strong>computação em nuvem</strong>.
                </Body.TextMobile>
            </Body.ContentMobile>
            <Body.ContentMobile style={{height: '100vh'}}>
                <Body.OriginBtnsContent>
                    <Body.Lines src={LinesBtn}/>
                    <Body.OriginBtnBrainMobile borderSize={(dataCard === 1 ? 2 : 0)} onClick={() => setDataCard(1)}>
                        <Body.IconBtn src={brainIcon} />
                    </Body.OriginBtnBrainMobile>
                    <Body.OriginBtnRobotMobile borderSize={(dataCard === 2 ? 2 : 0)} onClick={() => setDataCard(2)}>
                        <Body.IconBtn src={robotIcon} />
                    </Body.OriginBtnRobotMobile>
                    <Body.OriginBtnWifiMobile borderSize={(dataCard === 3 ? 2 : 0)} onClick={() => setDataCard(3)}>
                        <Body.IconBtn src={wifiIcon} />
                    </Body.OriginBtnWifiMobile>
                    <Body.OriginBtnCloudMobile borderSize={(dataCard === 4 ? 2 : 0)} onClick={() => setDataCard(4)}>
                        <Body.IconBtn src={cloudIcon} />
                    </Body.OriginBtnCloudMobile>
                </Body.OriginBtnsContent>
                <Body.Card>
                    {getDataCard()}
                </Body.Card>
            </Body.ContentMobile>
            <Body.ContentMobile style={{marginTop: -20}} id="anchor">
                <Body.LinesBottom src={LinesMobile}/>
                <Body.LargeText style={{fontWeight: 400}}>
                    Juntas, elas se tornam uma poderosa ferramenta para mudar sua operação
                    fabril. É por isso que aqui, nós gostamos de chamar a indústria 4.0 de
                </Body.LargeText>
                <Body.LargeTextWithGradient>
                    Indústria Inteligente
                </Body.LargeTextWithGradient>
            </Body.ContentMobile>
            <Body.SmartFactoryScroll>
                <Body.SmartFactory id="SmartFactory" value={translateValue}>
                    <SmartFactory />
                </Body.SmartFactory>
            </Body.SmartFactoryScroll>
        </>
    )
}

function OneSolution(){
    return(
        <Body.ContentMobile style={{textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: "flex-end"}}>
                 <Body.TitleHeaderFlex >
                    <Body.TitleMobile>Uma solução para</Body.TitleMobile>
                    <Body.TitleWithGradientMobile style={{marginLeft: 7}}>todos</Body.TitleWithGradientMobile>
                </Body.TitleHeaderFlex>
                <Body.TextMobile>
                Não importa o grau de automação da sua indústria,<strong> nós temos o que é necessário para torná-la uma indústria inteligente</strong>. Nossos equipamentos são compatíveis com <strong>qualquer padrão de automação existente</strong>.
                </Body.TextMobile>

                <Body.PercentageContent>
                    <Body.LargeCircle>
                        <Body.TextMobile style={{fontSize: '3vw'}}>
                            aumento de
                        </Body.TextMobile>
                        <Body.PercentageText>
                            25%
                        </Body.PercentageText>
                        <Body.TextMobile style={{fontSize: '3vw'}}>
                            em produtividade
                        </Body.TextMobile>
                    </Body.LargeCircle>
                    <Body.LargeCircle>
                        <Body.TextMobile style={{fontSize: '3vw'}}>
                            redução de
                        </Body.TextMobile>
                        <Body.PercentageText>
                            40%
                        </Body.PercentageText>
                        <Body.TextMobile style={{fontSize: '3vw'}}>
                            em manutenção
                        </Body.TextMobile>
                    </Body.LargeCircle>
                    
                    <Body.LargeCircle>
                        <Body.TextMobile style={{fontSize: '3vw'}}>
                            redução de
                        </Body.TextMobile>
                        <Body.PercentageText>
                            50%
                        </Body.PercentageText>
                        <Body.TextMobile style={{fontSize: '3vw'}}>
                            em máquina parada
                        </Body.TextMobile>
                    </Body.LargeCircle>
                </Body.PercentageContent>

                <IndustryIllustration />
                <Body.TitleMobile style={{textAlign: 'center'}}>
                    Deixe o trabalho pesado para as máquinas
                </Body.TitleMobile>
                <Body.TextMobile style={{textAlign: 'center', marginTop: 30, marginBottom: 30}}>
                    Com a <strong>inteligência artificial</strong>, nossos algoritmos conseguem trabalhar em tempo real na sua
                    indústria, ajustando padrões e criando novos que você nem sabia que existia – <strong>tudo isso
                    para maximizar a sua produtividade</strong>
                </Body.TextMobile>
                <Body.TextMobile style={{textAlign: 'center', marginBottom: 50}}>
                    E tudo isso é feito praticamente <strong>sem intervenção
                    humana</strong>, então você não precisa mais se preocupar em ficar com alguém analisando seus
                    equipamentos 24/7 para garantir que eles funcionem
                </Body.TextMobile>
        </Body.ContentMobile>
    )
}

function Security(){
    return(
        <Body.SecurityContainer>
            <Body.SecurityShild>
                <Body.Img src={Shild} style={{width: '28vw'}}/>
            </Body.SecurityShild>
            <Body.SecurityTitle>
                Segurança em primeiro lugar
            </Body.SecurityTitle>
        
            <Body.SecurityContainer >
                <Body.SecurityCarouselContent>
                    <Body.SecurityCard style={{marginLeft: '20vw'}}>
                        <Body.SecurityItemTitle>
                            Anos de experiência aplicada em automação
                        </Body.SecurityItemTitle>
                        <Body.SecurityItemText>
                            Nossos algoritmos foram desenvolvidos baseado nos <strong>mais de 25 anos de experiência</strong> no mercado de automação e manutenção, garantindo confiabilidade nos resultados aplicados
                        </Body.SecurityItemText>
                    </Body.SecurityCard>
                    <Body.SecurityCard>
                        <Body.SecurityItemTitle>
                            Uma equipe virtual dentro das máquinas
                        </Body.SecurityItemTitle>
                        <Body.SecurityItemText>
                            Quando algo imprevisivel acontecer, <strong>o próprio sistema se encarrega de tentar solucionar o problema </strong>sem
                            intervenção humana, garantindo que seus equipamentos funcionem sem problemas
                        </Body.SecurityItemText>
                    </Body.SecurityCard>
                    <Body.SecurityCard>
                        <Body.SecurityItemTitle>
                            Seus dados guardados a sete chaves
                        </Body.SecurityItemTitle>
                        <Body.SecurityItemText>
                            De ponta a ponta, <strong>todos os dados  transmitidos do seu equipamento trafegam em um canal seguro</strong>, e são <strong>criptografados</strong> utilizando as últimas tecnologias em <strong>ofuscamento e segurança digital</strong>
                        </Body.SecurityItemText>
                    </Body.SecurityCard>
                    <Body.SecurityCard style={{marginRight: '20vw'}}>
                        <Body.SecurityItemTitle>
                            Fique sabendo de tudo o que acontece
                        </Body.SecurityItemTitle>
                        <Body.SecurityItemText>
                            Em qualquer situação de emergência, até mesmo que aquelas que resolvemos automaticamente, <strong>você e sua equipe serão notificados instantaneamente do ocorrido</strong>
                        </Body.SecurityItemText>
                    </Body.SecurityCard>
                </Body.SecurityCarouselContent>
            </Body.SecurityContainer>

            <Body.ContentMobile>
                <Body.SecurityText>
                    Nós aplicamos algoritmos de monitoramento de anomalias <strong>todos os pontos do nosso sistema</strong>,
                    permitindo que eles identifiquem possíveis <strong>ataques de invasores</strong>, <strong>uso incorreto do
                    equipamento</strong> ou até mesmo <strong>funcionamento inadequado</strong>
                </Body.SecurityText>
            </Body.ContentMobile>

            <Body.Img src={CloudComputingAbstract} style={{width: '50vw'}} />
            <Body.SecurityTitle style={{fontSize: '6vw'}}>
                Assim fica fácil ficar relaxado, não é mesmo?
            </Body.SecurityTitle>
            <Body.ContainerWithWall>
                <Body.Img src={BarbedWire} style={{width: '100%', top: -10, position: 'absolute'}}/>
                <Body.ContentMobile>
                    <Body.SecurityTitle style={{ textAlign: 'left', top: 10}}>
                        Deixe os bisbilhoteiros pra fora
                    </Body.SecurityTitle>
                    <Body.TitleWithGradientMobile style={{marginLeft: '3vh'}}>
                        Você em total controle dos dados compartilhados
                    </Body.TitleWithGradientMobile>

                    <Body.SecurityText style={{ textAlign: 'left', marginTop: '5vh'}}>
                        Nós do Grupo Tectrol e Dínamo
                        acreditamos na <strong>privacidade dos seus dados</strong>, e por isso nem mesmo nós temos acesso às suas
                        informações sem sua explícita permissão.<br/>
                        <strong>Você é quem decide</strong> quem da sua empresa, ou da
                        nossa, vai ter acesso aos seus dados.
                    </Body.SecurityText>
                </Body.ContentMobile>
            </Body.ContainerWithWall>
        </Body.SecurityContainer>

    )
}

function Connectivity(){
    const stickyTitle = useRef(document.getElementById("connectivity")?.getBoundingClientRect().top!)
    const anchor = useRef(document.getElementById("connectivityAnchor")?.getBoundingClientRect().top!)
    const [top, setTop] = useState(0)

    useEffect(()=>{
        document.addEventListener('scroll', function(e){
            stickyTitle.current = document.getElementById("connectivity")?.getBoundingClientRect().top!;
            anchor.current = document.getElementById("connectivityAnchor")?.getBoundingClientRect().top!;
            if(stickyTitle.current < 0 && (anchor.current - 30) >= 0){
                setTop(stickyTitle.current * -1);
            }else if(stickyTitle.current >= 0){
                setTop(0)
            }else if(anchor.current < 0){
                setTop((stickyTitle.current * -1) + anchor.current - 30)
            }
        })

        document.onscroll = function(e) { 
        }
        
        return function cleanUp(){
            document.removeEventListener('scroll', function(e){
            })
        }
    })

    return(
        <Body.Container id="connectivity">
            <Body.ContainerConnectivity>
                    <Body.ContentMobile style={{position: 'absolute', top: top, marginLeft: 'auto', marginRight: 'auto',left: 0, right: 0, transition: 'linear'}}>
                        <Body.ConnectivityTitleScroll>
                            Conectividade sem limites
                        </Body.ConnectivityTitleScroll>
                    </Body.ContentMobile>
            </Body.ContainerConnectivity>
            <Body.ContentMobile>
                <Body.TitleMobile id="connectivityAnchor" style={{fontSize: '9vw', margin: 20,marginTop: 30, zIndex: 100}}>
                    Conectividade sem limites
                </Body.TitleMobile>
                <Body.TextMobile style={{fontSize: '6vw', margin: 20,marginTop: 30}}>
                    Não importa se você e sua equipe estão na indústria ou fazendo uma caminhada nas montanhas, <strong>nós sempre iremos notificar</strong> para vocês um estado geral de como está sua indústria <strong>onde quer que vocês estejam</strong>.
                </Body.TextMobile>
                <Body.TextMobile style={{fontSize: '6vw', margin: 20,marginTop: 30}}>
                    E mesmo que você não tenha uma rede WiFi na sua indústria, nossos
                    produtos também são equipados com redes 3G, 4G, e até mesmo <strong>5G</strong>, para conseguir
                    máxima conectividade com nossos servidores e garantir monitoramento contínuo dos seus
                    equipamentos.
                </Body.TextMobile>
            </Body.ContentMobile>
        </Body.Container>
    )
}

function App(){
    const [currentCard, setCurrentCard] = useState(1)
    const helpCurrent = useRef(1)
    const intervalIdCarousel = useRef<NodeJS.Timeout>()

    useEffect(()=>{
        const interval = setInterval(()=>{
            if(helpCurrent.current < 6){
                helpCurrent.current += 1;
                setCurrentCard(helpCurrent.current)
            }else{
                helpCurrent.current = 1
                setCurrentCard(1)
            }
        }, 6000)

        intervalIdCarousel.current = interval

        return function cleanUp(){
            clearInterval(interval)
        }
    },[])

    const clear = () =>{
        clearInterval(intervalIdCarousel.current!)

        const interval = setInterval(()=>{
            if(helpCurrent.current < 6){
                helpCurrent.current += 1;
                setCurrentCard(helpCurrent.current)
            }else{
                helpCurrent.current = 1
                setCurrentCard(1)
            }
        }, 6000)

        intervalIdCarousel.current = interval
    }

    useEffect(()=>{
        if(currentCard !== helpCurrent.current){
            helpCurrent.current = currentCard
            clear()
        }
    }, [currentCard])

    const getImage = () => {
        switch(currentCard){
            case 1:
                return Carousel1Image
            case 2:
                return Carousel2Image
            case 3:
                return Carousel3Image
            case 4:
                return Carousel4Image
            case 5:
                return Carousel5Image
            case 6:
                return Carousel6Image
        }
    }

    const getConcept = () => {
        switch(currentCard){
            case 1:
                return (
                    <Body.ConceptText>conceito 1</Body.ConceptText>
                )
            case 2:
                return (
                    <Body.ConceptText>conceito 2</Body.ConceptText>   
                )
            case 3:
                return (
                    <Body.ConceptText>conceito 3</Body.ConceptText>
                )
            case 4:
                return (
                    <Body.ConceptText>conceito 4</Body.ConceptText>
                )
            case 5:
                return (
                    <Body.ConceptText>conceito 5</Body.ConceptText>
                )
            case 6:
                return (
                    <Body.ConceptText>conceito 6</Body.ConceptText>
                )
        }
    }

    const getText = () => {
        switch(currentCard){
            case 1:
                return(
                    <>
                        <Body.CarouselInfoContent>
                            <Body.CarouselTitle>Cards inteligentes</Body.CarouselTitle>
                        </Body.CarouselInfoContent>
                        <Body.CarouselText>
                            O aplicativo possui cards que agregam informações de <strong>vários sensores</strong>, sendo capaz de exibir informações <strong>complementares e inteligentes</strong>
                        </Body.CarouselText>
                    </>
                )
            case 2:
                return(
                    <>
                        <Body.CarouselInfoContent>
                            <Body.CarouselTitle>Da visão macro...</Body.CarouselTitle>
                        </Body.CarouselInfoContent>
                        <Body.CarouselText>
                            A <strong>visualização rápida</strong> dos dados dos sensores será através de cards <strong>padronizados</strong>, mantendo a mesma identidade em cada dispositivo
                        </Body.CarouselText>
                    </>
                )
            case 3:
                return(
                    <>
                        <Body.CarouselInfoContent>
                            <Body.CarouselTitle>...ao detalhe</Body.CarouselTitle>
                        </Body.CarouselInfoContent>
                        <Body.CarouselText>
                            A visualização completa dos dados dos sensores será através <strong>gráficos e histórico de funcionamento</strong>, customizados para cada tipo de sensor 
                        </Body.CarouselText>
                    </>
                )
            case 4:
                return(
                    <>
                        <Body.CarouselInfoContent>
                            <Body.CarouselTitle>Customização</Body.CarouselTitle>
                        </Body.CarouselInfoContent>
                        <Body.CarouselText>
                            Você poderá selecionar os dados disponíveis para visualização e <strong>agrupá-los de acordo sua preferência</strong>
                        </Body.CarouselText>
                    </>
                )
            case 5:
                return(
                    <>
                        <Body.CarouselInfoContent>
                            <Body.CarouselTitle>Notificações</Body.CarouselTitle>
                        </Body.CarouselInfoContent>
                        <Body.CarouselText>
                            Você poderá <strong>personalizar quais avisos deseja receber</strong>, e em quais situações
                        </Body.CarouselText>
                    </>
                )
            case 6:
                return(
                    <>
                        <Body.CarouselInfoContent>
                            <Body.CarouselTitle>Multiplataforma</Body.CarouselTitle>
                        </Body.CarouselInfoContent>
                        <Body.CarouselText>
                        O aplicativo funciona em <strong>diversos dispositivos</strong>, e as costumizações realizadas em um, se aplicam aos outros <strong>automaticamente</strong>. 
                        </Body.CarouselText>
                    </>
                )
        }
    }

    return(
        <Body.ContentMobile>
            <Body.TitleHeader style={{textAlign: 'center', marginTop: '10vh'}}>
                <Body.TitleMobile style={{fontSize: '8vw'}}>
                    Uma aplicação
                </Body.TitleMobile>
                <Body.TitleHeader style={{display: 'flex', justifyContent: 'center', width:'100%'}}>
                    <Body.TitleMobile style={{fontSize: '8vw'}}>
                        para
                    </Body.TitleMobile>
                    <Body.TitleWithGradientMobile style={{marginLeft: 10, fontSize: '8vw'}}>
                        todas
                    </Body.TitleWithGradientMobile>
                </Body.TitleHeader>
                <Body.TitleMobile style={{fontSize: '8vw'}}>
                    as aplicações
                </Body.TitleMobile>
            </Body.TitleHeader>
            <Body.TextMobile style={{fontSize: '6vw',marginTop: 30, marginBottom: '5vh', textAlign: 'center'}}>
                Nós desenvolvemos nosso <strong>app de monitoramento da indústria inteligente</strong> para funcionar de maneira integrada com todos os seus <strong>sensores e atuadores</strong>
            </Body.TextMobile>
            <Body.CarouselAppContainer>
                <Body.CarouselTitle>Conheça como nossos apps funcionam</Body.CarouselTitle>
                <Body.CarouselImg src={getImage()} />
                <Body.CarouselInfoContent>
                    {getConcept()}
                    <Body.CarouselBtnsContent>
                        <Body.CarouselBtn color={(currentCard === 1 ? "#66C9ED" : "#AFAFAF")} onClick={()=>setCurrentCard(1)} />
                        <Body.CarouselBtn color={(currentCard === 2 ? "#66C9ED" : "#AFAFAF")} onClick={()=>setCurrentCard(2)} />
                        <Body.CarouselBtn color={(currentCard === 3 ? "#66C9ED" : "#AFAFAF")} onClick={()=>setCurrentCard(3)} />
                        <Body.CarouselBtn color={(currentCard === 4 ? "#66C9ED" : "#AFAFAF")} onClick={()=>setCurrentCard(4)} />
                        <Body.CarouselBtn color={(currentCard === 5 ? "#66C9ED" : "#AFAFAF")} onClick={()=>setCurrentCard(5)} />
                        <Body.CarouselBtn color={(currentCard === 6 ? "#66C9ED" : "#AFAFAF")} onClick={()=>setCurrentCard(6)} />
                    </Body.CarouselBtnsContent>
                </Body.CarouselInfoContent>
                {getText()}
            </Body.CarouselAppContainer>
        </Body.ContentMobile>
    )
}

function Contact (){
    return(
        <Body.Container style={{backgroundColor: '#fff'}}>
            <Body.TitleHeader style={{display: 'flex', marginTop: 30}}>
                <Body.TitleMobile style={{fontSize: '8vw'}}>
                    A
                </Body.TitleMobile>
                <Body.TitleWithGradientMobile style={{marginLeft: 10, fontSize: '8vw'}}>
                    mudança
                </Body.TitleWithGradientMobile>
            </Body.TitleHeader>
            <Body.TitleHeader style={{display: 'flex', marginBottom: 20}}>
                <Body.TitleMobile style={{fontSize: '8vw'}}>
                    começa com
                </Body.TitleMobile>
                <Body.TitleWithGradientMobile style={{marginLeft: 10, fontSize: '8vw'}}>
                    você
                </Body.TitleWithGradientMobile>
            </Body.TitleHeader>
            <Body.ContentMobile style={{alignItems:'center'}}>
            <Body.TextMobile style={{color: '#000', margin: 20, textAlign: 'center'}}>
                <strong>As possibilidades com as tecnologias de Indústria Inteligente são infinitas.</strong> E uma coisa é
                    certa: elas fazem sua indústria crescer e alcançar o pico de <strong>produtividade e ganhos.</strong>
            </Body.TextMobile>

            <Body.TextMobile style={{color: '#000', margin: 20, textAlign: 'center'}}>
                <strong>Converse com nossos especialistas para saber como você pode implementar nossas
                tecnologias na sua indústria</strong>
            </Body.TextMobile>

            <a href="mailto:vendas@tectrolnet.com.br?subject=Ind%C3%BAstria%20Inteligente&body=Ol%C3%A1!%0D%0A%0D%0AGostaria%20de%20saber%20mais%20sobre%20a%20Ind%C3%BAstria%20Inteligente." style={{textDecoration: 'none'}}>
                <Body.MailBtn>
                    <Body.ContactBtnIcon src={EmailIcon} />
                    <Body.ContactBtnText>vendas@tectrolnet.com.br</Body.ContactBtnText>
                </Body.MailBtn>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=5543991244301&text=Ol%C3%A1!%20Estou%20interessado%20mais%20em%20saber%20mais%20sobre%20a%20Ind%C3%BAstria%20Inteligente." style={{textDecoration: 'none'}}>
                <Body.WhatsappBtn>
                    <Body.ContactBtnWhatsAppIcon src={WhatsappIcon} />
                    <Body.ContactBtnText>(43) 99124-4301</Body.ContactBtnText>
                </Body.WhatsappBtn>
            </a>
            </Body.ContentMobile>

        </Body.Container>
    )
}

function Footer(){
    return(
        <Body.FooterContainer>
            <Body.CompanyCopyright>® 2021-2022 Tectrol Automação Industrial. Todos os direitos reservados.</Body.CompanyCopyright>
            <Body.CompanyCopyright>® 2021-2022 Dínamo Automação Industrial. Todos os direitos reservados.</Body.CompanyCopyright>
            <Body.ArtCopyright target="_blank" rel="noopener noreferrer" href="https://br.freepik.com/fotos-vetores-gratis/tecnologia">Tecnologia vetor criado por jcomp e pch.vector - br.freepik.com</Body.ArtCopyright><br/>
            <Body.ArtCopyright target="_blank" rel="noopener noreferrer" href="https://br.freepik.com/fotos-vetores-gratis/infografico">Infográfico vetor criado por jcomp - br.freepik.com</Body.ArtCopyright><br/>
            <Body.ArtCopyright target="_blank" rel="noopener noreferrer" href="https://br.freepik.com/fotos-vetores-gratis/nuvem">Nuvem vetor criado por vectorjuice - br.freepik.com</Body.ArtCopyright><br/>
            <Body.ArtCopyright target="_blank" rel="noopener noreferrer" href="http://fontawesome.io">Font Awesome by Dave Gandy</Body.ArtCopyright>
        </Body.FooterContainer>
    )
}

const Mobile = () => {
    return(
        <Body.Container>
            <InitMobile />
            <OriginMobile />
            <OneSolution />
            <Security />
            <Connectivity />
            <App />
            <Contact />
            <Footer />
        </Body.Container>
    )
}

export default Mobile;