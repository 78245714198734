import styled from 'styled-components'
import BackgroundInit from '../../../assets/images/thisisengineering-raeng-nZbtducZUFU-unsplash.png'
import BackgroundWall from '../../../assets/images/black-brick-wall-textured-background1.png'
import BackgroundConnectivity from '../../../assets/images/khamkhor--I-McziCxxM-unsplash1.png'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #E5E5E5;
    width: 100%;
`

export const Content = styled.div`
    width: 70%;
    display: flex;  
    flex-direction: column;
`

export const WithoutBrokenLine = styled.div`
    display: flex;
    flex-direction: row;
`

export const ContainerWithImage = styled.div`
    background: url(${BackgroundInit});
    background-repeat: no-repeat;
    background-size: cover;
    width: ${window.innerWidth}px;
    height: 140vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`
export const Img = styled.img``

export const Title = styled.h1`
    color: #414141;
    font-size: 4vw;
    font-family: 'Montserrat';
`

export const TitleWithGradient = styled.h1`
    font-family: 'Montserrat';
    font-size: 4vw;
    background: -webkit-linear-gradient(45deg, #3E93D8, #4CB931);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
` 

export const TitleHeader = styled.div`
`

export const Text = styled.p`
    font-size: 2vw;
    font-family: 'Nunito';
    color: #414141;
`

export const TextWithGradient = styled.p`
    font-weight: 700;
    font-size: 1.7vw;
    font-family: 'Nunito';
    background: -webkit-linear-gradient(45deg, #3E93D8, #4CB931);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

export const TextContentInit = styled.div`
    height: 150vh;
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`


export const DivisionContent = styled.div`
    width: 49%;
`
export const OriginBtnsContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
`

export const OriginBtn = styled.button<{ borderSize: number}>`
    width: 6.5vw;
    height: 6.5vw;
    border-radius: 50%;
    background: #EFEFEF;
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px #CBCBCB;
    border: none;
    border: ${props=>(props.borderSize)}px solid #66C9ED;
    cursor: pointer;
    position: absolute;
    :hover{
        border: 1px solid rgba(102, 201, 237, 0.5);
    }
`
export const IconBtn = styled.img`
    width: 3vw;
    height: 3vw;
    color: #282828;
`

export const Card = styled.div`
    width: 30vw;
    height: 30vw;
    background: #EFEFEF;
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px #CBCBCB;
    border-radius: 30px;   
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
`

export const CardTitle = styled.p`
    font-family: 'Montserrat';
    font-size: 2vw;
    margin: 20px;
    margin-top: 50px;
    background: -webkit-linear-gradient(45deg, #3E93D8, #4CB931);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

export const CardText = styled.p`
    font-size: 1.3vw;
    font-family: 'Nunito';
    width: 25vw;
    text-align: center;
    margin-top: 30px;
    line-height: 1.8;
`

// Origin

export const ContentRowOrigin = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 50px;
`

export const TextAlignCenter = styled.p`
    font-size: 1.7vw;
    font-family: 'Nunito';
    text-align: center;
    margin: 30px;
`

export const LargeCircle = styled.div`
    width: 18vw;
    height: 18vw;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #EFEFEF;
    margin: 20px;
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px #CBCBCB;
`

export const PercentageText = styled.div`
    color:#4CB931;
    font-size: 5.8vw;
    font-weight: 700;
    font-family: 'Montserrat';
`

export const PercentageContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 30px;
`

//Security

export const SecurityContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1C1C1C;
    width: 100%;
`

export const SecurityShild = styled.div`
    width: 13vw;
    height: 13vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    border-radius: 50%;
    background: #1C1C1C;
    box-shadow: -10px -10px 30px #252525, 10px 10px 30px #0D0D0D;

`

export const SecurityTitle = styled.h1`
    color: #FFF;
    font-family: 'Montserrat';
    margin: 3vh;
    font-size: 3.5vw;
`

export const SecurityText = styled.p`
    font-size: 1.5vw;
    color: #FFF;
    font-family: 'Nunito';
    margin: 20px;
    text-align: center;
`

export const SecurityItemTitle = styled.p`
    color: #FFF;
    width: 60%;
    font-weight: 700;
    font-size: 1.3vw;
    font-family: 'Montserrat';
    margin-bottom: 20px;
`

export const SecurityItemText = styled.p`
    font-size: 1.2vw;
    width: 60%;
    color: #FFF;
    font-family: 'Nunito';
`

export const ContainerWithWall = styled.div`
    background: url(${BackgroundWall});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`

export const DivisionContentSecurity = styled.div`
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const SecurityRow = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 100px;
`

export const ContentRowSecurity = styled.div`
    display: flex;
    flex-direction: row;
    margin: 40px;
    align-items: center;
`

//Connectivity

export const ContainerConnectivity = styled.div`
    background: url(${BackgroundConnectivity});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 920px;
    display: flex;
    flex-direction: column;
    position: relative;
`

export const ConnectivityTitle = styled.h1`
    color: #FFF;
    font-family: 'Montserrat';
    margin: 40px;
    margin-top: 60px;
    font-size: 3.5vw;
`

export const ConnectivityText = styled.p`
    font-size: 1.3vw;
    width: 55vw;
    margin: 40px;
    text-align: left;
    color: #FFF;
    font-family: 'Nunito';
`

//App

export const TextRowContent = styled.div`
    display: flex;
`

export const CarouselAppContainer = styled.div`
    background: #E5E5E5;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 9vh;
    `

export const CarouselBtnsContent = styled.div`
    position: absolute;
    width: 60%;
    bottom: 0;
`

export const CarouselBtn = styled.button<{color: string}>`
    width: 1.3vw;
    height: 1.3vw;
    border-radius: 50%;
    cursor: pointer;
    margin: 5px;
    border: none;
    background: ${props => props.color};
`

export const CarouselImg = styled.img`
    height: 100%;
    object-fit: contain;
`

export const CarouselCard = styled.div`
    display: flex;
    flex-direction: row;
    width: 55vw;
    margin-top: 10vh;
    height: 50vh;
    justify-content: space-between;
    position: relative;
`

export const CarouselCardTextContent = styled.div`
    width: 50%;
`

export const CarouselTitle = styled.h1`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 2.68vw;
    color: rgba(65, 65, 65, 1);
    width: 100%;
`

export const ConceptText = styled.p`
    font-family: 'Montserrat';
    color: rgba(153, 153, 153, 1);
    font-size: 1vw;
    text-transform: uppercase;
`

export const CarouselText = styled.p`
    margin-top: 10px;
    font-family: 'Nunito';
    color: rgba(65, 65, 65, 1);
    font-size: 1.5vw;
`

//Contact

export const ContactContainer = styled.div`
    background: #FFF;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ContactBtnsContent = styled.div`
    display: flex;
    width: 80%;
    justify-content: space-around;
    margin: 5vh;
`

export const ContactBtnIcon = styled.img`
    height: 1.7vw;
    margin: 30px;
`

export const MailBtn = styled.div`
    height: 5vw;
    width: 25vw;
    border: 2px solid rgba(62, 147, 216, 0.4);
    background: none;
    cursor: pointer;
    border-radius: 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    box-shadow: -10px -10px 30px #F9F9F9, 10px 10px 20px rgba(217, 217, 217, 0.25);
    :hover{
        border-color: rgba(62, 147, 216, 1);
    }
`

export const WhatsappBtn = styled.div`
    height: 5vw;
    width: 20vw;
    border: 2px solid rgba(76, 185, 49, 0.4);
    background: none;
    cursor: pointer;
    border-radius: 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    box-shadow: -10px -10px 30px #F9F9F9, 10px 10px 20px rgba(217, 217, 217, 0.25);
    :hover{
        border-color: rgba(76, 185, 49, 1);
    }
`

export const ContactBtnText = styled.p`
    color: #000;
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 1.3vw;
`

export const FooterContainer = styled.div`
    width: 100%;
    height: 165px;
    background-color: #3E93D8;
    padding: 2vw 15vw;
    box-sizing: border-box;
`

export const CompanyCopyright = styled.p`
    font-family: 'Nunito';
    color: #FFFFFF;
`

export const ArtCopyright = styled.a`
    font-family: 'Nunito';
    color: #FFFFFF;
    font-size: 12px;
    text-decoration: none;
`