import React from "react";
import './style.css'


const IndustryIllustration = () => {
    return(
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 3500 2000" >
   <g id="window">
       <g>
           <rect x="802.2" y="352.6" className="in0" width="702.6" height="663.1"/>
       </g>
       <g>
           <rect x="1945.7" y="411.8" className="in0" width="632.8" height="597.2"/>
       </g>
   </g>
   <g id="illustration">
       <g id="phone">
           <g>
               <g>
                   <path className="in1" d="M3188.6,1341.8l-16.9,2.3c-2.4-18.5-8.7-36-17.9-51.8l14.7-8.6c-10.2-17.4-23.8-32.9-40.2-45.3l-10.4,13.6
                       c-14.3-10.9-30.9-19.1-49.3-23.9l4.3-16.6c-14.2-3.7-28.4-5.3-42.4-5v80.9c7.3-0.3,14.7,0.5,22.1,2.4
                       c40.5,10.5,64.8,51.8,54.3,92.2c-9.1,35.2-41.5,58.1-76.3,56.6v80.8c8.1,0.2,16.2-0.3,24.2-1.4l-2.3-16.9
                       c18.5-2.4,36.1-8.7,51.9-17.9l8.6,14.7c17.4-10.2,32.9-23.8,45.3-40.2l-0.1,0l-3.9-3l-9.6-7.3c10.9-14.3,19.1-30.9,23.9-49.3
                       l16.6,4.3C3190.3,1381.9,3191.3,1361.4,3188.6,1341.8z"/>
               </g>
               <g>
                   <path className="gear" d="M3143,1013.7c-6.4,9.8-10.6,20.3-12.7,31.1l9.3,1.8c-2,10.2-1.9,20.6,0.2,30.5l-9.3,1.9
                       c2.3,11,6.7,21.5,13,31l1.8-1.2l6.1-4.1c5.6,8.3,12.9,15.6,21.7,21.4l-5.2,8c9.8,6.4,20.3,10.6,31.2,12.6l1.8-9.3
                       c10.2,2,20.5,1.9,30.5-0.2l1.9,9.3c11-2.3,21.5-6.7,31-13.1l0,0l-1.5-2.3l-3.8-5.6c8.3-5.6,15.6-12.8,21.4-21.7l8,5.2
                       c6.4-9.8,10.6-20.4,12.7-31.2l-9.3-1.8c2-10.2,1.9-20.5-0.2-30.5l9.3-1.9c-2.3-11-6.7-21.5-13.1-31l-7.9,5.3
                       c-5.6-8.3-12.8-15.6-21.7-21.4l5.2-8c-9.8-6.4-20.4-10.6-31.2-12.7l-1.8,9.3c-10.2-2-20.5-1.9-30.5,0.2l-1.9-9.3
                       c-11,2.3-21.5,6.7-31,13.1l0,0.1l3.5,5.2l1.8,2.6c-8.3,5.6-15.6,12.9-21.4,21.7L3143,1013.7z M3181.1,1037.6
                       c12.3-17.8,36.6-23.4,55.4-12.7c21.6,12.3,27.8,40.2,13.9,60.5c-12.3,17.8-36.5,23.4-55.3,12.7
                       C3173.4,1085.8,3167.2,1057.9,3181.1,1037.6z"/>
               </g>
           </g>
           <g>
               <g>
                   <path className="in2" d="M2993.2,589.6c-10.3-59.3-101.8-85.8-99.9-47l-419.3,4.2c-29,0.3-52.5,26.5-52.4,58.6l3.7,1102.7
                       c0.1,33.2,24.2,60.3,53.8,60.5l428,1.9c2.7,5.6,7.7,10.6,15.5,14.8c49.1,0.8,72.5-23.5,81-50.9c2.8-7.4,4.3-15.5,4.2-24.1
                       C3006,1695.8,2996.3,603.5,2993.2,589.6z"/>
                   <path className="in2" d="M2918.1,1785.4l-491.3-1.1c-30.7-0.1-55.6-27.9-55.7-62.1l-2.8-1134c-0.1-33,24.2-59.9,54.3-60.1l481.7-3.5
                       c30.3-0.2,55.2,26.4,55.6,59.6l13.4,1139.2C2973.7,1757.6,2949,1785.4,2918.1,1785.4z"/>
                   <path className="in3" d="M2924.8,1777.1c-2,0.3-4.1,0.4-6.2,0.4l-491.1-1.7c-26.3-0.1-48-24.5-48.1-54.3l-3-1132.8
                       c-0.1-28.8,21-52.4,46.9-52.6L2905,532c0.8,0,1.5,0,2.3,0c12.1,0.5,23.5,6,32,15.4c8.7,9.6,13.6,22.4,13.7,36l13.3,1140.4
                       C2966.6,1750.9,2948.8,1773.8,2924.8,1777.1z"/>
                   <polygon className="in4" points="2936.1,589.3 2949.1,1725.7 2395.8,1724.6 2392.5,591.5 				"/>
               </g>
               <path className="in5" d="M2486.8,1538.2c0,0-66.6-40.7-22.9-201.2s-84.8-243.5-14.2-331.8c46.9-58.7,154.9,37.1,261.9-44.3
                   c77.4-59,159.3-55.1,194.2,81.4c51.5,201.3-60.8,288-29.5,389.3c39.3,127-64.4,202.4-178.9,144.9
                   C2624.4,1539.9,2532.7,1588.5,2486.8,1538.2z"/>
           </g>
           <g>
               <path className="in2" d="M2642.5,851.1h-187.1c-7.6,0-13.7-6.1-13.7-13.7V693c0-7.6,6.1-13.7,13.7-13.7h187.1
                   c7.6,0,13.7,6.1,13.7,13.7v144.5C2656.2,845,2650.1,851.1,2642.5,851.1z"/>
               <path className="in6" d="M2646.3,832.5h-183.7V705.8c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1v124.7h181.8c0.5,0,1,0.4,1,1
                   C2647.2,832,2646.8,832.5,2646.3,832.5z"/>
               <rect x="2480.8" y="792.7" className="graphBar1" width="20.3"/>
               <rect x="2509.1" y="783.9" className="graphBar2" width="20.3"/>
               <rect x="2537.4" y="765.9" className="graphBar3" width="20.3"/>
               <rect x="2565.6" y="753.4" className="graphBar4" width="20.3"/>
               <rect x="2593.9" y="726.4" className="graphBar5" width="20.3"/>
           </g>
           <g>
               <g>
                   <path className="in2" d="M2872.9,851.1h-187.1c-7.6,0-13.7-6.1-13.7-13.7V693c0-7.6,6.1-13.7,13.7-13.7h187.1
                       c7.6,0,13.7,6.1,13.7,13.7v144.5C2886.5,845,2880.4,851.1,2872.9,851.1z"/>
                   <path className="in6" d="M2876.6,832.5h-183.7V712.7c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1v117.8h181.8c0.5,0,1,0.4,1,1
                       C2877.6,832,2877.2,832.5,2876.6,832.5z"/>
               </g>
               <path className="in6" d="M2693.9,831.5c30.8-2.7,35.1-126.4,80.7-70.9c43.4,52.7,45.7-45.2,92.8-45.2v115
                   C2867.4,830.4,2693.9,831.5,2693.9,831.5z"/>
           </g>
           <g>
               <path className="in2" d="M2886.7,663.1h-444.8v-37.9h444.8V663.1z M2443,662h442.6v-35.8H2443V662z"/>
               <rect x="2450.1" y="637.8" className="loadingBar" width="90.2" height="12.7"/>
           </g>
           <g>
               <path className="in2" d="M2886.7,1675.6h-444.8v-37.9h444.8V1675.6z M2443,1674.5h442.6v-35.8H2443V1674.5z"/>
               <rect x="2450.1" y="1650.3" className="loadingBar" width="90.2" height="12.7"/>
           </g>
           <g>
               <rect x="2443.2" y="875.3" className="in5" width="211.7" height="12"/>
               <rect x="2443.2" y="919.5" className="in5" width="185.1" height="12"/>
               <rect x="2443.2" y="897.1" className="in5" width="321.1" height="12"/>
           </g>
           <g>
               <circle className="in1" cx="2791.2" cy="478.3" r="18.7"/>
               <g>
                   <path className="signalBar3" d="M2725,419.7l-7.9-9c44.5-44.7,114-43.1,156.3,3.7l-8.4,8.6C2827,381.2,2764.9,379.7,2725,419.7z"/>
                   <path className="signalBar2" d="M2741.5,438.5l-7.9-9c35-34.8,89.2-33.5,122.5,2.9l-8.4,8.6C2818.7,409.5,2771.8,408.4,2741.5,438.5z"/>
                   <path className="signalBar1" d="M2757.5,456.6l-7.9-9c25.7-25.3,65.2-24.3,89.7,2.1l-8.4,8.6C2810.7,436.9,2778.5,436.1,2757.5,456.6z"/>
               </g>
           </g>
       </g>
       <g id="_robotic_arms">
           <g>
               <rect x="1856.4" y="537.2" className="in7" width="27" height="124.5"/>
               <path className="in2" d="M1886.3,623.7h-31.7c-5.6,4.6-9.2,11.5-9.2,19.3l0.1,22.8l50-0.1l-0.1-22.8
                   C1895.5,635.2,1891.9,628.3,1886.3,623.7z"/>
               <path className="in7" d="M1852.1,677.3L1852.1,677.3c-6.8-5.7-17.2-3-20.1,5.3l-24.2,68c-0.7,1.8,0.1,3.9,1.8,4.8l0,0
                   c1.8,1,4.1,0.5,5.2-1.3l39.7-60.2C1858,688.6,1857,681.4,1852.1,677.3z"/>
               <path className="in7" d="M1892.6,674.9L1892.6,674.9c7.4-4.9,17.4-0.9,19.4,7.7l16.1,70.4c0.4,1.9-0.6,3.8-2.4,4.6l0,0
                   c-1.9,0.8-4.1,0-5.1-1.9l-32.3-64.5C1885.4,685.4,1887.2,678.4,1892.6,674.9z"/>
               <g>
                   <rect x="1841.1" y="665.7" className="in7" width="59.2" height="7.1"/>
                   <path className="in7" d="M1857.2,673.6c0,3.2-2.6,5.8-5.8,5.8c-3.2,0-5.8-2.6-5.8-5.8c0-3.2,2.6-5.8,5.8-5.8
                       C1854.6,667.9,1857.2,670.4,1857.2,673.6z"/>
                   <path className="in7" d="M1895.5,673.6c0,3.2-2.6,5.8-5.8,5.8c-3.2,0-5.8-2.6-5.8-5.8c0-3.2,2.6-5.8,5.8-5.8
                       C1892.9,667.9,1895.5,670.4,1895.5,673.6z"/>
               </g>
           </g>
           <g>
               <rect x="1767.5" y="516.6" className="in7" width="102.1" height="41.2"/>
               <path className="in2" d="M1783.2,582.3h-83.7c-24.9,0-45.1-20.2-45.1-45.1l0,0c0-24.9,20.2-45.1,45.1-45.1h83.7V582.3z"/>
               <path className="in2" d="M1864.7,582.3h-59.1v-90.3h59.1c24.9,0,45.1,20.2,45.1,45.1l0,0C1909.9,562.1,1889.7,582.3,1864.7,582.3z"/>
               <g>
                   <path className="in7" d="M1875.2,558.7c-12.2,5.1-26.2-0.6-31.3-12.7c-5.1-12.1,0.6-26.1,12.8-31.1c12.2-5.1,26.2,0.6,31.3,12.7
                       C1893.1,539.7,1887.4,553.7,1875.2,558.7z M1860.6,524.1c-7,2.9-10.4,11-7.4,18c3,7,11.1,10.3,18.1,7.4c7-2.9,10.4-11,7.4-18
                       C1875.8,524.5,1867.6,521.2,1860.6,524.1z"/>
               </g>
           </g>
           <g>
               <polygon className="in7" points="1509.7,741.5 1437.8,630.7 1461,615.8 1533,726.6 			"/>
               <path className="in7" d="M1304.6,794.6L1304.6,794.6c-10.8-16.7-5.9-38.9,10.8-49.7l185.9-119.1l39.1,60.4l-185.9,119.1
                   C1337.7,816.1,1315.4,811.3,1304.6,794.6z"/>
               <g>
                   <path className="in2" d="M1524.4,743.6l201.1-148.1c25.8-19,32.3-54.8,14.8-81.6h0c-17.5-26.8-53-35.5-81-19.7l-217.4,122.5
                       L1524.4,743.6z"/>
                   <g>
                       <path className="in7" d="M1710.3,579.5c-18.1,11.7-42.3,6.5-54-11.4c-11.7-18-6.6-42.1,11.5-53.7c18.1-11.7,42.3-6.5,54,11.4
                           C1733.5,543.8,1728.4,567.9,1710.3,579.5z M1676.7,528.1c-10.5,6.8-13.4,20.7-6.6,31.1c6.8,10.4,20.8,13.4,31.3,6.6
                           c10.5-6.8,13.4-20.7,6.6-31.1C1701.2,524.3,1687.2,521.4,1676.7,528.1z"/>
                   </g>
               </g>
           </g>
           <g>
               <path className="in8" d="M1398.7,1390.4L1398.7,1390.4c-60.2-23.8-89.5-91.7-65.6-151.5l108.7-271.7c24-59.9,92.2-89.1,152.3-65.2
                   l0,0c60.2,23.8,89.5,91.7,65.6,151.5L1551,1325.1C1527.1,1385,1458.9,1414.2,1398.7,1390.4z"/>
               <path className="in9" d="M1630.8,1088.2L1630.8,1088.2c-48.9,42.2-123,37-165.4-11.7l-192.6-220.9c-42.4-48.7-37.2-122.3,11.8-164.6
                   v0c48.9-42.2,123-37,165.4,11.7l192.6,220.9C1685,972.4,1679.7,1046,1630.8,1088.2z"/>
               <g>
                   <path className="in7" d="M1316.4,727.6c-25.8,22.2-28.5,61-6.2,86.6c22.3,25.6,61.3,28.4,87.1,6.2c25.8-22.2,28.5-61,6.2-86.6
                       C1381.1,708.1,1342.1,705.4,1316.4,727.6z M1381,801.8c-15.4,13.3-38.7,11.6-52.1-3.7s-11.7-38.5,3.7-51.8
                       c15.4-13.3,38.7-11.6,52.1,3.7S1396.4,788.5,1381,801.8z"/>
               </g>
           </g>
           <g>
               <g>
                   <path className="in7" d="M1576.2,946.9c-31.7-12.5-67.6,2.8-80.2,34.3c-12.6,31.5,2.8,67.2,34.5,79.8c31.7,12.5,67.6-2.8,80.2-34.3
                       C1623.3,995.1,1607.9,959.4,1576.2,946.9z M1539.7,1038.1c-18.9-7.5-28.2-28.9-20.6-47.7s29-28,48-20.5
                       c18.9,7.5,28.2,28.9,20.7,47.7C1580.1,1036.4,1558.6,1045.6,1539.7,1038.1z"/>
               </g>
               <g>
                   <path className="in7" d="M1442,1240.4c-31.7-12.5-67.6,2.8-80.2,34.3c-12.6,31.5,2.8,67.2,34.5,79.8c31.7,12.5,67.6-2.8,80.2-34.3
                       C1489.1,1288.6,1473.7,1252.9,1442,1240.4z M1405.5,1331.6c-18.9-7.5-28.2-28.9-20.6-47.7c7.5-18.8,29-28,48-20.5
                       c18.9,7.5,28.2,28.9,20.7,47.7S1424.4,1339.1,1405.5,1331.6z"/>
               </g>
           </g>
           <g>
               <g>
                   <path className="in7" d="M1534.8,1604.9h-213.1v-97.5c0-54.9,44.5-99.5,99.5-99.5h14.2c54.9,0,99.5,44.5,99.5,99.5V1604.9z"/>
                   <g>
                       <path className="in2" d="M1397.7,1476c-20.2,17.4-22.4,47.9-4.9,68c17.5,20.1,48.1,22.3,68.4,4.8c20.2-17.4,22.4-47.9,4.9-68
                           C1448.5,1460.7,1417.9,1458.6,1397.7,1476z M1448.4,1534.2c-12.1,10.4-30.4,9.1-40.9-2.9c-10.5-12-9.2-30.2,2.9-40.7
                           c12.1-10.4,30.4-9.1,40.9,2.9C1461.8,1505.6,1460.5,1523.8,1448.4,1534.2z"/>
                   </g>
               </g>
               <path className="in3" d="M1634.4,1708h-406.7v-56.5c0-28.7,23.3-51.9,51.9-51.9h302.9c28.7,0,51.9,23.3,51.9,51.9V1708z"/>
               <rect x="1160.9" y="1674.8" className="in7" width="539.7" height="108.4"/>
           </g>
       </g>
       <path className="in10" d="M3285.5,1794.2H238.2c-3,0-5.3-2.4-5.3-5.3s2.4-5.3,5.3-5.3h3047.3c3,0,5.3,2.4,5.3,5.3
           S3288.4,1794.2,3285.5,1794.2z"/>
       <g id="Machine">
           <g>
               <rect x="377.5" y="1068.8" className="caixa1" width="252.6" height="221.7"/>
               <rect x="453.7" y="1068.8" className="sombraCaixa" width="252.6" height="221.7"/>
               <g>
                   <rect x="558.9" y="1068.8" className="faixaCaixa" width="27.3" height="44.7"/>
               </g>
               <g>
                   <rect x="631.3" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                   <rect x="667.7" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                   <rect x="594.6" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
               </g>
           </g>

           <g>
                <rect x="833.6" y="1068.8" className="caixa1" width="252.6" height="221.7"/>
                <rect x="909.8" y="1068.8" className="sombraCaixa" width="252.6" height="221.7"/>
                <g>
                    <rect x="1015" y="1068.8" className="faixaCaixa" width="27.3" height="44.7"/>
                </g>
                <g>
                    <rect x="1087.4" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                    <rect x="1123.8" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                    <rect x="1050.7" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                </g>
            </g>

            <g>
                <rect x="1062.7" y="1068.8" className="caixa1" width="252.6" height="221.7"/>
                <rect x="1138.9" y="1068.8" className="sombraCaixa" width="252.6" height="221.7"/>
                <g>
                    <rect x="1244.1" y="1068.8" className="faixaCaixa" width="27.3" height="44.7"/>
                </g>
                <g>
                    <rect x="1316.5" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                    <rect x="1352.9" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                    <rect x="1279.8" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                </g>
            </g>

           <g>
               <path className="in2" d="M2763.4,1301.2H1034c-41.1,0-74.4,33.3-74.4,74.4v0c0,41.1,33.3,74.4,74.4,74.4h1729.4V1301.2z"/>
               <rect x="1938.4" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1905.5" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1970.8" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1971.2" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1937.9" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2004.1" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2037" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2003.7" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2069.4" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2036.5" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1905" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1774" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2069.9" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1741.1" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1773.5" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1872.6" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1740.6" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1708.2" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1872.1" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2464.4" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1839.7" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1839.3" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1806.8" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2233.8" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2299.6" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2365.8" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2332.9" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2332.4" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2300" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2365.3" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2431.5" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2398.2" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2464" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2398.7" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2431.1" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2266.7" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2168.1" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2135.6" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2102.8" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2135.2" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2168.5" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2102.3" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2267.1" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2234.3" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1707.8" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2201.4" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2200.9" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1806.4" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1149.3" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1181.7" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1083.5" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1116.4" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1182.2" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1115.9" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1148.8" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1247.9" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1214.6" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1280.3" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1247.5" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1215" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1050.6" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1675.3" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <path className="in7" d="M962.4,1383.3l-13.1,1.4c0.6,5.9,1.9,11.8,3.7,17.4l12.5-4.1C964,1393.2,963,1388.3,962.4,1383.3z"/>
               <path className="in7" d="M1004.6,1310l-5.4-12c-5.4,2.4-10.6,5.4-15.4,9l7.8,10.6C995.6,1314.6,1000,1312,1004.6,1310z"/>
               <path className="in7" d="M980.4,1327.6l-9.8-8.8c-4,4.4-7.5,9.3-10.4,14.4l11.4,6.6C974.1,1335.5,977.1,1331.4,980.4,1327.6z"/>
               <path className="in7" d="M965.5,1353.5l-12.5-4.1c-1.8,5.6-3.1,11.5-3.7,17.4l13.1,1.3C962.9,1363.2,964,1358.3,965.5,1353.5z"/>
               <path className="in7" d="M984,1444.5c4.8,3.5,10,6.5,15.4,8.9l5.4-12c-4.6-2-8.9-4.6-13-7.5L984,1444.5z"/>
               <rect x="1083.1" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1050.2" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1280.8" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <path className="in7" d="M1033.8,1303.7l0-13.2c-6,0-11.9,0.7-17.7,1.9l2.8,12.9C1023.7,1304.2,1028.7,1303.7,1033.8,1303.7z"/>
               <path className="in7" d="M1019.1,1446.1l-2.7,12.9c5.8,1.2,11.7,1.8,17.7,1.8h0.2v-13.2h-0.2
                   C1029,1447.6,1023.9,1447.1,1019.1,1446.1z"/>
               <path className="in7" d="M960.3,1418.3c3,5.1,6.5,10,10.5,14.4l9.8-8.8c-3.4-3.7-6.3-7.8-8.9-12.1L960.3,1418.3z"/>
               <rect x="1543.8" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1576.2" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1510.5" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1510.9" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1576.7" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1543.4" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1674.9" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1642.5" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1642" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1609.1" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1609.6" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1445.2" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1379.4" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1379" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1346.6" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1313.7" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1346.1" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1477.6" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1411.8" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1478.1" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1313.2" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="1412.3" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="1444.7" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2562.6" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2759.9" y="1290.5" className="in7" width="3.6" height="13.2"/>
               <rect x="2694.1" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2760.3" y="1447.6" className="in7" width="3.1" height="13.2"/>
               <rect x="2727" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2694.6" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2496.8" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2563.1" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2530.2" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2529.7" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2497.3" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2628.8" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2661.7" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2661.2" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2595.5" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2595.9" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <rect x="2628.4" y="1290.5" className="in7" width="16.4" height="13.2"/>
               <rect x="2727.4" y="1447.6" className="in7" width="16.4" height="13.2"/>
               <path className="in7" d="M2763.4,1443.5H1034c-37.4,0-67.9-30.4-67.9-67.9s30.4-67.9,67.9-67.9h1729.4v-13.2H1034
                   c-44.7,0-81,36.4-81,81c0,44.7,36.4,81,81,81h1729.4V1443.5z"/>
               <path className="in7" d="M2747,1443.5H1017.6c-37.4,0-67.9-30.4-67.9-67.9s30.4-67.9,67.9-67.9H2747v-13.2H1017.6
                   c-44.7,0-81,36.4-81,81c0,44.7,36.4,81,81,81H2747V1443.5z"/>
               <g>
                   <path className="in3" d="M1013,1345c-16.1,14-17.9,38.4-3.9,54.5c14,16.1,38.4,17.9,54.5,3.9c16.1-14,17.9-38.4,3.9-54.5
                       C1053.5,1332.7,1029.1,1331,1013,1345z M1053.4,1391.6c-9.6,8.4-24.2,7.3-32.6-2.3c-8.4-9.6-7.3-24.2,2.3-32.6
                       c9.6-8.4,24.2-7.3,32.6,2.3C1064.1,1368.7,1063.1,1383.3,1053.4,1391.6z"/>
               </g>
               <g>
                   <path className="in3" d="M1416.2,1345c-16.1,14-17.9,38.4-3.9,54.5c14,16.1,38.4,17.9,54.5,3.9c16.1-14,17.9-38.4,3.9-54.5
                       C1456.8,1332.7,1432.4,1331,1416.2,1345z M1456.7,1391.6c-9.6,8.4-24.2,7.3-32.6-2.3c-8.4-9.6-7.3-24.2,2.3-32.6
                       c9.6-8.4,24.2-7.3,32.6,2.3C1467.4,1368.7,1466.4,1383.3,1456.7,1391.6z"/>
               </g>
               <g>
                   <path className="in3" d="M1819.5,1345c-16.1,14-17.9,38.4-3.9,54.5c14,16.1,38.4,17.9,54.5,3.9c16.1-14,17.9-38.4,3.9-54.5
                       C1860.1,1332.7,1835.7,1331,1819.5,1345z M1860,1391.6c-9.6,8.4-24.2,7.3-32.6-2.3c-8.4-9.6-7.3-24.2,2.3-32.6
                       c9.6-8.4,24.2-7.3,32.6,2.3C1870.7,1368.7,1869.6,1383.3,1860,1391.6z"/>
               </g>
               <g>
                   <path className="in3" d="M2222.8,1345c-16.1,14-17.9,38.4-3.9,54.5c14,16.1,38.4,17.9,54.5,3.9c16.1-14,17.9-38.4,3.9-54.5
                       C2263.4,1332.7,2239,1331,2222.8,1345z M2263.3,1391.6c-9.6,8.4-24.2,7.3-32.6-2.3c-8.4-9.6-7.3-24.2,2.3-32.6
                       c9.6-8.4,24.2-7.3,32.6,2.3C2274,1368.7,2272.9,1383.3,2263.3,1391.6z"/>
               </g>
               <g>
                   <path className="in3" d="M2626.1,1345c-16.1,14-17.9,38.4-3.9,54.5c14,16.1,38.4,17.9,54.5,3.9c16.1-14,17.9-38.4,3.9-54.5
                       C2666.7,1332.7,2642.2,1331,2626.1,1345z M2666.6,1391.6c-9.6,8.4-24.2,7.3-32.6-2.3c-8.4-9.6-7.3-24.2,2.3-32.6
                       c9.6-8.4,24.2-7.3,32.6,2.3C2677.3,1368.7,2676.2,1383.3,2666.6,1391.6z"/>
               </g>
               <g>
                   <rect x="208.7" y="1301.2" className="in2" width="659.5" height="148.9"/>
                   <rect x="569.2" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="338.6" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="404.4" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="470.6" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="437.7" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="437.3" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="404.9" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="470.2" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="536.4" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="503" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="568.8" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="503.5" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="535.9" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="371.5" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="272.9" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="240.5" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="208.7" y="1447.6" className="in7" width="15.3" height="13.2"/>
                   <rect x="240" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="273.3" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="208.7" y="1290.5" className="in7" width="14.9" height="13.2"/>
                   <rect x="372" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="339.1" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="306.2" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="305.8" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="667.4" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="864.7" y="1290.5" className="in7" width="3.6" height="13.2"/>
                   <rect x="798.9" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="865.2" y="1447.6" className="in7" width="3.1" height="13.2"/>
                   <rect x="831.8" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="799.4" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="601.7" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="667.9" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="635" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="634.5" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="602.1" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="733.6" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="766.5" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="766.1" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="700.3" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="700.8" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="733.2" y="1290.5" className="in7" width="16.4" height="13.2"/>
                   <rect x="832.3" y="1447.6" className="in7" width="16.4" height="13.2"/>
                   <rect x="208.7" y="1443.5" className="in7" width="659.5" height="13.2"/>
                   <rect x="208.7" y="1294.6" className="in7" width="659.5" height="13.2"/>
                   <rect x="208.7" y="1443.5" className="in7" width="643.1" height="13.2"/>
                   <rect x="208.7" y="1294.6" className="in7" width="643.1" height="13.2"/>
                   <g>
                       <path className="in3" d="M327.7,1345c-16.1,14-17.9,38.4-3.9,54.5c14,16.1,38.4,17.9,54.5,3.9c16.1-14,17.9-38.4,3.9-54.5
                           C368.2,1332.7,343.8,1331,327.7,1345z M368.1,1391.6c-9.6,8.4-24.2,7.3-32.6-2.3c-8.4-9.6-7.3-24.2,2.3-32.6
                           c9.6-8.4,24.2-7.3,32.6,2.3C378.8,1368.7,377.8,1383.3,368.1,1391.6z"/>
                   </g>
                   <g>
                       <path className="in3" d="M730.9,1345c-16.1,14-17.9,38.4-3.9,54.5c14,16.1,38.4,17.9,54.5,3.9c16.1-14,17.9-38.4,3.9-54.5
                           C771.5,1332.7,747.1,1331,730.9,1345z M771.4,1391.6c-9.6,8.4-24.2,7.3-32.6-2.3c-8.4-9.6-7.3-24.2,2.3-32.6
                           c9.6-8.4,24.2-7.3,32.6,2.3C782.1,1368.7,781.1,1383.3,771.4,1391.6z"/>
                   </g>
               </g>
               <rect x="208.7" y="1445.3" className="in3" width="659.6" height="32.4"/>
               <polygon className="in3" points="474.9,1290.3 474.9,1477.9 408.4,1477.9 453.7,1290.5 			"/>
           </g>
           <path className="in3" d="M2763.5,1445.3H869.3c-8.9,0-16.2,7.2-16.2,16.2c0,8.9,7.2,16.2,16.2,16.2h1894.2V1445.3z"/>
           <rect x="903.3" y="911.7" className="in13" width="361.6" height="878.2"/>
           <rect x="903.3" y="911.7" className="in7" width="288.5" height="878.2"/>
           <g>
               <rect x="518" y="665.5" className="in14" width="604.9" height="45.4"/>
               
                   <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -289.3029 633.936)" className="in14" cx="620.6" cy="666.2" rx="25.4" ry="25.4"/>
               <path className="in2" d="M1172.3,1789.9V727.7c0-16.9-13.7-30.6-30.6-30.6H506.3c-16.9,0-30.6,13.7-30.6,30.6v1062.1H1172.3z"/>
               <g>
                   <rect x="524.2" y="1530.5" className="in3" width="23.9" height="189.7"/>
                   <rect x="570.6" y="1530.5" className="in3" width="23.9" height="189.7"/>
                   <rect x="617.1" y="1530.5" className="in3" width="23.9" height="189.7"/>
                   <rect x="663.6" y="1530.5" className="in3" width="23.9" height="189.7"/>
                   <rect x="710.1" y="1530.5" className="in3" width="23.9" height="189.7"/>
                   <rect x="756.5" y="1530.5" className="in3" width="23.9" height="189.7"/>
                   <rect x="803" y="1530.5" className="in3" width="23.9" height="189.7"/>
               </g>
               <rect x="519.7" y="747.2" className="in5" width="607.3" height="646.5"/>
               <g>
                   <g>
                       <circle className="in2" cx="717.2" cy="1145.4" r="75.1"/>
                       <g>
                           <path className="in15" d="M665.7,1101.5l25.5,24.1c-4.4,5.5-7.1,12.5-7.2,20.1h-35C649.1,1128.8,655.4,1113.3,665.7,1101.5z"/>
                           <path className="in15" d="M671.5,1095.6c11.8-10.6,27.2-17.2,44.2-17.6l-0.5,35.1c-6.8,0.4-13,2.8-18.1,6.7L671.5,1095.6z"/>
                           <path className="in15" d="M763.9,1096.5l-25.1,24.5c-4.3-3.7-9.5-6.3-15.3-7.4l0.5-35.3C739.3,1079.8,753.2,1086.4,763.9,1096.5z"
                               />
                           <path className="in15" d="M785.4,1145.7h-35c-0.1-6.9-2.4-13.3-6.1-18.6l25.2-24.6C779.4,1114.2,785.3,1129.2,785.4,1145.7z"/>
                       </g>
                       <g>
                           <polygon className="pointer" points="696.1,1091.7 723.8,1139.5 710.8,1145 						"/>
                           <path className="in16" d="M727.7,1138.4c2.4,5.7-0.3,12.2-5.9,14.6c-5.7,2.4-12.2-0.3-14.6-5.9c-2.4-5.7,0.3-12.2,5.9-14.6
                               C718.8,1130.1,725.4,1132.8,727.7,1138.4z"/>
                       </g>
                   </g>
                   <g>
                       <circle className="in2" cx="928.5" cy="1145.4" r="75.1"/>
                       <g>
                           <path className="in15" d="M877,1101.5l25.5,24.1c-4.4,5.5-7.1,12.5-7.2,20.1h-35C860.4,1128.8,866.7,1113.3,877,1101.5z"/>
                           <path className="in15" d="M882.8,1095.6c11.8-10.6,27.2-17.2,44.2-17.6l-0.5,35.1c-6.8,0.4-13,2.8-18.1,6.7L882.8,1095.6z"/>
                           <path className="in15" d="M975.2,1096.5l-25.1,24.5c-4.3-3.7-9.5-6.3-15.3-7.4l0.5-35.3C950.7,1079.8,964.5,1086.4,975.2,1096.5z"
                               />
                           <path className="in15" d="M996.8,1145.7h-35c-0.1-6.9-2.4-13.3-6.1-18.6l25.2-24.6C990.7,1114.2,996.6,1129.2,996.8,1145.7z"/>
                       </g>
                       <g>
                           <polygon className="pointer2" points="982.2,1128.3 931.2,1149.4 927.5,1135.8 						"/>
                           
                               <ellipse transform="matrix(0.3266 -0.9452 0.9452 0.3266 -454.61 1647.4476)" className="in16" cx="928.8" cy="1142.8" rx="11.1" ry="11.1"/>
                       </g>
                   </g>
                   <rect x="596.7" y="831.5" className="in2" width="447.6" height="168.3"/>
                   <g>
                       <g>
                           <rect x="736.1" y="951.2" className="in15" width="36.2" height="23.7"/>
                           <rect x="736.1" y="923.1" className="in15" width="36.2" height="23.7"/>
                           <rect x="736.1" y="895.1" className="column2line3" width="36.2" height="23.7"/>
                           <rect x="736.1" y="867" className="column2line4" width="36.2" height="23.7"/>
                       </g>
                       <g>
                           <rect x="778" y="951.2" className="in15" width="36.2" height="23.7"/>
                           <rect x="778" y="923.1" className="in15" width="36.2" height="23.7"/>
                           <rect x="778" y="895.1" className="column4line3" width="36.2" height="23.7"/>
                       </g>
                       <g>
                           <rect x="904.9" y="951.2" className="in15" width="36.2" height="23.7"/>
                           <rect x="904.9" y="923.1" className="in15" width="36.2" height="23.7"/>
                           <rect x="904.9" y="895.1" className="column2line2" width="36.2" height="23.7"/>
                       </g>
                       <g>
                           <rect x="820.6" y="951.2" className="in15" width="36.2" height="23.7"/>
                           <rect x="820.6" y="923.1" className="in15" width="36.2" height="23.7"/>
                           <rect x="820.6" y="895.1" className="column2line3" width="36.2" height="23.7"/>
                           <rect x="820.6" y="867" className="column2line4" width="36.2" height="23.7"/>
                       </g>
                       <g>
                           <rect x="862.4" y="951.2" className="in15" width="36.2" height="23.7"/>
                           <rect x="651.7" y="951.2" className="in15" width="36.2" height="23.7"/>
                           <rect x="862.4" y="923.1" className="column2line3" width="36.2" height="23.7"/>
                       </g>
                       <g>
                           <rect x="694.3" y="951.2" className="in15" width="36.2" height="23.7"/>
                           <rect x="694.3" y="923.1" className="column2line2" width="36.2" height="23.7"/>
                       </g>
                       <g>
                           <rect x="946.2" y="951.2" className="in15" width="36.2" height="23.7"/>
                           <rect x="946.2" y="923.1" className="in15" width="36.2" height="23.7"/>
                       </g>
                   </g>
               </g>
               <rect x="655.5" y="1285.1" className="in2" width="40.8" height="40.8"/>
               <rect x="717" y="1285.1" className="in2" width="40.8" height="40.8"/>
               <rect x="778.6" y="1285.1" className="in2" width="40.8" height="40.8"/>
               <rect x="840.2" y="1285.1" className="in2" width="40.8" height="40.8"/>
               <rect x="901.8" y="1285.1" className="in2" width="40.8" height="40.8"/>
               <rect x="963.4" y="1285.1" className="in2" width="40.8" height="40.8"/>
           </g>
           <g>
               <rect x="1518.8" y="1068.8" className="caixa1" width="252.6" height="221.7"/>
               <rect x="1594.9" y="1068.8" className="sombraCaixa" width="252.6" height="221.7"/>
               <g>
                   <rect x="1700.2" y="1068.8" className="faixaCaixa" width="27.3" height="44.7"/>
               </g>
               <g>
                   <rect x="1772.6" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                   <rect x="1809" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                   <rect x="1735.9" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
               </g>
           </g>
           <g>
               <rect x="1974.9" y="1068.8" className="caixa1" width="252.6" height="221.7"/>
               <rect x="2051" y="1068.8" className="sombraCaixa" width="252.6" height="221.7"/>
               <g>
                   <rect x="2156.2" y="1068.8" className="faixaCaixa" width="27.3" height="44.7"/>
               </g>
               <g>
                   <rect x="2228.6" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                   <rect x="2265" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                   <rect x="2192" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
               </g>
           </g>
           <g>
               <circle className="in1" cx="622.2" cy="596.4" r="22.8"/>
               <g>
                   <path className="signalBar3" d="M541.3,524.8l-9.7-11c54.4-54.6,139.1-52.5,190.8,4.5l-10.2,10.5C665.9,477.9,590.1,476.1,541.3,524.8z"/>
                   <path className="signalBar2" d="M561.5,547.8l-9.7-11c42.6-42.5,108.8-40.9,149.4,3.5L691,550.8C655.7,512.4,598.5,511.1,561.5,547.8z"/>
                   <path className="signalBar1" d="M581,569.9l-9.7-11c31.3-30.8,79.5-29.7,109.4,2.5L670.4,572C645.9,545.8,606.6,544.9,581,569.9z"/>
               </g>
           </g>
           <g>
               
                   <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.0203 1812.3228)" className="checkCircle" cx="2185.2" cy="912.2" rx="74.2" ry="74.2"/>
               <path className="in4" d="M2167.9,951.2c-2.9,0-5.6-1.1-7.6-3.2l-23.4-23.4c-4.2-4.2-4.2-11.1,0-15.3c4.2-4.2,11.1-4.2,15.3,0
                   l15.8,15.8l46.9-46.9c4.2-4.2,11.1-4.2,15.3,0c4.2,4.2,4.2,11.1,0,15.3l-54.6,54.6C2173.5,950.1,2170.8,951.2,2167.9,951.2z"/>
           </g>
           <g>
               
                   <ellipse transform="matrix(0.2298 -0.9732 0.9732 0.2298 1020.4095 2651.176)" className="in17" cx="2185.2" cy="680.9" rx="74.2" ry="74.2"/>
               <path className="in4" d="M2167.9,719.9c-2.9,0-5.6-1.1-7.6-3.2l-23.4-23.4c-4.2-4.2-4.2-11.1,0-15.3c4.2-4.2,11.1-4.2,15.3,0
                   l15.8,15.8l46.9-46.9c4.2-4.2,11.1-4.2,15.3,0c4.2,4.2,4.2,11.1,0,15.3l-54.6,54.6C2173.5,718.8,2170.8,719.9,2167.9,719.9z"/>
           </g>
           <g>
               <rect x="2430.9" y="1068.8" className="caixa1" width="252.6" height="221.7"/>
               <rect x="2507" y="1068.8" className="sombraCaixa" width="252.6" height="221.7"/>
               <g>
                   <rect x="2612.3" y="1068.8" className="faixaCaixa" width="27.3" height="44.7"/>
               </g>
               <g>
                   <rect x="2684.7" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                   <rect x="2721.1" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
                   <rect x="2648" y="1249.4" className="dadosCaixa" width="26.3" height="25.1"/>
               </g>
           </g>
       </g>
   </g>
   </svg>
    )
}

export default IndustryIllustration