import React,{useState, useEffect} from 'react';
import Web from '../../pages/Web/Home/Home'
import Mobile from '../../pages/Mobile/Home/Home'

const Routes = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const heigth =  window.innerHeight;

    useEffect(() => {

        const changeWidth = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', changeWidth)

        return function cleanup(){

            window.removeEventListener('resize', changeWidth)

        }


    }, [])

    if(width > 1024 && heigth < width){
        return(
            <Web />
        )
    }
    return(
        <Mobile />
    )
    
}

export default Routes;