import React from "react";
import './style.css'

const SmartFactory = () =>{
    return(
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 5000 2000">
    <g id="factory">
        <g>
            <g>
                <path className="st0" d="M3504.5,972.6h216.1v-12H1532.4v12h92.2v199.1l-92.2-86.6v16.9l83,77.9h-83v12h97.1c0.4,0.1,0.9,0.1,1.3,0.1
                    c0.4,0,0.9-0.1,1.3-0.1h230.1c0.4,0.1,0.9,0.1,1.3,0.1c0.4,0,0.9-0.1,1.3-0.1h230.1c0.4,0.1,0.9,0.1,1.3,0.1
                    c0.4,0,0.9-0.1,1.3-0.1h229.3c0.4,0.1,0.9,0.1,1.3,0.1c0.4,0,0.9-0.1,1.3-0.1h230.1c0.4,0.1,0.9,0.1,1.3,0.1
                    c0.4,0,0.9-0.1,1.3-0.1h230.1c0.4,0.1,0.9,0.1,1.3,0.1c0.4,0,0.9-0.1,1.3-0.1h230.1c0.4,0.1,0.9,0.1,1.3,0.1
                    c0.4,0,0.9-0.1,1.3-0.1H3256c0.4,0.1,0.9,0.1,1.3,0.1c0.4,0,0.9-0.1,1.3-0.1h230.1c0.4,0.1,0.9,0.1,1.3,0.1
                    c0.4,0,0.9-0.1,1.3-0.1h229.3v-3.8l6.3-6.7L3504.5,972.6z M1637,1180V981.7l211.1,198.3H1637z M1857.3,1171.7l-212-199.1h212
                    V1171.7z M1869.7,1180V981.7l211.1,198.3H1869.7z M2090,1171.7l-212-199.1h212V1171.7z M2102.4,1180V982.5l210.3,197.5H2102.4z
                    M2321.9,1171.7l-212-199.1h212V1171.7z M2334.2,1180V981.7l211.1,198.3H2334.2z M2554.6,1171.7l-212-199.1h212V1171.7z
                    M2567,1180V981.7l211.1,198.3H2567z M2787.3,1171.7l-212-199.1h212V1171.7z M2799.7,1180V981.7l211.1,198.3H2799.7z
                    M3020,1171.7l-212-199.1h212V1171.7z M3032.4,1180V983.2l209.5,196.7H3032.4z M3251.1,1171.7l-212-199.1h212V1171.7z
                    M3263.4,1180V981.7l211.1,198.3H3263.4z M3483.8,1171.7l-212-199.1h212V1171.7z M3496.1,1180V981.7l211.1,198.3H3496.1z"/>
                <g className="gears">
                    <path className="gearSmartFactory" d="M1155.9,1008.9c-1.8-7.1-5-13.4-9.2-18.9l1.7-1.3l3.4-2.6l0,0c-4.8-6.3-10.7-11.5-17.4-15.4l-3.3,5.6
                        c-6.1-3.5-12.8-5.9-19.9-6.9l0.9-6.5c-7.5-1.1-15.4-0.7-23.2,1.4l1.6,6.4c-7.1,1.8-13.4,5-18.9,9.2l-4-5.2
                        c-6.3,4.8-11.5,10.7-15.4,17.4l5.6,3.3c-3.5,6.1-5.9,12.8-6.9,19.9l-6.5-0.9c-1.1,7.5-0.7,15.4,1.4,23.2l6.4-1.6
                        c1.8,7.1,5,13.4,9.2,18.9l-3.7,2.8l-1.5,1.2l0,0c4.8,6.3,10.7,11.5,17.4,15.4l3.3-5.6c6.1,3.5,12.8,5.9,19.9,6.9l-0.9,6.5
                        c7.5,1.1,15.4,0.7,23.2-1.4l-1.6-6.4c7.1-1.8,13.5-5,18.9-9.2l3.1,4.1l0.9,1.2c6.3-4.8,11.5-10.7,15.4-17.4l-5.6-3.3
                        c3.5-6.1,5.9-12.8,6.9-19.9l6.5,0.9c1-7.5,0.6-15.4-1.4-23.2L1155.9,1008.9z M1111.4,1050.4c-15.5,4-31.4-5.3-35.4-20.8
                        c-4-15.5,5.3-31.3,20.8-35.4c15.5-4,31.3,5.3,35.4,20.8C1136.2,1030.6,1126.9,1046.4,1111.4,1050.4z"/>
                </g>
                <g>
                    <g>
                        
                            <ellipse transform="matrix(0.3913 -0.9203 0.9203 0.3913 361.3947 2440.8127)" className="st2" cx="2025.8" cy="947.2" rx="36.3" ry="36.3"/>
                    </g>
                    <g>
                        <ellipse className="st3" cx="2026.2" cy="960.2" rx="5.8" ry="5.7"/>
                        <g>
                            <path className="signaBar3" d="M2047.9,940.9L2047.9,940.9c0.7-0.7,0.6-1.9-0.1-2.6c-13.3-11.9-32.9-11.5-45.6,1.1
                                c-0.7,0.7-0.7,1.8,0,2.6l0,0c0.7,0.7,1.9,0.8,2.6,0.1c11.3-11,28.7-11.4,40.5-0.9C2046,941.8,2047.2,941.7,2047.9,940.9z"/>
                            <path className="signaBar2" d="M2042.8,946.7L2042.8,946.7c0.7-0.8,0.6-1.9-0.2-2.6c-10.3-8.9-25.2-8.5-35,0.8c-0.7,0.7-0.8,1.9-0.1,2.6
                                l0,0c0.7,0.7,1.9,0.8,2.6,0.1c8.5-7.9,21.2-8.2,30.1-0.7C2040.9,947.5,2042.1,947.4,2042.8,946.7z"/>
                            <path className="signaBar1" d="M2037.8,952.3L2037.8,952.3c0.7-0.8,0.6-2-0.2-2.6c-7.4-6-17.7-5.7-24.8,0.6c-0.8,0.7-0.8,1.9-0.1,2.6
                                l0,0c0.7,0.7,1.8,0.7,2.6,0.1c5.8-4.9,14-5.1,20-0.5C2036,953.1,2037.2,953,2037.8,952.3z"/>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path className="st1" d="M4098.3,1069.1c0.6,4.3,1.4,8.6,2.4,12.8h63.7c-6.9-14.6-8.9-31.7-4.5-48.5c9.9-38.3,49.1-61.3,87.4-51.4
                            c38.4,10,61.4,49.1,51.4,87.4c-1.1,4.4-2.7,8.6-4.5,12.5h80.2c3.6-17.1,4.1-34.2,1.8-50.6l-16.1,2.2
                            c-2.3-17.5-8.2-34.1-17-49.1l13.9-8.2c-9.7-16.5-22.6-31.1-38.1-42.9l-9.8,12.9c-13.5-10.3-29.3-18.1-46.8-22.7l4.1-15.7
                            c-19.3-5-38.7-6-57.3-3.4l2.2,16.1c-17.5,2.3-34.1,8.2-49.1,17l-8.2-13.9c-16.5,9.7-31.1,22.6-42.9,38.1l0.1,0.1l8.5,6.5
                            l4.3,3.3c-10.3,13.5-18.2,29.3-22.7,46.8l-15.7-4.1c-5,19.3-6,38.7-3.4,57.2L4098.3,1069.1z"/>
                    </g>
                    <g>
                        <path className="gear2" d="M3951,835.1c-6.1,9.3-10,19.3-12,29.5l8.8,1.7c-1.9,9.6-1.8,19.5,0.2,28.9l-8.8,1.8
                            c2.2,10.4,6.3,20.4,12.4,29.4l1.7-1.1l5.8-3.9c5.3,7.8,12.2,14.8,20.6,20.3l-4.9,7.5c9.3,6.1,19.3,10,29.5,12l1.7-8.8
                            c9.6,1.9,19.5,1.8,28.9-0.2l1.8,8.8c10.4-2.2,20.4-6.3,29.4-12.4l0,0l-1.4-2.2l-3.6-5.3c7.8-5.3,14.8-12.2,20.3-20.6l7.5,4.9
                            c6.1-9.3,10.1-19.3,12-29.6l-8.8-1.7c1.9-9.6,1.8-19.4-0.2-28.9l8.8-1.8c-2.2-10.4-6.3-20.4-12.4-29.4l-7.5,5
                            c-5.3-7.8-12.2-14.8-20.6-20.3l4.9-7.5c-9.3-6.1-19.3-10.1-29.6-12l-1.7,8.8c-9.6-1.9-19.4-1.8-28.9,0.2l-1.8-8.8
                            c-10.4,2.1-20.4,6.3-29.4,12.4l0,0.1l3.3,4.9l1.7,2.5c-7.8,5.3-14.8,12.2-20.3,20.6L3951,835.1z M3986.6,858.4
                            c12.1-18.4,36.8-23.5,55.2-11.5c18.4,12.1,23.6,36.8,11.5,55.2c-12.1,18.4-36.8,23.6-55.2,11.5
                            C3979.6,901.6,3974.5,876.9,3986.6,858.4z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <g>
                                <path className="st1" d="M966.4,1491c-0.9,0-1.7-0.8-1.7-1.7v-274.8c0-0.9,0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7v274.8
                                    C968.1,1490.2,967.4,1491,966.4,1491z"/>
                                <path className="st1" d="M931.6,1491c-0.9,0-1.7-0.8-1.7-1.7v-274.8c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7v274.8
                                    C933.3,1490.2,932.5,1491,931.6,1491z"/>
                                <path className="st1" d="M931.8,1490c-0.4,0-0.9-0.2-1.2-0.5c-0.6-0.7-0.6-1.7,0-2.4l32-31.1h-31.1l-1.2-2.9l32.2-31.3h-31.1
                                    l-1.2-2.9l32.2-31.2h-30.9l-1.2-2.9l31.9-31h-30.9l-1.2-2.9l32.2-31.2h-30.9l-1.2-2.9l31.9-31h-30.9l-1.2-2.9l32.1-31.2
                                    h-30.9l-1.2-2.9l32.1-31.2h-30.8c-0.9,0-1.7-0.7-1.7-1.7s0.7-1.7,1.7-1.7h34.9l1.2,2.9l-32.1,31.2h30.9l1.2,2.9l-32.1,31.2
                                    h30.9l1.2,2.9l-31.9,31h30.9l1.2,2.9l-32.2,31.2h30.9l1.2,2.9l-31.9,31h30.9l1.2,2.9l-32.2,31.2h31.1l1.2,2.9l-32.2,31.3
                                    h31.1l1.2,2.9l-34.9,33.9C932.6,1489.9,932.2,1490,931.8,1490z"/>
                            </g>
                            <g>
                                <path className="st1" d="M995.6,1242c-0.2,0-0.4-0.1-0.7-0.2c-0.4-0.2-0.7-0.7-0.7-1.1v-19.2l-32.1,20.3c-0.4,0.3-0.9,0.3-1.3,0
                                    c-0.4-0.2-0.7-0.7-0.7-1.1v-19.1c-1.1,0.7-2.2,1.3-3.2,2c-9.9,6.1-19.3,11.8-28.6,18.1c-0.4,0.3-0.9,0.3-1.4,0.1
                                    c-0.4-0.2-0.7-0.7-0.7-1.2v-19.1c-8.9,5.8-19.2,12.2-32.1,20.2c-0.4,0.3-0.9,0.3-1.3,0c-0.4-0.2-0.7-0.7-0.7-1.1v-19
                                    c-4.5,3.1-9.2,5.8-13.8,8.5c-6,3.5-12.2,7.1-18,11.5c-0.4,0.3-0.9,0.4-1.4,0.1c-0.4-0.2-0.7-0.7-0.7-1.2v-19.1
                                    c-3.5,2.2-7.1,4.4-10.6,6.6c-7.2,4.4-14.6,8.9-21.4,13.6c-0.4,0.3-0.9,0.3-1.4,0.1c-0.4-0.2-0.7-0.7-0.7-1.2v-19.1
                                    c-3.6,2.3-7.2,4.5-10.7,6.7c-6.9,4.3-14.1,8.8-21.2,13.5c-0.6,0.4-1.4,0.2-1.8-0.4c-0.4-0.6-0.2-1.4,0.4-1.8
                                    c7.2-4.7,14.3-9.2,21.3-13.5c4.2-2.6,8.5-5.3,12.7-8c0.4-0.3,0.9-0.3,1.3,0c0.4,0.2,0.7,0.7,0.7,1.1v19
                                    c6.3-4.3,13-8.4,19.5-12.3c4.2-2.5,8.5-5.2,12.6-7.8c0.4-0.3,0.9-0.3,1.3,0c0.4,0.2,0.7,0.7,0.7,1.1v18.8
                                    c5.2-3.8,10.8-7,16.1-10.1c5.2-3,10.6-6.2,15.7-9.8c0.4-0.3,0.9-0.3,1.4-0.1c0.4,0.2,0.7,0.7,0.7,1.2v19.1
                                    c13-8,23.2-14.5,32.1-20.3c0.4-0.3,0.9-0.3,1.3-0.1c0.4,0.2,0.7,0.7,0.7,1.2v19c8.7-5.8,17.4-11.2,26.6-16.8
                                    c1.7-1.1,3.5-2.1,5.2-3.2c0.4-0.2,0.9-0.3,1.3,0c0.4,0.2,0.7,0.7,0.7,1.1v19.1l32.1-20.3c0.4-0.3,0.9-0.3,1.3,0
                                    c0.4,0.2,0.7,0.7,0.7,1.1v19.3c8.7-5.1,18.1-11.1,28.7-18.2c0.6-0.4,1.4-0.2,1.8,0.4c0.4,0.6,0.2,1.4-0.4,1.8
                                    c-11.5,7.7-21.5,14-30.8,19.4C996.1,1241.9,995.9,1242,995.6,1242z"/>
                                <path className="st1" d="M909.1,1241.9c-39.2,0-78.5,0-117.5-0.1c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0,0,0,0,0,0
                                    c77.6,0.1,156.1,0.1,233.4,0l0-18.7c-66,0.3-133.1,0.3-199.4,0c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0,0,0,0,0,0
                                    c66.8,0.3,134.3,0.3,200.7,0c0,0,0,0,0,0c0.4,0,0.7,0.1,0.9,0.4c0.3,0.3,0.4,0.6,0.4,0.9l0,21.4c0,0.7-0.6,1.3-1.3,1.3
                                    C987.4,1241.9,948.3,1241.9,909.1,1241.9z"/>
                                <path className="st1" d="M793,1311.4c-0.7,0-1.3-0.6-1.3-1.3v-69.6c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3v69.6
                                    C794.3,1310.8,793.7,1311.4,793,1311.4z"/>
                            </g>
                            <rect x="920" y="1205.3" className="st1" width="58.3" height="50.9"/>
                            <path className="st1" d="M966.6,1217.5c-0.5,0-1-0.3-1.2-0.8l-31.6-81.9v78.2c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3v-85.2
                                l2.5-0.5l34.1,88.4c0.3,0.7-0.1,1.4-0.8,1.7C966.9,1217.5,966.8,1217.5,966.6,1217.5z"/>
                            <path className="st1" d="M932.8,1204.4c-0.3,0-0.5-0.1-0.8-0.2c-0.6-0.4-0.7-1.2-0.3-1.8l16.9-23.7h-15.8c-0.7,0-1.3-0.6-1.3-1.3
                                s0.6-1.3,1.3-1.3h18.3l1.1,2.1l-18.3,25.8C933.6,1204.2,933.2,1204.4,932.8,1204.4z"/>
                        </g>
                        <polygon className="st1" points="1664.7,1699.9 1664.7,1213.7 1446.9,1398.9 1446.9,1699.9 					"/>
                    </g>
                    <g>
                        <polygon className="st1" points="1078.8,1699.9 1078.8,1609.9 1008.1,1409.2 1008.1,1291 857.8,1291 857.8,1409.2 787.1,1609.9 
                            787.1,1699.9 					"/>
                        <rect x="848.7" y="1290.5" className="st1" width="166.4" height="6.9"/>
                    </g>
                    <polygon className="st1" points="1588.5,1699.9 1588.5,1413.7 1434.8,1298 1287.3,1413.7 977.5,1413.7 977.5,1699.9 				"/>
                    <rect x="1010.2" y="1495.5" className="st1" width="65.1" height="61.5"/>
                    <rect x="1090.5" y="1495.5" className="st5" width="65.1" height="61.5"/>
                    <rect x="1010.2" y="1594.6" className="st1" width="65.1" height="105.4"/>
                    <rect x="1078.9" y="1594.6" className="st1" width="65.1" height="105.4"/>
                    <rect x="1171.9" y="1594.6" className="st5" width="65.1" height="105.4"/>
                    <rect x="1092.3" y="1594.6" className="st5" width="65.1" height="105.4"/>
                    <rect x="1252.4" y="1594.6" className="st5" width="65.1" height="105.4"/>
                    <rect x="1323.3" y="1594.6" className="st1" width="65.1" height="105.4"/>
                    <rect x="1392" y="1594.6" className="st1" width="65.1" height="105.4"/>
                    <rect x="1492" y="1594.6" className="st1" width="65.1" height="105.4"/>
                    <rect x="1170.7" y="1495.5" className="st5" width="65.1" height="61.5"/>
                    <rect x="1251" y="1495.5" className="st5" width="65.1" height="61.5"/>
                    <rect x="1331.3" y="1495.5" className="st1" width="65.1" height="61.5"/>
                    <rect x="1411.6" y="1495.5" className="st1" width="65.1" height="61.5"/>
                    <rect x="1491.9" y="1495.5" className="st1" width="65.1" height="61.5"/>
                    <polygon className="st1" points="1147,1426.1 1042.6,1426.1 1048.8,1301 1052,1237.7 1056.9,1139.8 1132.7,1139.8 1137.6,1237.7 
                        1140.8,1301 				"/>
                    <rect x="1048.7" y="1138.9" className="st1" width="94.1" height="6.3"/>
                    <polygon className="st1" points="1137.6,1237.7 1140.8,1301 1048.8,1301 1052,1237.7 				"/>
                    <g>
                        <polygon className="st1" points="1298.2,1426.1 1174.5,1426.1 1180.7,1301 1183.9,1237.7 1191.4,1086.8 1281.3,1086.8 
                            1288.8,1237.7 1292,1301 					"/>
                        <rect x="1183.3" y="1086.5" className="st1" width="108" height="6.3"/>
                        <polygon className="st1" points="1292,1301 1180.7,1301 1183.9,1237.7 1288.8,1237.7 					"/>
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        <rect x="2762.6" y="1313.6" className="caixa1SmartFactory" width="138.7" height="121.8"/>
                        <rect x="2720.8" y="1313.6" className="sombraCaixaSmartFactory" width="138.7" height="121.8"/>
                        <g>
                            <rect x="2786.7" y="1313.6" className="faixaCaixaSmartFactory" width="15" height="24.6"/>
                        </g>
                        <g>
                            <rect x="2747.5" y="1412.8" className="dadosCaixaSmartFactory" width="14.4" height="13.8"/>
                            <rect x="2727.5" y="1412.8" className="dadosCaixaSmartFactory" width="14.4" height="13.8"/>
                            <rect x="2767.6" y="1412.8" className="dadosCaixaSmartFactory" width="14.4" height="13.8"/>
                        </g>
                    </g>
                    <g>
                        <rect x="3044.2" y="1313.6" className="caixa1SmartFactory" width="138.7" height="121.8" />
                        <rect x="3002.4" y="1313.6" className="sombraCaixaSmartFactory" width="138.7" height="121.8"/>
                        <g>
                            <rect x="3068.3" y="1313.6" className="faixaCaixaSmartFactory" width="15" height="24.6"/>
                        </g>
                        <g>
                            <rect x="3029.1" y="1412.8" className="dadosCaixaSmartFactory" width="14.4" height="13.8"/>
                            <rect x="3009.1" y="1412.8" className="dadosCaixaSmartFactory" width="14.4" height="13.8"/>
                            <rect x="3049.3" y="1412.8" className="dadosCaixaSmartFactory" width="14.4" height="13.8"/>
                        </g>
                    </g>
                    <g>
                        <rect x="2158.6" y="1313.6" className="caixa1SmartFactory" width="138.7" height="121.8"/>
                        <rect x="2116.8" y="1313.6" className="sombraCaixaSmartFactory" width="138.7" height="121.8"/>
                        <g>
                            <rect x="2182.7" y="1313.6" className="faixaCaixaSmartFactory" width="15" height="24.6"/>
                        </g>
                        <g>
                            <rect x="2143.5" y="1412.8" className="dadosCaixaSmartFactory" width="14.4" height="13.8"/>
                            <rect x="2123.5" y="1412.8" className="dadosCaixaSmartFactory" width="14.4" height="13.8"/>
                            <rect x="2163.6" y="1412.8" className="dadosCaixaSmartFactory" width="14.4" height="13.8"/>
                        </g>
                    </g>
                    <g>
                        <rect x="1870.7" y="1313.6" className="caixa1SmartFactory" width="138.7" height="121.8"/>
                        <rect x="1828.9" y="1313.6" className="sombraCaixaSmartFactory" width="138.7" height="121.8"/>
                        <g>
                            <rect x="1894.8" y="1313.6" className="faixaCaixaSmartFactory" width="15" height="24.6"/>
                        </g>
                        <g>
                            <rect x="1855.6" y="1412.8" className="dadosCaixaSmartFactory" width="14.4" height="13.8"/>
                            <rect x="1835.6" y="1412.8" className="dadosCaixaSmartFactory" width="14.4" height="13.8"/>
                            <rect x="1875.7" y="1412.8" className="dadosCaixaSmartFactory" width="14.4" height="13.8"/>
                        </g>
                    </g>
                </g>
                <path className="st8" d="M4391.6,1695.4h-557.9v-558.9c0-16,12.9-28.9,28.9-28.9h500.1c16,0,28.9,12.9,28.9,28.9V1695.4z"/>
                <g>
                    
                        <rect x="3620.3" y="1178.6" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 7457.5122 2852.6724)" className="st1" width="216.8" height="495.5"/>
                    <rect x="3620.8" y="1250" className="st9" width="216.8" height="2.2"/>
                    <rect x="3620.8" y="1474.7" className="st9" width="216.8" height="2.2"/>
                    <g>
                        <path className="st10" d="M3638,1270.7c-2.8,0-5.1,2.3-5.1,5.1v174.1c0,2.8,2.3,5.1,5.1,5.1h179.7c2.8,0,5.1-2.3,5.1-5.1v-174.1
                            c0-2.8-2.3-5.1-5.1-5.1H3638z"/>
                        <path className="st11" d="M3705.8,1297.7h51.1c3.1,0,5.6-2.5,5.6-5.6l0,0c0-3.1-2.5-5.6-5.6-5.6h-51.1c-3.1,0-5.6,2.5-5.6,5.6l0,0
                            C3700.2,1295.2,3702.8,1297.7,3705.8,1297.7z"/>
                    </g>
                    <polygon className="st12" points="3630.2,1178.6 3632.1,1672.4 3620.2,1672.4 3620.2,1178.6 				"/>
                </g>
                <g>
                    <g>
                        <path className="st13" d="M1724.6,1478.6c0,10.8,4.4,20.5,11.4,27.6c7.1,7.1,16.8,11.4,27.6,11.4h1697.5c21.6,0,39-17.5,39-39
                            c0-10.8-4.4-20.5-11.4-27.6c-7.1-7.1-16.8-11.4-27.6-11.4H1763.6C1742.1,1439.6,1724.6,1457,1724.6,1478.6z"/>
                        <path className="st14" d="M1772,1516.3h8.6v6.9h-8.6V1516.3z M1789.2,1516.3h8.6v6.9h-8.6V1516.3z M1806.5,1516.3h8.6v6.9h-8.6
                            V1516.3z M1823.7,1516.3h8.6v6.9h-8.6V1516.3z M1840.9,1516.3h8.6v6.9h-8.6V1516.3z M1858.2,1516.3h8.6v6.9h-8.6V1516.3z
                            M1875.4,1516.3h8.6v6.9h-8.6V1516.3z M1892.6,1516.3h8.6v6.9h-8.6V1516.3z M1909.9,1516.3h8.6v6.9h-8.6V1516.3z
                            M1927.1,1516.3h8.6v6.9h-8.6V1516.3z M1944.3,1516.3h8.6v6.9h-8.6V1516.3z M1961.6,1516.3h8.6v6.9h-8.6V1516.3z
                            M1978.8,1516.3h8.6v6.9h-8.6V1516.3z M1996,1516.3h8.6v6.9h-8.6V1516.3z M2013.3,1516.3h8.6v6.9h-8.6V1516.3z M2030.5,1516.3
                            h8.6v6.9h-8.6V1516.3z M2047.7,1516.3h8.6v6.9h-8.6V1516.3z M2065,1516.3h8.6v6.9h-8.6V1516.3z M2082.2,1516.3h8.6v6.9h-8.6
                            V1516.3z M2099.4,1516.3h8.6v6.9h-8.6V1516.3z M2116.7,1516.3h8.6v6.9h-8.6V1516.3z M2133.9,1516.3h8.6v6.9h-8.6V1516.3z
                            M2151.1,1516.3h8.6v6.9h-8.6V1516.3z M2168.4,1516.3h8.6v6.9h-8.6V1516.3z M2185.6,1516.3h8.6v6.9h-8.6V1516.3z
                            M2202.8,1516.3h8.6v6.9h-8.6V1516.3z M2220.1,1516.3h8.6v6.9h-8.6V1516.3z M2237.3,1516.3h8.6v6.9h-8.6V1516.3z
                            M2254.5,1516.3h8.6v6.9h-8.6V1516.3z M2271.8,1516.3h8.6v6.9h-8.6V1516.3z M2289,1516.3h8.6v6.9h-8.6V1516.3z M2306.2,1516.3
                            h8.6v6.9h-8.6V1516.3z M2323.5,1516.3h8.6v6.9h-8.6V1516.3z M2340.7,1516.3h8.6v6.9h-8.6V1516.3z M2357.9,1516.3h8.6v6.9h-8.6
                            V1516.3z M2375.2,1516.3h8.6v6.9h-8.6V1516.3z M2392.4,1516.3h8.6v6.9h-8.6V1516.3z M2409.6,1516.3h8.6v6.9h-8.6V1516.3z
                            M2426.9,1516.3h8.6v6.9h-8.6V1516.3z M2444.1,1516.3h8.6v6.9h-8.6V1516.3z M2461.4,1516.3h8.6v6.9h-8.6V1516.3z
                            M2478.6,1516.3h8.6v6.9h-8.6V1516.3z M2495.8,1516.3h8.6v6.9h-8.6V1516.3z M2513.1,1516.3h8.6v6.9h-8.6V1516.3z
                            M2530.3,1516.3h8.6v6.9h-8.6V1516.3z M2547.5,1516.3h8.6v6.9h-8.6V1516.3z M2564.8,1516.3h8.6v6.9h-8.6V1516.3z M2582,1516.3
                            h8.6v6.9h-8.6V1516.3z M2599.2,1516.3h8.6v6.9h-8.6V1516.3z M2616.5,1516.3h8.6v6.9h-8.6V1516.3z M2633.7,1516.3h8.6v6.9h-8.6
                            V1516.3z M2650.9,1516.3h8.6v6.9h-8.6V1516.3z M2668.2,1516.3h8.6v6.9h-8.6V1516.3z M2685.4,1516.3h8.6v6.9h-8.6V1516.3z
                            M2702.6,1516.3h8.6v6.9h-8.6V1516.3z M2719.9,1516.3h8.6v6.9h-8.6V1516.3z M2737.1,1516.3h8.6v6.9h-8.6V1516.3z
                            M2754.3,1516.3h8.6v6.9h-8.6V1516.3z M2771.6,1516.3h8.6v6.9h-8.6V1516.3z M2788.8,1516.3h8.6v6.9h-8.6V1516.3z M2806,1516.3
                            h8.6v6.9h-8.6V1516.3z M2823.3,1516.3h8.6v6.9h-8.6V1516.3z M2840.5,1516.3h8.6v6.9h-8.6V1516.3z M2857.7,1516.3h8.6v6.9h-8.6
                            V1516.3z M2875,1516.3h8.6v6.9h-8.6V1516.3z M2892.2,1516.3h8.6v6.9h-8.6V1516.3z M2909.4,1516.3h8.6v6.9h-8.6V1516.3z
                            M2926.7,1516.3h8.6v6.9h-8.6V1516.3z M2943.9,1516.3h8.6v6.9h-8.6V1516.3z M2961.1,1516.3h8.6v6.9h-8.6V1516.3z
                            M2978.4,1516.3h8.6v6.9h-8.6V1516.3z M2995.6,1516.3h8.6v6.9h-8.6V1516.3z M3012.8,1516.3h8.6v6.9h-8.6V1516.3z
                            M3030.1,1516.3h8.6v6.9h-8.6V1516.3z M3047.3,1516.3h8.6v6.9h-8.6V1516.3z M3064.5,1516.3h8.6v6.9h-8.6V1516.3z
                            M3081.8,1516.3h8.6v6.9h-8.6V1516.3z M3099,1516.3h8.6v6.9h-8.6V1516.3z M3116.2,1516.3h8.6v6.9h-8.6V1516.3z M3133.5,1516.3
                            h8.6v6.9h-8.6V1516.3z M3150.7,1516.3h8.6v6.9h-8.6V1516.3z M3167.9,1516.3h8.6v6.9h-8.6V1516.3z M3185.2,1516.3h8.6v6.9h-8.6
                            V1516.3z M3202.4,1516.3h8.6v6.9h-8.6V1516.3z M3219.6,1516.3h8.6v6.9h-8.6V1516.3z M3236.9,1516.3h8.6v6.9h-8.6V1516.3z
                            M3254.1,1516.3h8.6v6.9h-8.6V1516.3z M3271.3,1516.3h8.6v6.9h-8.6V1516.3z M3288.6,1516.3h8.6v6.9h-8.6V1516.3z
                            M3305.8,1516.3h8.6v6.9h-8.6V1516.3z M3323,1516.3h8.6v6.9h-8.6V1516.3z M3340.3,1516.3h8.6v6.9h-8.6V1516.3z M3357.5,1516.3
                            h8.6v6.9h-8.6V1516.3z M3374.7,1516.3h8.6v6.9h-8.6V1516.3z M3392,1516.3h8.6v6.9h-8.6V1516.3z M3409.2,1516.3h8.6v6.9h-8.6
                            V1516.3z M3426.4,1516.3h8.6v6.9h-8.6V1516.3z M3443.7,1516.3h8.6v6.9h-8.6V1516.3z M3460.9,1516.3h0.2c2.6,0,5.3-0.3,7.8-0.8
                            l1.4,6.8c-3,0.6-6.1,1-9.3,1h-0.2V1516.3z M1754.1,1522.2l1.5-6.8c2.6,0.6,5.2,0.8,7.8,0.9l0,6.9
                            C1760.2,1523.2,1757.1,1522.9,1754.1,1522.2z M3476.4,1513.1c2.4-1.1,4.7-2.4,6.8-3.9l4.1,5.6c-2.5,1.8-5.2,3.4-8.1,4.7
                            L3476.4,1513.1z M1737.2,1514.6l4.1-5.6c2.1,1.6,4.4,2.9,6.8,4l-2.9,6.3C1742.4,1518,1739.7,1516.4,1737.2,1514.6z
                            M3489.1,1503.9c1.8-2,3.3-4.1,4.6-6.4l6,3.5c-1.6,2.7-3.4,5.2-5.5,7.5L3489.1,1503.9z M1724.9,1500.8l6-3.4
                            c1.3,2.3,2.9,4.4,4.6,6.4l-5.2,4.6C1728.2,1506,1726.4,1503.4,1724.9,1500.8z M3497,1490.3c0.8-2.5,1.4-5.1,1.6-7.7l6.9,0.7
                            c-0.3,3.1-1,6.2-2,9.1L3497,1490.3z M1719.2,1483.1l6.9-0.7c0.3,2.6,0.8,5.2,1.6,7.7l-6.6,2.1
                            C1720.1,1489.3,1719.5,1486.2,1719.2,1483.1z M3497,1467l6.6-2.1c1,3,1.6,6,1.9,9.1l-6.9,0.7
                            C3498.3,1472.1,3497.8,1469.5,3497,1467z M1719.2,1473.8c0.3-3.1,1-6.2,2-9.1l6.6,2.2c-0.8,2.5-1.4,5.1-1.7,7.7L1719.2,1473.8z
                            M3489.2,1453.4l5.1-4.6c2.1,2.3,3.9,4.9,5.5,7.5l-6,3.5C3492.5,1457.5,3490.9,1455.4,3489.2,1453.4z M1725,1456.2
                            c1.6-2.7,3.4-5.2,5.5-7.5l5.1,4.6c-1.8,2-3.3,4.1-4.7,6.4L1725,1456.2z M3476.5,1444.2l2.8-6.3c2.8,1.3,5.5,2.9,8,4.7l-4.1,5.6
                            C3481.2,1446.6,3478.9,1445.3,3476.5,1444.2z M1737.5,1442.4c2.5-1.8,5.2-3.4,8.1-4.7l2.8,6.3c-2.4,1.1-4.7,2.4-6.8,3.9
                            L1737.5,1442.4z M3461.2,1440.9l0-6.9c3.1,0,6.2,0.3,9.3,1l-1.5,6.8C3466.5,1441.2,3463.9,1440.9,3461.2,1440.9z
                            M1754.4,1434.9c3-0.6,6.1-0.9,9.2-0.9h0.1v6.9h-0.1c-2.6,0-5.2,0.3-7.8,0.8L1754.4,1434.9z M1772.3,1434h8.6v6.9h-8.6V1434z
                            M1789.5,1434h8.6v6.9h-8.6V1434z M1806.8,1434h8.6v6.9h-8.6V1434z M1824,1434h8.6v6.9h-8.6V1434z M1841.2,1434h8.6v6.9h-8.6
                            V1434z M1858.5,1434h8.6v6.9h-8.6V1434z M1875.7,1434h8.6v6.9h-8.6V1434z M1892.9,1434h8.6v6.9h-8.6V1434z M1910.2,1434h8.6
                            v6.9h-8.6V1434z M1927.4,1434h8.6v6.9h-8.6V1434z M1944.6,1434h8.6v6.9h-8.6V1434z M1961.9,1434h8.6v6.9h-8.6V1434z
                            M1979.1,1434h8.6v6.9h-8.6V1434z M1996.3,1434h8.6v6.9h-8.6V1434z M2013.6,1434h8.6v6.9h-8.6V1434z M2030.8,1434h8.6v6.9h-8.6
                            V1434z M2048.1,1434h8.6v6.9h-8.6V1434z M2065.3,1434h8.6v6.9h-8.6V1434z M2082.5,1434h8.6v6.9h-8.6V1434z M2099.8,1434h8.6
                            v6.9h-8.6V1434z M2117,1434h8.6v6.9h-8.6V1434z M2134.2,1434h8.6v6.9h-8.6V1434z M2151.5,1434h8.6v6.9h-8.6V1434z M2168.7,1434
                            h8.6v6.9h-8.6V1434z M2185.9,1434h8.6v6.9h-8.6V1434z M2203.2,1434h8.6v6.9h-8.6V1434z M2220.4,1434h8.6v6.9h-8.6V1434z
                            M2237.6,1434h8.6v6.9h-8.6V1434z M2254.9,1434h8.6v6.9h-8.6V1434z M2272.1,1434h8.6v6.9h-8.6V1434z M2289.3,1434h8.6v6.9h-8.6
                            V1434z M2306.6,1434h8.6v6.9h-8.6V1434z M2323.8,1434h8.6v6.9h-8.6V1434z M2341,1434h8.6v6.9h-8.6V1434z M2358.3,1434h8.6v6.9
                            h-8.6V1434z M2375.5,1434h8.6v6.9h-8.6V1434z M2392.7,1434h8.6v6.9h-8.6V1434z M2410,1434h8.6v6.9h-8.6V1434z M2427.2,1434h8.6
                            v6.9h-8.6V1434z M2444.4,1434h8.6v6.9h-8.6V1434z M2461.7,1434h8.6v6.9h-8.6V1434z M2478.9,1434h8.6v6.9h-8.6V1434z
                            M2496.1,1434h8.6v6.9h-8.6V1434z M2513.4,1434h8.6v6.9h-8.6V1434z M2530.6,1434h8.6v6.9h-8.6V1434z M2547.8,1434h8.6v6.9h-8.6
                            V1434z M2565.1,1434h8.6v6.9h-8.6V1434z M2582.3,1434h8.6v6.9h-8.6V1434z M2599.5,1434h8.6v6.9h-8.6V1434z M2616.8,1434h8.6
                            v6.9h-8.6V1434z M2634,1434h8.6v6.9h-8.6V1434z M2651.2,1434h8.6v6.9h-8.6V1434z M2668.5,1434h8.6v6.9h-8.6V1434z M2685.7,1434
                            h8.6v6.9h-8.6V1434z M2702.9,1434h8.6v6.9h-8.6V1434z M2720.2,1434h8.6v6.9h-8.6V1434z M2737.4,1434h8.6v6.9h-8.6V1434z
                            M2754.6,1434h8.6v6.9h-8.6V1434z M2771.9,1434h8.6v6.9h-8.6V1434z M2789.1,1434h8.6v6.9h-8.6V1434z M2806.3,1434h8.6v6.9h-8.6
                            V1434z M2823.6,1434h8.6v6.9h-8.6V1434z M2840.8,1434h8.6v6.9h-8.6V1434z M2858,1434h8.6v6.9h-8.6V1434z M2875.3,1434h8.6v6.9
                            h-8.6V1434z M2892.5,1434h8.6v6.9h-8.6V1434z M2909.7,1434h8.6v6.9h-8.6V1434z M2927,1434h8.6v6.9h-8.6V1434z M2944.2,1434h8.6
                            v6.9h-8.6V1434z M2961.4,1434h8.6v6.9h-8.6V1434z M2978.7,1434h8.6v6.9h-8.6V1434z M2995.9,1434h8.6v6.9h-8.6V1434z
                            M3013.1,1434h8.6v6.9h-8.6V1434z M3030.4,1434h8.6v6.9h-8.6V1434z M3047.6,1434h8.6v6.9h-8.6V1434z M3064.8,1434h8.6v6.9h-8.6
                            V1434z M3082.1,1434h8.6v6.9h-8.6V1434z M3099.3,1434h8.6v6.9h-8.6V1434z M3116.5,1434h8.6v6.9h-8.6V1434z M3133.8,1434h8.6
                            v6.9h-8.6V1434z M3151,1434h8.6v6.9h-8.6V1434z M3168.2,1434h8.6v6.9h-8.6V1434z M3185.5,1434h8.6v6.9h-8.6V1434z M3202.7,1434
                            h8.6v6.9h-8.6V1434z M3219.9,1434h8.6v6.9h-8.6V1434z M3237.2,1434h8.6v6.9h-8.6V1434z M3254.4,1434h8.6v6.9h-8.6V1434z
                            M3271.7,1434h8.6v6.9h-8.6V1434z M3288.9,1434h8.6v6.9h-8.6V1434z M3306.1,1434h8.6v6.9h-8.6V1434z M3323.4,1434h8.6v6.9h-8.6
                            V1434z M3340.6,1434h8.6v6.9h-8.6V1434z M3357.8,1434h8.6v6.9h-8.6V1434z M3375.1,1434h8.6v6.9h-8.6V1434z M3392.3,1434h8.6
                            v6.9h-8.6V1434z M3409.5,1434h8.6v6.9h-8.6V1434z M3426.8,1434h8.6v6.9h-8.6V1434z M3444,1434h8.6v6.9h-8.6V1434z"/>
                        <path className="st14" d="M1721.1,1478.6c0-23.4,19.1-42.5,42.5-42.5h1697.5c23.4,0,42.5,19.1,42.5,42.5
                            c0,23.4-19.1,42.5-42.5,42.5H1763.6C1740.2,1521.1,1721.1,1502,1721.1,1478.6z M1763.6,1443c-19.6,0-35.6,16-35.6,35.6
                            c0,19.6,16,35.6,35.6,35.6h1697.5c19.6,0,35.6-16,35.6-35.6c0-19.6-16-35.6-35.6-35.6H1763.6z"/>
                        <g>
                            <path className="st15" d="M3443.5,1464.6c-7.3,8.5-6.4,21.2,2,28.6c8.5,7.3,21.2,6.4,28.6-2c7.3-8.5,6.4-21.2-2-28.6
                                C3463.7,1455.2,3450.9,1456.1,3443.5,1464.6z M3449.7,1469.9c4.4-5.1,12-5.6,17.1-1.2c5.1,4.4,5.6,12,1.2,17.1
                                c-4.4,5.1-12,5.6-17.1,1.2C3445.9,1482.6,3445.3,1474.9,3449.7,1469.9z"/>
                        </g>
                        <g>
                            <path className="st15" d="M3232.1,1464.6c-7.3,8.5-6.4,21.2,2,28.6c8.5,7.3,21.2,6.4,28.6-2c7.3-8.5,6.4-21.2-2-28.6
                                C3252.3,1455.2,3239.5,1456.1,3232.1,1464.6z M3238.3,1469.9c4.4-5.1,12-5.6,17.1-1.2c5.1,4.4,5.6,12,1.2,17.1
                                c-4.4,5.1-12,5.6-17.1,1.2C3234.5,1482.6,3233.9,1474.9,3238.3,1469.9z"/>
                        </g>
                        <g>
                            <path className="st15" d="M3020.7,1464.6c-7.3,8.5-6.4,21.2,2,28.6c8.5,7.3,21.2,6.4,28.6-2c7.3-8.5,6.4-21.2-2-28.6
                                C3040.9,1455.2,3028.1,1456.1,3020.7,1464.6z M3026.9,1469.9c4.4-5.1,12-5.6,17.1-1.2c5.1,4.4,5.6,12,1.2,17.1
                                c-4.4,5.1-12,5.6-17.1,1.2C3023.1,1482.6,3022.5,1474.9,3026.9,1469.9z"/>
                        </g>
                        <g>
                            <path className="st15" d="M2809.4,1464.6c-7.3,8.5-6.4,21.2,2,28.6c8.5,7.3,21.2,6.4,28.6-2c7.3-8.5,6.4-21.2-2-28.6
                                C2829.5,1455.2,2816.7,1456.1,2809.4,1464.6z M2815.5,1469.9c4.4-5.1,12-5.6,17.1-1.2c5.1,4.4,5.6,12,1.2,17.1
                                c-4.4,5.1-12,5.6-17.1,1.2C2811.7,1482.6,2811.1,1474.9,2815.5,1469.9z"/>
                        </g>
                        <g>
                            <path className="st15" d="M2598,1464.6c-7.3,8.5-6.4,21.2,2,28.6c8.5,7.3,21.2,6.4,28.6-2c7.3-8.5,6.4-21.2-2-28.6
                                C2618.1,1455.2,2605.3,1456.1,2598,1464.6z M2604.1,1469.9c4.4-5.1,12-5.6,17.1-1.2c5.1,4.4,5.6,12,1.2,17.1
                                c-4.4,5.1-12,5.6-17.1,1.2C2600.3,1482.6,2599.7,1474.9,2604.1,1469.9z"/>
                        </g>
                        <g>
                            <path className="st15" d="M2386.6,1464.6c-7.3,8.5-6.4,21.2,2,28.6c8.5,7.3,21.2,6.4,28.6-2c7.3-8.5,6.4-21.2-2-28.6
                                C2406.7,1455.2,2393.9,1456.1,2386.6,1464.6z M2392.7,1469.9c4.4-5.1,12-5.6,17.1-1.2c5.1,4.4,5.6,12,1.2,17.1
                                c-4.4,5.1-12,5.6-17.1,1.2C2388.9,1482.6,2388.3,1474.9,2392.7,1469.9z"/>
                        </g>
                        <g>
                            <path className="st15" d="M2175.2,1464.6c-7.3,8.5-6.4,21.2,2,28.6c8.5,7.3,21.2,6.4,28.6-2c7.3-8.5,6.4-21.2-2-28.6
                                C2195.3,1455.2,2182.5,1456.1,2175.2,1464.6z M2181.3,1469.9c4.4-5.1,12-5.6,17.1-1.2c5.1,4.4,5.6,12,1.2,17.1
                                c-4.4,5.1-12,5.6-17.1,1.2C2177.5,1482.6,2176.9,1474.9,2181.3,1469.9z"/>
                        </g>
                        <g>
                            <path className="st15" d="M1963.8,1464.6c-7.3,8.5-6.4,21.2,2,28.6c8.5,7.3,21.2,6.4,28.6-2c7.3-8.5,6.4-21.2-2-28.6
                                C1983.9,1455.2,1971.1,1456.1,1963.8,1464.6z M1969.9,1469.9c4.4-5.1,12-5.6,17.1-1.2c5.1,4.4,5.6,12,1.2,17.1
                                c-4.4,5.1-12,5.6-17.1,1.2C1966.1,1482.6,1965.5,1474.9,1969.9,1469.9z"/>
                        </g>
                        <g>
                            <path className="st15" d="M1752.4,1464.6c-7.3,8.5-6.4,21.2,2,28.6c8.5,7.3,21.2,6.4,28.6-2c7.3-8.5,6.4-21.2-2-28.6
                                C1772.5,1455.2,1759.7,1456.1,1752.4,1464.6z M1758.5,1469.9c4.4-5.1,12-5.6,17.1-1.2c5.1,4.4,5.6,12,1.2,17.1
                                c-4.4,5.1-12,5.6-17.1,1.2C1754.7,1482.6,1754.1,1474.9,1758.5,1469.9z"/>
                        </g>
                    </g>
                    <rect x="3207.1" y="1188.9" className="st8" width="183.6" height="505.6"/>
                    <g>
                        <path className="st13" d="M3255.1,1694.5h365.2v-560.8c0-8.9-7.2-16-16-16h-333.1c-8.9,0-16,7.2-16,16V1694.5z"/>
                        <g>
                            
                                <rect x="3559.1" y="1554.5" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 7130.7881 3208.4597)" className="st15" width="12.5" height="99.4"/>
                            
                                <rect x="3534.8" y="1554.5" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 7082.0698 3208.4597)" className="st15" width="12.5" height="99.4"/>
                            
                                <rect x="3510.4" y="1554.5" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 7033.3516 3208.4597)" className="st15" width="12.5" height="99.4"/>
                            
                                <rect x="3486" y="1554.5" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6984.6333 3208.4597)" className="st15" width="12.5" height="99.4"/>
                            
                                <rect x="3461.7" y="1554.5" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6935.915 3208.4597)" className="st15" width="12.5" height="99.4"/>
                            
                                <rect x="3437.3" y="1554.5" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6887.1968 3208.4597)" className="st15" width="12.5" height="99.4"/>
                            
                                <rect x="3413" y="1554.5" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6838.4785 3208.4597)" className="st15" width="12.5" height="99.4"/>
                        </g>
                        <rect x="3273" y="1109.7" className="st16" width="333" height="7.7"/>
                        <path className="st17" d="M3284.9,1482.8h306.3c3.3,0,6-2.7,6-6V1150c0-3.3-2.7-6-6-6h-306.3c-3.3,0-6,2.7-6,6v326.9
                            C3278.9,1480.2,3281.5,1482.8,3284.9,1482.8z"/>
                        <g>
                            <path className="st16" d="M3329.3,1276.3h220.4c3.9,0,7.1-3.2,7.1-7.1v-74c0-3.9-3.2-7.1-7.1-7.1h-220.4c-3.9,0-7.1,3.2-7.1,7.1
                                v74C3322.2,1273.1,3325.4,1276.3,3329.3,1276.3z"/>
                            <g>
                                <g>
                                    <rect x="3464.8" y="1250.8" className="st17" width="19" height="12.4"/>
                                    <rect x="3464.8" y="1236.1" className="st17" width="19" height="12.4"/>
                                    <rect x="3464.8" y="1221.4" className="st17" width="19" height="12.4"/>
                                    <rect x="3464.8" y="1206.7" className="st17" width="19" height="12.4"/>
                                </g>
                                <g>
                                    <rect x="3442.8" y="1250.8" className="st17" width="19" height="12.4"/>
                                    <rect x="3442.8" y="1236.1" className="st17" width="19" height="12.4"/>
                                    <rect x="3442.8" y="1221.4" className="st17" width="19" height="12.4"/>
                                </g>
                                <g>
                                    <rect x="3376.3" y="1250.8" className="st17" width="19" height="12.4"/>
                                    <rect x="3376.3" y="1236.1" className="st17" width="19" height="12.4"/>
                                    <rect x="3376.3" y="1221.4" className="st17" width="19" height="12.4"/>
                                </g>
                                <g>
                                    <rect x="3420.5" y="1250.8" className="st17" width="19" height="12.4"/>
                                    <rect x="3420.5" y="1236.1" className="st17" width="19" height="12.4"/>
                                    <rect x="3420.5" y="1221.4" className="st17" width="19" height="12.4"/>
                                    <rect x="3420.5" y="1206.7" className="st17" width="19" height="12.4"/>
                                </g>
                                <g>
                                    <rect x="3398.6" y="1250.8" className="st17" width="19" height="12.4"/>
                                    <rect x="3509" y="1250.8" className="st17" width="19" height="12.4"/>
                                    <rect x="3398.6" y="1236.1" className="st17" width="19" height="12.4"/>
                                </g>
                                <g>
                                    <rect x="3486.7" y="1250.8" className="st17" width="19" height="12.4"/>
                                    <rect x="3486.7" y="1236.1" className="st17" width="19" height="12.4"/>
                                </g>
                                <g>
                                    <rect x="3354.7" y="1250.8" className="st17" width="19" height="12.4"/>
                                    <rect x="3354.7" y="1236.1" className="st17" width="19" height="12.4"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    
                                        <ellipse transform="matrix(0.9732 -0.2298 0.2298 0.9732 -220.6456 810.2899)" className="st18" cx="3369.3" cy="1352.7" rx="39.4" ry="39.4"/>
                                    <g>
                                        <g>
                                            <path className="st16" d="M3396.3,1329.6l-13.4,12.6c2.3,2.9,3.7,6.6,3.8,10.5h18.4C3405,1343.9,3401.7,1335.8,3396.3,1329.6z
                                                "/>
                                        </g>
                                        <g>
                                            <path className="st16" d="M3393.3,1326.6c-6.2-5.6-14.3-9-23.2-9.2l0.3,18.4c3.6,0.2,6.8,1.5,9.5,3.5L3393.3,1326.6z"/>
                                        </g>
                                        <g>
                                            <path className="st16" d="M3344.8,1327l13.2,12.8c2.2-1.9,5-3.3,8-3.9l-0.3-18.5C3357.7,1318.3,3350.4,1321.8,3344.8,1327z"/>
                                        </g>
                                        <g>
                                            <path className="st16" d="M3333.5,1352.8h18.4c0.1-3.6,1.2-7,3.2-9.8l-13.2-12.9C3336.7,1336.3,3333.6,1344.2,3333.5,1352.8z"
                                                />
                                        </g>
                                    </g>
                                </g>
                                <path className="st15" d="M3371.1,1353.7L3371.1,1353.7c0.4-1.1-0.2-2.3-1.3-2.7l-21-7.1c-1.1-0.4-2.3,0.2-2.7,1.3l0,0
                                    c-0.4,1.1,0.2,2.3,1.3,2.7l21,7.1C3369.5,1355.4,3370.7,1354.8,3371.1,1353.7z"/>
                            </g>
                            <g>
                                <g>
                                    
                                        <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 70.0911 2874.5332)" className="st18" cx="3504.9" cy="1352.7" rx="39.4" ry="39.4"/>
                                    <g>
                                        <g>
                                            <path className="st16" d="M3531.9,1329.6l-13.4,12.6c2.3,2.9,3.7,6.6,3.8,10.5h18.4
                                                C3540.6,1343.9,3537.3,1335.8,3531.9,1329.6z"/>
                                        </g>
                                        <g>
                                            <path className="st16" d="M3528.9,1326.6c-6.2-5.6-14.3-9-23.2-9.2l0.3,18.4c3.6,0.2,6.8,1.5,9.5,3.5L3528.9,1326.6z"/>
                                        </g>
                                        <g>
                                            <path className="st16" d="M3480.4,1327l13.2,12.8c2.2-1.9,5-3.3,8-3.9l-0.3-18.5C3493.3,1318.3,3486,1321.8,3480.4,1327z"/>
                                        </g>
                                        <g>
                                            <path className="st16" d="M3469.1,1352.8h18.4c0.1-3.6,1.2-7,3.2-9.8l-13.2-12.9C3472.3,1336.3,3469.2,1344.2,3469.1,1352.8z"
                                                />
                                        </g>
                                    </g>
                                </g>
                                <path className="st15" d="M3504.2,1354.1L3504.2,1354.1c1.1,0.4,2.3-0.1,2.7-1.2l7.8-20.8c0.4-1.1-0.1-2.3-1.2-2.7l0,0
                                    c-1.1-0.4-2.3,0.1-2.7,1.2l-7.8,20.8C3502.5,1352.4,3503.1,1353.7,3504.2,1354.1z"/>
                                <path className="st13" d="M3432.2,1327.3c0,2.7,2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9
                                    S3432.2,1324.6,3432.2,1327.3z"/>
                                <path className="st13" d="M3432.2,1344c0,2.7,2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9s-2.2-4.9-4.9-4.9S3432.2,1341.3,3432.2,1344z"/>
                                <path className="st13" d="M3432.2,1360.6c0,2.7,2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9
                                    S3432.2,1357.9,3432.2,1360.6z"/>
                                <path className="st13" d="M3432.2,1377.3c0,2.7,2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9c0-2.7-2.2-4.9-4.9-4.9
                                    S3432.2,1374.6,3432.2,1377.3z"/>
                            </g>
                        </g>
                        <path className="st13" d="M3528.5,1441.6L3528.5,1441.6c6.7,0,12.1-5.4,12.1-12.1v0c0-6.7-5.4-12.1-12.1-12.1l0,0
                            c-6.7,0-12.1,5.4-12.1,12.1v0C3516.4,1436.1,3521.9,1441.6,3528.5,1441.6z"/>
                        <path className="st13" d="M3492,1441.6L3492,1441.6c6.7,0,12.1-5.4,12.1-12.1v0c0-6.7-5.4-12.1-12.1-12.1l0,0
                            c-6.7,0-12.1,5.4-12.1,12.1v0C3479.9,1436.1,3485.3,1441.6,3492,1441.6z"/>
                        <path className="st16" d="M3455.4,1441.6L3455.4,1441.6c6.7,0,12.1-5.4,12.1-12.1v0c0-6.7-5.4-12.1-12.1-12.1l0,0
                            c-6.7,0-12.1,5.4-12.1,12.1v0C3443.3,1436.1,3448.7,1441.6,3455.4,1441.6z"/>
                        <path className="st13" d="M3418.9,1441.6L3418.9,1441.6c6.7,0,12.1-5.4,12.1-12.1v0c0-6.7-5.4-12.1-12.1-12.1l0,0
                            c-6.7,0-12.1,5.4-12.1,12.1v0C3406.7,1436.1,3412.2,1441.6,3418.9,1441.6z"/>
                        <path className="st13" d="M3382.3,1441.6L3382.3,1441.6c6.7,0,12.1-5.4,12.1-12.1v0c0-6.7-5.4-12.1-12.1-12.1l0,0
                            c-6.7,0-12.1,5.4-12.1,12.1v0C3370.2,1436.1,3375.6,1441.6,3382.3,1441.6z"/>
                        <path className="st16" d="M3345.7,1441.6L3345.7,1441.6c6.7,0,12.1-5.4,12.1-12.1v0c0-6.7-5.4-12.1-12.1-12.1l0,0
                            c-6.7,0-12.1,5.4-12.1,12.1v0C3333.6,1436.1,3339,1441.6,3345.7,1441.6z"/>
                    </g>
                    <rect x="3173.4" y="1673.3" className="st15" width="663.6" height="21.3"/>
                </g>
                <g id="Arm_02_2_">
                    <g>
                        <path className="st19" d="M1649.7,1262.2l-4.1-3.4c36.5-44.8,51.3-82.2,45.4-114.3c-8.1-43.7-54-70.1-94.5-93.5
                            c-22.7-13.1-44.2-25.5-56.4-39.5c-9.1-10.5-12.9-22-11.2-33.9c3.8-27.2,34.1-47.4,35.3-48.2l2.9,4.5
                            c-0.3,0.2-29.6,19.8-33,44.5c-1.4,10.4,1.9,20.4,10,29.7c11.5,13.4,32.6,25.5,55,38.4c41.5,23.9,88.6,51.1,97.1,97.1
                            C1702.4,1177.3,1687.2,1216.1,1649.7,1262.2z"/>
                        <path className="st19" d="M1774.5,1408.6l-18-11.5c29.6-46.3,29.6-88.3,0-121.4c-49.2-55.1-149.1-11.6-150.1-11.1l-8.7-19.5
                            c1.2-0.5,28.7-12.7,63.6-17.1c47.8-6.1,86.2,5.5,111.2,33.5C1808.5,1301.9,1809.3,1354.2,1774.5,1408.6z"/>
                        <path className="st19" d="M1331,964c-0.7-1.4-16.4-34.4-20-75.4c-4.9-56.1,15.4-99.9,58.6-126.6c31.6-19.5,66.3-25.2,100.4-16.3
                            c39.4,10.2,70.8,38.2,79.6,53.9l-18.6,10.5c-5.9-10.5-32.2-34.9-66.3-43.7c-28.8-7.4-57-2.8-83.9,13.8
                            c-36.4,22.5-52.7,58.4-48.6,106.6c3.2,37.2,17.8,67.7,17.9,68L1331,964z"/>
                        
                            <rect x="1510.6" y="796.6" transform="matrix(0.866 -0.5 0.5 0.866 -196.0081 878.0134)" className="st19" width="59.5" height="16.3"/>
                        <g>
                            <g>
                                <rect x="1919.4" y="745.5" className="barArm" width="35.2" height="163"/>
                                <path className="boxArm" d="M1958.3,858.8l-41.2,0c-7.3,6-12,15.1-12,25.3l0.1,29.8l65.2-0.1l-0.1-29.8
                                    C1970.3,873.8,1965.6,864.8,1958.3,858.8z"/>
                                <g>
                                    <rect x="1899.5" y="913.8" className="boxLineArm" width="77" height="9.3"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <polygon className="tweezersArm" points="1883.5,1083.8 1870.3,1085.7 1832.8,988.9 1868.8,983.6 								"/>
                                    <polygon className="tweezersArm" points="2020.7,1083.1 2034,1082.6 2053.2,980.5 2016.8,981.9 								"/>
                                    <path className="handArm" d="M1979.2,958.2l-77.5-0.1c-9.4,0-17.2-7.7-17.2-17.2l0,0c0-9.4,7.7-17.2,17.2-17.2l77.5,0.1
                                        c9.4,0,17.2,7.7,17.2,17.2l0,0C1996.4,950.5,1988.6,958.2,1979.2,958.2z"/>
                                    <g>
                                        <path className="handArm" d="M1910.5,929.4c-5.4-5.9-14.5-6.4-20.5-1.2l-50.7,43.7c-11.7,9.9-11.5,28.1,0.4,37.7l0,0
                                            c11.3,9.2,28.2,6.5,36-5.8l36.3-56.6C1915.6,941.6,1915,934.3,1910.5,929.4L1910.5,929.4z"/>
                                        <g>
                                            <g>
                                                <path className="screwHand" d="M1848.7,984.1c-4.1,5.1-1.9,12.6,4.2,14.8c3.7,1.3,8,0.1,10.4-3c4.1-5.1,1.9-12.6-4.2-14.8
                                                    C1855.4,979.8,1851.1,981,1848.7,984.1z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path className="screwHand" d="M1893.1,936c-3.1,3.9-1.5,9.7,3.2,11.4c2.9,1,6.1,0.1,8-2.3c3.1-3.9,1.5-9.7-3.2-11.4
                                                    C1898.3,932.7,1895,933.6,1893.1,936z"/>
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <path className="handArm" d="M1968,930.1c4.5-6.6,13.5-8.3,20.2-3.9l56,36.7c12.9,8.3,15.1,26.3,4.6,37.4l0,0
                                            c-10,10.6-27.1,10.1-36.5-1l-43.4-51.3C1964.6,942.9,1964.2,935.6,1968,930.1L1968,930.1z"/>
                                        <g>
                                            <g>
                                                <path className="screwHand" d="M1973.9,938.2c-0.9,4.9,3.2,9.3,8.1,8.6c3-0.4,5.5-2.8,6.1-5.7c0.9-4.9-3.2-9.3-8.1-8.6
                                                    C1977,932.9,1974.5,935.3,1973.9,938.2z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path className="screwHand" d="M2020.7,981.2c-1.2,6.4,4.1,12.1,10.6,11.2c3.9-0.5,7.1-3.6,7.9-7.5c1.2-6.4-4.1-12.1-10.6-11.2
                                                    C2024.7,974.2,2021.4,977.3,2020.7,981.2z"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g>
                            <rect x="1814.1" y="736.9" className="st19" width="129.6" height="41.7"/>
                            <path className="st13" d="M1829.9,803.5h-84c-25.2,0-45.7-20.4-45.7-45.7v0c0-25.2,20.4-45.7,45.7-45.7h84V803.5z"/>
                            <path className="st13" d="M1938.6,803.5h-59.2v-91.3h59.2c25.2,0,45.7,20.4,45.7,45.7v0C1984.3,783,1963.9,803.5,1938.6,803.5z"/>
                            <path className="st19" d="M1949.4,779.6c-12.2,5.1-26.4-0.7-31.5-12.9c-5.1-12.2,0.7-26.4,12.9-31.5c12.2-5.1,26.4,0.7,31.5,12.9
                                S1961.7,774.5,1949.4,779.6z M1934.7,744.6c-7.1,3-10.4,11.2-7.5,18.2c3,7.1,11.2,10.4,18.2,7.5c7.1-3,10.4-11.2,7.5-18.2
                                C1950,744.9,1941.8,741.6,1934.7,744.6z"/>
                        </g>
                        <g>
                            
                                <rect x="1463.5" y="887" transform="matrix(0.5427 0.84 -0.84 0.5427 1456.5371 -873.3077)" className="st19" width="133.4" height="27.8"/>
                            <path className="st19" d="M1348.3,1018.2L1348.3,1018.2c-10.9-16.9-6-39.4,10.9-50.2l187.1-120.4l39.3,61.2l-187.1,120.4
                                C1381.6,1040,1359.1,1035.1,1348.3,1018.2z"/>
                            <g>
                                <path className="st13" d="M1569.4,966.6l202.4-149.9c26-19.3,32.5-55.4,14.9-82.6l0,0c-17.6-27.1-53.3-35.9-81.5-20l-218.8,123.9
                                    L1569.4,966.6z"/>
                                <path className="st19" d="M1756.6,800.6c-18.2,11.8-42.6,6.6-54.4-11.6s-6.6-42.6,11.6-54.4c18.2-11.8,42.6-6.6,54.4,11.6
                                    C1779.9,764.4,1774.7,788.8,1756.6,800.6z M1722.8,748.6c-10.5,6.8-13.5,21-6.7,31.5c6.8,10.5,21,13.5,31.5,6.7
                                    c10.5-6.8,13.5-21,6.7-31.5C1747.4,744.8,1733.3,741.8,1722.8,748.6z"/>
                            </g>
                        </g>
                        <g>
                            <path className="st13" d="M1648.2,1332L1648.2,1332c-49.2,42.7-123.8,37.4-166.5-11.8l-193.8-223.5
                                c-42.7-49.2-37.4-123.8,11.8-166.5l0,0c49.2-42.7,123.8-37.4,166.5,11.8l193.8,223.5C1702.7,1214.8,1697.4,1289.3,1648.2,1332
                                z"/>
                            <path className="st19" d="M1331.8,967.2c-25.9,22.5-28.7,61.7-6.2,87.6c22.5,25.9,61.7,28.7,87.6,6.2
                                c25.9-22.5,28.7-61.7,6.2-87.6C1397,947.5,1357.7,944.7,1331.8,967.2z M1396.8,1042.2c-15.5,13.4-39,11.8-52.4-3.7
                                c-13.4-15.5-11.8-39,3.7-52.4c15.5-13.4,39-11.8,52.4,3.7C1414,1005.3,1412.3,1028.7,1396.8,1042.2z"/>
                        </g>
                        <g>
                            <g>
                                <path className="st19" d="M1690.7,1353.6h-214.4v-92c0-59.2,48-107.2,107.2-107.2h0c59.2,0,107.2,48,107.2,107.2V1353.6z"/>
                                <g>
                                    <path className="st13" d="M1552.7,1223.2c-20.3,17.6-22.5,48.4-4.9,68.8s48.4,22.5,68.8,4.9c20.4-17.6,22.5-48.4,4.9-68.8
                                        C1603.9,1207.8,1573.1,1205.6,1552.7,1223.2z M1603.8,1282.1c-12.2,10.6-30.6,9.2-41.1-2.9c-10.6-12.2-9.2-30.6,2.9-41.1
                                        c12.2-10.6,30.6-9.2,41.1,2.9C1617.3,1253.1,1616,1271.5,1603.8,1282.1z"/>
                                </g>
                            </g>
                        </g>
                        <g>
                            <rect x="1451.9" y="1342.8" className="st19" width="274.2" height="80.6"/>
                            <g>
                                <path className="st13" d="M1820.8,1455.2h-487.2c-0.3,2.2-0.4,4.5-0.4,6.8v233.9h488V1462
                                    C1821.2,1459.7,1821,1457.4,1820.8,1455.2z"/>
                                <path className="st13" d="M1714.3,1381.6h-274.7c-58.8,16-101.8,43.5-106.3,82.5h487.2C1816.1,1425,1773.1,1397.5,1714.3,1381.6z
                                    "/>
                            </g>
                            <rect x="1321.9" y="1624.7" className="st19" width="513.7" height="71.2"/>
                            <g>
                                <path className="st19" d="M1530.5,1522.8L1530.5,1522.8c-7.2,0-13.1-5.9-13.1-13.1l0,0c0-7.2,5.9-13.1,13.1-13.1h0
                                    c7.2,0,13.1,5.9,13.1,13.1l0,0C1543.6,1516.9,1537.8,1522.8,1530.5,1522.8z"/>
                                <path className="st19" d="M1575,1522.8L1575,1522.8c-7.2,0-13.1-5.9-13.1-13.1l0,0c0-7.2,5.9-13.1,13.1-13.1l0,0
                                    c7.2,0,13.1,5.9,13.1,13.1l0,0C1588.1,1516.9,1582.3,1522.8,1575,1522.8z"/>
                                <path className="st19" d="M1619.5,1522.8L1619.5,1522.8c-7.2,0-13.1-5.9-13.1-13.1l0,0c0-7.2,5.9-13.1,13.1-13.1l0,0
                                    c7.2,0,13.1,5.9,13.1,13.1l0,0C1632.6,1516.9,1626.8,1522.8,1619.5,1522.8z"/>
                                <path className="st19" d="M1664,1522.8L1664,1522.8c-7.2,0-13.1-5.9-13.1-13.1l0,0c0-7.2,5.9-13.1,13.1-13.1l0,0
                                    c7.2,0,13.1,5.9,13.1,13.1l0,0C1677.1,1516.9,1671.2,1522.8,1664,1522.8z"/>
                            </g>
                            <path className="st19" d="M1466.5,1613.4h-78.7v-72.3h78.7V1613.4z M1389,1612.1h76.3v-69.8H1389V1612.1z"/>
                            <g>
                                <g>
                                    <path className="st19" d="M1411.8,1423.8c8-11.5,20-19.6,31.9-26.8h-5.8c-11.9,7.1-23.9,15.3-31.9,26.8H1411.8z"/>
                                    <path className="st19" d="M1425.5,1423.8c8-11.5,20-19.6,31.9-26.8h-5.8c-11.9,7.1-23.9,15.3-31.9,26.8H1425.5z"/>
                                    <path className="st19" d="M1439.3,1423.8c8-11.5,20-19.6,31.8-26.8h-5.8c-11.9,7.1-23.9,15.3-31.8,26.8H1439.3z"/>
                                    <path className="st19" d="M1453,1423.8c7.9-11.5,19.9-19.6,31.8-26.8h-5.8c-11.9,7.1-23.9,15.3-31.8,26.8H1453z"/>
                                    <path className="st19" d="M1466.8,1423.8c7.9-11.5,19.9-19.6,31.8-26.8h-5.8c-11.8,7.1-23.8,15.3-31.8,26.8H1466.8z"/>
                                    <path className="st19" d="M1480.6,1423.8c7.9-11.5,19.9-19.6,31.7-26.8h-5.8c-11.8,7.1-23.8,15.3-31.7,26.8H1480.6z"/>
                                    <path className="st19" d="M1494.3,1423.8c7.9-11.5,19.9-19.6,31.7-26.8h-5.8c-11.8,7.1-23.8,15.3-31.7,26.8H1494.3z"/>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g>
                        
                            <ellipse transform="matrix(0.6671 -0.7449 0.7449 0.6671 -558.5978 1472.1621)" className="st13" cx="1368" cy="1361.1" rx="12.3" ry="12.1"/>
                        <g>
                            <path className="signaBar3" d="M1306.6,1368.1L1306.6,1368.1c-2.1,0-3.9-1.7-3.9-3.8c-0.1-38.1,28.5-68.6,66.5-70.9
                                c2.1-0.1,3.9,1.5,4.1,3.6l0,0c0.1,2.2-1.5,4.1-3.6,4.2c-33.6,2.4-58.8,29.3-59.1,63
                                C1310.5,1366.4,1308.7,1368.1,1306.6,1368.1z"/>
                            <path className="signaBar2" d="M1322.9,1368.2L1322.9,1368.2c-2.2,0-3.9-1.8-3.9-4c0.5-29,22.2-52.1,51.1-54.5c2.2-0.2,4.1,1.4,4.2,3.6
                                l0,0c0.1,2.1-1.4,4-3.6,4.2c-24.7,2.3-43.2,22-43.9,46.8C1326.8,1366.5,1325.1,1368.2,1322.9,1368.2z"/>
                            <path className="signaBar1" d="M1338.8,1368.2L1338.8,1368.2c-2.3,0-4-1.8-3.9-4c1-20.2,16.1-36.3,36.2-38.6c2.2-0.3,4.1,1.4,4.3,3.6
                                l0,0.1c0.1,2.1-1.4,3.9-3.5,4.2c-16,2.1-28,14.9-29.1,31.1C1342.6,1366.6,1340.9,1368.2,1338.8,1368.2z"/>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect x="4216.4" y="1463" className="st20" width="40.9" height="229.6"/>
                            <g>
                                <rect x="4250.3" y="1463" className="st13" width="124.1" height="229.6"/>
                            </g>
                        </g>
                        <g>
                            <rect x="3915.4" y="1454.5" className="st17" width="40.9" height="238.2"/>
                            <g>
                                <rect x="3949.3" y="1463" className="st13" width="124.1" height="229.6"/>
                                <g>
                                    <g>
                                        <path className="st17" d="M3960.2,1641.3v37h18.6l2.8-4.3h56l2.8,4.3h22.8v-37H3960.2z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st17" d="M3960.2,1588.2v37h18.6l2.8-4.3h56l2.8,4.3h22.8v-37H3960.2z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st17" d="M3960.2,1535v37h18.6l2.8-4.3h56l2.8,4.3h22.8v-37H3960.2z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st17" d="M3960.2,1481.9v37h18.6l2.8-4.3h56l2.8,4.3h22.8v-37H3960.2z"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <rect x="3949.3" y="1454.5" className="st13" width="425.2" height="9.3"/>
                        <g>
                            <g>
                                <path className="st17" d="M4260.1,1641.3v37h18.6l2.8-4.3h56l2.8,4.3h22.8v-37H4260.1z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path className="st17" d="M4260.1,1588.2v37h18.6l2.8-4.3h56l2.8,4.3h22.8v-37H4260.1z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path className="st17" d="M4260.1,1535v37h18.6l2.8-4.3h56l2.8,4.3h22.8v-37H4260.1z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path className="st17" d="M4260.1,1481.9v37h18.6l2.8-4.3h56l2.8,4.3h22.8v-37H4260.1z"/>
                            </g>
                        </g>
                    </g>
                </g>
                <path className="st21" d="M3781.6,1179.5h-39.6v-135.6c0-31.3,25.5-56.8,56.8-56.8h171c31.3,0,56.7,25.5,56.7,56.8v63.6h-39.6v-63.6
                    c0-9.4-7.7-17.1-17.1-17.1h-171c-9.4,0-17.1,7.7-17.1,17.1V1179.5z"/>
                <rect x="3651" y="1097.4" className="st21" width="82.3" height="10.5"/>
                <rect x="3720.2" y="1169.3" className="st21" width="82.3" height="10.5"/>
                <path className="st22" d="M3452.1,1382c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7s-2.1-4.7-4.7-4.7S3452.1,1379.4,3452.1,1382z"/>
                <path className="st22" d="M3437.6,1382c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7s-2.1-4.7-4.7-4.7S3437.6,1379.4,3437.6,1382z"/>
                <path className="st22" d="M3423.1,1382c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7s-2.1-4.7-4.7-4.7S3423.1,1379.4,3423.1,1382z"/>
                <path className="st22" d="M3408.6,1382c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7s-2.1-4.7-4.7-4.7
                    C3410.7,1377.3,3408.6,1379.4,3408.6,1382z"/>
                <path className="st22" d="M3394.1,1382c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7s-2.1-4.7-4.7-4.7
                    C3396.2,1377.3,3394.1,1379.4,3394.1,1382z"/>
                <path className="st22" d="M3379.6,1382c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7s-2.1-4.7-4.7-4.7S3379.6,1379.4,3379.6,1382z"/>
                <path className="st22" d="M3365.1,1382c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7s-2.1-4.7-4.7-4.7S3365.1,1379.4,3365.1,1382z"/>
                <g>
                    <path className="st4" d="M4342.4,1352.8h-204.9c-10.4,0-18.9-8.5-18.9-18.9v-154.7c0-10.4,8.5-18.9,18.9-18.9h204.9
                        c10.4,0,18.9,8.5,18.9,18.9v154.7C4361.3,1344.4,4352.8,1352.8,4342.4,1352.8z"/>
                    <path className="st3" d="M4334,1215.3h-185.8c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4H4334c0.2,0,0.4,0.2,0.4,0.4
                        C4334.4,1215.1,4334.2,1215.3,4334,1215.3z"/>
                    <path className="st3" d="M4334,1279.9h-185.8c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4H4334c0.2,0,0.4,0.2,0.4,0.4
                        C4334.4,1279.7,4334.2,1279.9,4334,1279.9z"/>
                    <path className="st3" d="M4334,1248.4h-185.8c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4H4334c0.2,0,0.4,0.2,0.4,0.4
                        S4334.2,1248.4,4334,1248.4z"/>
                    <path className="st3" d="M4334,1312.6h-186.7l-0.4-115.1c0-0.5,0.4-0.9,0.9-0.9c0,0,0,0,0,0c0.5,0,0.9,0.4,0.9,0.9l0.4,113.3H4334
                        c0.5,0,0.9,0.4,0.9,0.9C4334.8,1312.2,4334.4,1312.6,4334,1312.6z"/>
                    <path className="st3" d="M4303.1,1226.7l-68.3,66.6l-35.2-31.6l-52.1,49.3c-0.4,0.3-0.4,0.9,0,1.3c0.2,0.2,0.4,0.3,0.6,0.3
                        c0.2,0,0.4-0.1,0.6-0.2l50.9-48.2l35.2,31.6l68.3-66.5V1226.7z"/>
                    <path className="st3" d="M4204.3,1263.1c0,2.7-2.1,4.8-4.7,4.8c-2.6,0-4.7-2.2-4.7-4.8c0-2.7,2.1-4.8,4.7-4.8
                        C4202.2,1258.2,4204.3,1260.4,4204.3,1263.1z"/>
                    <path className="st3" d="M4240.1,1294.1c0,2.7-2.1,4.8-4.7,4.8c-2.6,0-4.7-2.2-4.7-4.8c0-2.7,2.1-4.8,4.7-4.8
                        C4238,1289.3,4240.1,1291.4,4240.1,1294.1z"/>
                    <path className="st3" d="M4308.4,1227.2c0,2.7-2.1,4.8-4.7,4.8c-2.6,0-4.7-2.2-4.7-4.8c0-2.7,2.1-4.8,4.7-4.8
                        C4306.3,1222.4,4308.4,1224.6,4308.4,1227.2z"/>
                </g>
                <g>
                    <path className="st4" d="M3899.3,1355.1H4076c7.2,0,13-5.8,13-13v-168.6c0-7.2-5.8-13-13-13h-176.7c-7.2,0-13,5.8-13,13v168.6
                        C3886.2,1349.3,3892.1,1355.1,3899.3,1355.1z"/>
                    <rect x="4033.5" y="1266.4" className="graph2Bar1" width="19.2"/>
                    <rect x="4006.8" y="1258" className="graph2Bar2" width="19.2"/>
                    <rect x="3980.1" y="1240.8" className="graph2Bar3" width="19.2"/>
                    <rect x="3953.3" y="1228.8" className="graph2Bar4" width="19.2"/>
                    <rect x="3926.6" y="1202.9" className="graph2Bar5" width="19.2"/>
                </g>
                <g>
                    
                        <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -143.0223 2128.0071)" className="st2" cx="2497.2" cy="1236.6" rx="32.4" ry="32.4"/>
                    <path className="st3" d="M2489.7,1253.7c-1.2,0-2.4-0.5-3.3-1.4l-10.2-10.2c-1.8-1.8-1.8-4.8,0-6.7c1.8-1.8,4.8-1.8,6.7,0l6.9,6.9
                        l20.5-20.5c1.8-1.8,4.8-1.8,6.7,0c1.8,1.8,1.8,4.8,0,6.7l-23.8,23.8C2492.1,1253.2,2490.9,1253.7,2489.7,1253.7z"/>
                </g>
                <g>
                    <circle className="st2" cx="2497.2" cy="1240.7" r="32.4"/>
                    <path className="st3" d="M2489.7,1257.7c-1.2,0-2.4-0.5-3.3-1.4l-10.2-10.2c-1.8-1.8-1.8-4.8,0-6.7c1.8-1.8,4.8-1.8,6.7,0l6.9,6.9
                        l20.5-20.5c1.8-1.8,4.8-1.8,6.7,0c1.8,1.8,1.8,4.8,0,6.7l-23.8,23.8C2492.1,1257.2,2490.9,1257.7,2489.7,1257.7z"/>
                </g>
                <g>
                    <circle className="st2" cx="2769.4" cy="1240.7" r="32.4"/>
                    <path className="st3" d="M2761.9,1257.7c-1.2,0-2.4-0.5-3.3-1.4l-10.2-10.2c-1.8-1.8-1.8-4.8,0-6.7c1.8-1.8,4.8-1.8,6.7,0l6.9,6.9
                        l20.5-20.5c1.8-1.8,4.8-1.8,6.7,0c1.8,1.8,1.8,4.8,0,6.7l-23.8,23.8C2764.3,1257.2,2763.1,1257.7,2761.9,1257.7z"/>
                </g>
            </g>
            <g>
                <g>
                    
                        <ellipse transform="matrix(4.643577e-02 -0.9989 0.9989 4.643577e-02 627.8325 2082.4556)" className="st1" cx="1404.7" cy="712.4" rx="13.9" ry="14.1"/>
                    <g>
                        <path className="signaBar3" d="M1459.8,667.7L1459.8,667.7c1.7-1.7,1.7-4.5,0-6.3c-31.1-30.7-79.1-31.8-111.6-2.6
                            c-1.8,1.6-1.9,4.4-0.3,6.2l0,0c1.7,1.9,4.5,2.1,6.4,0.4c29-25.6,71.3-24.6,99.1,2.3C1455.2,669.6,1458.1,669.5,1459.8,667.7z"
                            />
                        <path className="signaBar2" d="M1446.7,681.1L1446.7,681.1c1.8-1.8,1.7-4.7-0.1-6.4c-24.2-22.9-60.6-23.7-85.7-2
                            c-1.9,1.6-2.1,4.5-0.4,6.4l0,0c1.6,1.8,4.4,2,6.3,0.5c21.7-18.3,52.7-17.6,73.6,1.7C1442.2,683,1445,682.9,1446.7,681.1z"/>
                        <path className="signaBar1" d="M1434,694.2L1434,694.2c1.8-1.9,1.7-4.8-0.2-6.5c-17.4-15.5-42.7-16.1-60.8-1.4c-2,1.6-2.2,4.5-0.5,6.4
                            l0,0c1.6,1.8,4.3,2,6.2,0.5c14.7-11.4,34.8-10.9,48.9,1.2C1429.6,696,1432.3,695.9,1434,694.2z"/>
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <g>
                        
                            <rect x="3358.17" y="904.3" transform="matrix(1.940084e-02 -0.9998 0.9998 1.940084e-02 2387.7234 4269.5347)" className="armBar2" width="26.5" height="82.3"/>
                        <g>
                            <g>
                                
                                    <rect x="3357.4" y="999.6" transform="matrix(-1 -8.558228e-03 8.558228e-03 -1 6732.1616 2038.924)" className="armBar2" width="26.2" height="11"/>
                                
                                    <rect x="3362.5" y="1005.9" transform="matrix(-1 -8.558271e-03 8.558271e-03 -1 6731.9302 2056.9624)" className="armBar2" width="15.7" height="16.4"/>
                                
                                    <rect x="3357.4" y="999.6" transform="matrix(-1 -8.558228e-03 8.558228e-03 -1 6732.1616 2038.924)" className="armBar2" width="26.2" height="11"/>
                                <g>
                                    <path className="armBar2" d="M3356.6,1025.8L3356.6,1025.8L3356.6,1025.8L3356.6,1025.8L3356.6,1025.8l2-1.9c3-2.8,6.9-4.4,11-4.4
                                        l0,0c4.1,0,8,1.7,11,4.6l1.9,1.9l0,0l0,0l0,0l0,0l-0.1,13l0,0l-2,1.9c-3,2.8-6.9,4.3-11,4.3l-0.1,0c-4.1,0-8-1.6-10.9-4.5
                                        l-2-1.9h0L3356.6,1025.8z"/>
                                    
                                        <rect x="3361.9" y="1037" transform="matrix(-1 -8.553378e-03 8.553378e-03 -1 6729.7583 2113.2251)" className="armBar2" width="15" height="10.5"/>
                                </g>
                                <polygon className="armBar2" points="3372.6,1029.6 3372.2,1074.5 3369.2,1077.4 3366.1,1074.4 3366.5,1029.6 							"/>
                            </g>
                            <g>
                                <polygon className="armBar2LightBlue" points="3354.6,1004.9 3386.2,1005.1 3402.6,987.6 3338.6,987.1 							"/>
                            </g>
                            <g>
                                <polygon className="armBar2LightBlue" points="3388,937.3 3354.1,937 3336.6,948.9 3405.3,949.5 							"/>
                            </g>
                            
                                <rect x="3334.3" y="946.7" transform="matrix(-1 -8.556105e-03 8.556105e-03 -1 6733.126 1963.9402)" className="armBar2" width="72.8" height="41.7"/>
                        </g>
                    </g>
                    <g>
                        
                            <rect x="3229.6" y="831.5" transform="matrix(2.861441e-02 -0.9996 0.9996 2.861441e-02 2259.8789 4106.5845)" className="st24" width="26.5" height="118.1"/>
                        <g>
                            <path className="st13" d="M3269,919.8l99.6,3.2c15.3,0.5,28-11.5,28.5-26.7l0,0c0.5-15.3-11.5-28-26.7-28.5l-99.6-3.2
                                c-15.3-0.5-28,11.5-28.5,26.7v0C3241.7,906.5,3253.7,919.3,3269,919.8z"/>
                            <g>
                                <g>
                                    <path className="st24" d="M3359.2,890.1c-2.6,5.7-0.1,12.5,5.6,15.1c5.7,2.6,12.5,0.1,15.1-5.6c2.6-5.7,0.1-12.5-5.6-15.1
                                        C3368.5,881.9,3361.8,884.4,3359.2,890.1z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st24" d="M3257.5,888.1c-2.6,5.7-0.1,12.5,5.6,15.1c5.7,2.6,12.5,0.1,15.1-5.6c2.6-5.7,0.1-12.5-5.6-15.1
                                        C3266.9,879.8,3260.1,882.4,3257.5,888.1z"/>
                                </g>
                            </g>
                        </g>
                    </g>
                    <rect x="3269.4" y="596.8" className="st24" width="26.5" height="118.1"/>
                    <path className="st13" d="M3256.1,566.1V602c0,14.6,11.8,26.4,26.4,26.4h0c14.6,0,26.4-11.8,26.4-26.4v-35.9H3256.1z"/>
                    <g>
                        <path className="st24" d="M3268.4,595.7c-3.4,7.8,0.2,16.9,8.1,20.3c7.8,3.4,16.9-0.2,20.3-8.1c3.4-7.8-0.2-16.9-8.1-20.3
                            S3271.7,587.9,3268.4,595.7z"/>
                    </g>
                    <g>
                        <path className="st13" d="M3224.9,907l88.6-162c13.6-24.8,4.5-56-20.4-69.5h0c-24.8-13.6-56-4.5-69.5,20.4l-88.6,162
                            c-13.6,24.8-4.5,56,20.4,69.5v0C3180.2,941,3211.3,931.9,3224.9,907z"/>
                        <g>
                            <g>
                                <path className="st24" d="M3247.2,736.2c9,11.3,25.5,13.2,36.8,4.1c11.3-9,13.2-25.5,4.1-36.8c-9-11.3-25.5-13.2-36.8-4.1
                                    C3240,708.4,3238.2,724.9,3247.2,736.2z"/>
                            </g>
                            <g>
                                <path className="st24" d="M3159.5,898.7c8.9,11.4,25.4,13.4,36.8,4.5c11.4-8.9,13.4-25.4,4.5-36.8s-25.4-13.4-36.8-4.5
                                    C3152.7,870.8,3150.6,887.3,3159.5,898.7z"/>
                            </g>
                        </g>
                    </g>
                </g>
                <g>
                    <g id="cloud_5_">
                        <g>
                            <path className="st3" d="M3159.5,415.2c-0.7-4.9-1.1-9.8-1.1-14.9c0-60.8,49.3-110.1,110.1-110.1c45.6,0,84.7,27.7,101.4,67.3
                                c13.6-12.1,31.4-19.5,51.1-19.5c42.5,0,76.9,34.4,76.9,76.9h19.5c38,0,69.2,31.1,69.2,69.2v10.2c0,38-31.1,69.2-69.2,69.2
                                h-357.5c-38,0-69.2-31.1-69.2-69.2v-5.1C3090.7,450.2,3121.2,418,3159.5,415.2z"/>
                            <g>
                                <path className="st25" d="M3153.8,417.3l-0.5-2.9c1.5-0.2,3-0.4,4.5-0.6c-0.2-1.5-0.4-3-0.5-4.4l3-0.3c0.2,1.8,0.4,3.7,0.7,5.8
                                    l0.2,1.6l-1.6,0.1C3157.7,416.8,3155.8,417,3153.8,417.3z"/>
                                <path className="st25" d="M3159.9,397.1l-3-0.1c0.1-4.1,0.5-8.3,1-12.3l2.9,0.4C3160.4,389.1,3160.1,393.1,3159.9,397.1z
                                    M3163.3,373.4l-2.9-0.7c1-4,2.3-7.9,3.7-11.8l2.8,1.1C3165.5,365.6,3164.3,369.5,3163.3,373.4z M3171.8,350.9l-2.6-1.4
                                    c1.9-3.7,4-7.2,6.2-10.7l2.5,1.6C3175.6,343.9,3173.6,347.3,3171.8,350.9z M3360.2,342.2c-2.1-3.4-4.5-6.7-7-9.8l2.3-1.9
                                    c2.6,3.2,5,6.6,7.2,10L3360.2,342.2z M3185,330.9l-2.3-1.9c2.6-3.2,5.5-6.2,8.4-9l2.1,2.1
                                    C3190.3,324.8,3187.5,327.8,3185,330.9z M3345.2,323.4c-2.8-2.8-5.8-5.5-9-8l1.9-2.3c3.2,2.6,6.3,5.3,9.2,8.2L3345.2,323.4z
                                    M3202.3,314.2l-1.8-2.4c3.3-2.5,6.7-4.9,10.2-7l1.5,2.5C3208.8,309.5,3205.5,311.8,3202.3,314.2z M3326.4,308.5
                                    c-3.4-2.1-6.9-4.1-10.5-5.9l1.3-2.7c3.7,1.8,7.3,3.8,10.8,6L3326.4,308.5z M3222.8,301.8l-1.3-2.7c3.7-1.7,7.6-3.3,11.5-4.6
                                    l0.9,2.8C3230.2,298.6,3226.4,300.1,3222.8,301.8z M3304.8,298c-3.8-1.3-7.7-2.5-11.5-3.4l0.7-2.9c4,0.9,8,2.1,11.9,3.5
                                    L3304.8,298z M3245.6,294.1l-0.6-2.9c4-0.9,8.1-1.5,12.2-1.9l0.3,3C3253.5,292.7,3249.5,293.3,3245.6,294.1z M3281.4,292.5
                                    c-4-0.5-8-0.7-12-0.8l0-3c4.1,0,8.3,0.3,12.3,0.8L3281.4,292.5z"/>
                                <path className="st25" d="M3369.4,360l-0.8-1.9c-0.8-1.8-1.6-3.6-2.4-5.3l2.7-1.3c0.6,1.2,1.1,2.4,1.7,3.6c1-0.9,2.1-1.7,3.1-2.5
                                    l1.8,2.4c-1.5,1.2-3,2.4-4.5,3.7L3369.4,360z"/>
                                <path className="st25" d="M3494.2,396.8c-1-4-2.3-8-4-11.8l2.7-1.2c1.7,3.9,3.1,8.1,4.1,12.2L3494.2,396.8z M3484.4,374
                                    c-2.2-3.5-4.8-6.8-7.6-9.8l2.2-2c2.9,3.2,5.5,6.6,7.9,10.2L3484.4,374z M3467.8,355.7c-3.2-2.6-6.7-4.9-10.3-6.9l1.4-2.6
                                    c3.8,2.1,7.4,4.5,10.7,7.2L3467.8,355.7z M3385.9,348.2l-1.4-2.6c3.8-2,7.8-3.7,11.9-5.1l0.9,2.8
                                    C3393.4,344.6,3389.5,346.3,3385.9,348.2z M3446.1,343.7c-3.9-1.4-7.9-2.4-12-3.1l0.5-2.9c4.2,0.7,8.4,1.8,12.5,3.3
                                    L3446.1,343.7z M3409.4,340.4l-0.5-2.9c4-0.6,8.1-0.9,12.1-0.9l0.8,0l0,3l-0.7,0C3417.1,339.5,3413.2,339.8,3409.4,340.4z"/>
                                <path className="st25" d="M3503.9,416.4h-7.4v-1.5c0-1.9-0.1-3.9-0.2-5.8l3-0.2c0.1,1.5,0.2,3,0.2,4.6h4.5V416.4z"/>
                                <path className="st25" d="M3509.6,564.9h-11.8v-3h11.8V564.9z M3486,564.9h-11.8v-3h11.8V564.9z M3462.3,564.9h-11.8v-3h11.8
                                    V564.9z M3438.6,564.9h-11.8v-3h11.8V564.9z M3414.9,564.9h-11.8v-3h11.8V564.9z M3391.2,564.9h-11.8v-3h11.8V564.9z
                                    M3367.5,564.9h-11.8v-3h11.8V564.9z M3343.9,564.9H3332v-3h11.8V564.9z M3320.2,564.9h-11.8v-3h11.8V564.9z M3296.5,564.9
                                    h-11.8v-3h11.8V564.9z M3272.8,564.9H3261v-3h11.8V564.9z M3249.1,564.9h-11.8v-3h11.8V564.9z M3225.4,564.9h-11.8v-3h11.8
                                    V564.9z M3201.7,564.9h-11.8v-3h11.8V564.9z M3178.1,564.9h-11.8v-3h11.8V564.9z M3521.6,564.8l-0.2-3
                                    c3.9-0.2,7.7-0.8,11.4-1.7l0.7,2.9C3529.6,564,3525.6,564.6,3521.6,564.8z M3154.3,564.7c-4-0.3-8-1-11.9-2l0.7-2.9
                                    c3.7,1,7.6,1.6,11.4,1.9L3154.3,564.7z M3545,559.3l-1.2-2.7c3.5-1.5,7-3.3,10.2-5.4l1.6,2.5
                                    C3552.3,555.8,3548.7,557.7,3545,559.3z M3130.9,558.7c-3.7-1.7-7.2-3.6-10.5-5.9l1.7-2.5c3.2,2.2,6.6,4.1,10.1,5.7
                                    L3130.9,558.7z M3565.2,546.2l-2-2.2c2.8-2.6,5.5-5.5,7.8-8.5l2.4,1.8C3570.9,540.5,3568.2,543.5,3565.2,546.2z M3111,545.2
                                    c-2.9-2.8-5.6-5.8-8-9.1l2.4-1.8c2.3,3.1,4.9,6,7.6,8.7L3111,545.2z M3579.9,527.1l-2.6-1.4c1.8-3.4,3.3-7,4.5-10.7l2.8,0.9
                                    C3583.4,519.8,3581.8,523.5,3579.9,527.1z M3096.7,525.8c-1.8-3.6-3.3-7.4-4.5-11.2l2.8-0.9c1.1,3.7,2.6,7.3,4.3,10.7
                                    L3096.7,525.8z M3587.3,504.2l-2.9-0.4c0.4-3.1,0.7-6.3,0.7-9.5v-2.1h3v2.1C3588,497.6,3587.8,500.9,3587.3,504.2z
                                    M3089.8,502.8c-0.3-2.8-0.5-5.7-0.5-8.5v-3.5h3v3.5c0,2.7,0.2,5.5,0.5,8.2L3089.8,502.8z M3585,480.4
                                    c-0.2-3.9-0.7-7.7-1.6-11.5l2.9-0.7c0.9,3.9,1.5,7.9,1.7,12L3585,480.4z M3092.9,479.2l-2.9-0.4c0.6-4,1.4-7.9,2.6-11.8
                                    l2.8,0.9C3094.3,471.5,3093.4,475.4,3092.9,479.2z M3579.8,458c-1.5-3.5-3.3-7-5.4-10.2l2.5-1.6c2.2,3.4,4.1,7,5.6,10.7
                                    L3579.8,458z M3099.7,457.1l-2.7-1.3c1.8-3.6,3.9-7.1,6.2-10.3l2.4,1.7C3103.4,450.3,3101.4,453.6,3099.7,457.1z
                                    M3567.4,438.6c-2.6-2.9-5.5-5.5-8.5-7.9l1.8-2.3c3.2,2.5,6.2,5.2,8.9,8.2L3567.4,438.6z M3113.1,438.3l-2.1-2.1
                                    c2.8-2.9,5.9-5.5,9.1-7.9l1.8,2.4C3118.8,433,3115.9,435.5,3113.1,438.3z M3131.7,424.6l-1.4-2.6c3.6-1.8,7.3-3.4,11.1-4.7
                                    l0.9,2.8C3138.8,421.3,3135.2,422.8,3131.7,424.6z M3549.2,424.4c-3.4-1.8-7-3.4-10.6-4.6l0.9-2.8c3.8,1.3,7.5,2.9,11.1,4.8
                                    L3549.2,424.4z M3527.3,417.1c-3.3-0.5-6.6-0.7-9.9-0.7h-1.7v-3h1.7c3.5,0,6.9,0.3,10.4,0.8L3527.3,417.1z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        
                            <rect x="3318.9" y="398.6" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6789.1558 934.1056)" className="st26" width="151.4" height="136.9"/>
                        <g>
                            
                                <rect x="3312.5" y="398.6" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6789.1558 838.4971)" className="st1" width="164.2" height="41.3"/>
                            <g>
                                
                                    <rect x="3453.5" y="409.1" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6923.1948 840.1381)" className="st26" width="16.2" height="21.9"/>
                                
                                    <rect x="3429.1" y="409.1" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6874.4858 840.1381)" className="st26" width="16.2" height="21.9"/>
                                
                                    <rect x="3404.8" y="409.1" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6825.7764 840.1381)" className="st26" width="16.2" height="21.9"/>
                            </g>
                        </g>
                        <g>
                            
                                <rect x="3312.5" y="447.7" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6789.1558 936.6709)" className="st1" width="164.2" height="41.3"/>
                            <g>
                                
                                    <rect x="3453.5" y="458.2" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6923.1948 938.3121)" className="st26" width="16.2" height="21.9"/>
                                
                                    <rect x="3429.1" y="458.2" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6874.4858 938.3121)" className="st26" width="16.2" height="21.9"/>
                                
                                    <rect x="3404.8" y="458.2" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6825.7764 938.3121)" className="st26" width="16.2" height="21.9"/>
                            </g>
                        </g>
                        <g>
                            
                                <rect x="3312.5" y="496.8" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6789.1558 1034.8448)" className="st1" width="164.2" height="41.3"/>
                            <g>
                                
                                    <rect x="3453.5" y="507.3" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6923.1948 1036.4858)" className="st26" width="16.2" height="21.9"/>
                                
                                    <rect x="3429.1" y="507.3" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6874.4858 1036.4858)" className="st26" width="16.2" height="21.9"/>
                                
                                    <rect x="3404.8" y="507.3" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 6825.7764 1036.4858)" className="st26" width="16.2" height="21.9"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <g>
                <g id="cloud_1_">
                    <g>
                        <path className="st3" d="M1808.9,519.6c-0.5-3.7-0.8-7.4-0.8-11.3c0-46.1,37.4-83.5,83.5-83.5c34.6,0,64.2,21,76.9,51
                            c10.3-9.2,23.8-14.8,38.7-14.8c32.2,0,58.3,26.1,58.3,58.3h14.7c28.8,0,52.4,23.6,52.4,52.4v7.7c0,28.8-23.6,52.4-52.4,52.4
                            h-271c-28.8,0-52.4-23.6-52.4-52.4v-3.9C1756.8,546.2,1779.9,521.8,1808.9,519.6z"/>
                        <g>
                            <path className="st25" d="M1804.6,521.3l-0.3-2.2c1.1-0.2,2.2-0.3,3.4-0.4c-0.1-1.2-0.3-2.3-0.4-3.3l2.2-0.2
                                c0.1,1.4,0.3,2.8,0.5,4.4l0.2,1.2l-1.2,0.1C1807.5,520.9,1806.1,521,1804.6,521.3z"/>
                            <path className="st25" d="M1809.2,505.9l-2.3-0.1c0.1-3.1,0.4-6.3,0.8-9.3l2.2,0.3C1809.6,499.9,1809.3,502.9,1809.2,505.9z
                                M1811.8,487.9l-2.2-0.6c0.8-3,1.7-6,2.8-8.9l2.1,0.8C1813.4,482.1,1812.5,485,1811.8,487.9z M1818.2,470.9l-2-1
                                c1.4-2.8,3-5.5,4.7-8.1l1.9,1.2C1821.1,465.5,1819.6,468.2,1818.2,470.9z M1961.1,464.3c-1.6-2.6-3.4-5.1-5.3-7.4l1.8-1.4
                                c1.9,2.4,3.8,5,5.4,7.6L1961.1,464.3z M1828.2,455.7l-1.7-1.4c2-2.4,4.1-4.7,6.4-6.9l1.6,1.6
                                C1832.3,451.1,1830.2,453.4,1828.2,455.7z M1949.7,450.1c-2.1-2.1-4.4-4.2-6.8-6.1l1.4-1.8c2.4,1.9,4.8,4,7,6.2L1949.7,450.1z
                                M1841.4,443.1l-1.4-1.8c2.5-1.9,5.1-3.7,7.7-5.3l1.2,1.9C1846.3,439.5,1843.8,441.2,1841.4,443.1z M1935.5,438.7
                                c-2.6-1.6-5.2-3.1-8-4.4l1-2c2.8,1.4,5.5,2.9,8.2,4.6L1935.5,438.7z M1856.9,433.6l-0.9-2c2.8-1.3,5.8-2.5,8.7-3.5l0.7,2.1
                                C1862.5,431.2,1859.7,432.4,1856.9,433.6z M1919.1,430.7c-2.9-1-5.8-1.9-8.7-2.6l0.5-2.2c3,0.7,6.1,1.6,9,2.6L1919.1,430.7z
                                M1874.2,427.9l-0.5-2.2c3-0.7,6.1-1.1,9.2-1.5l0.2,2.2C1880.1,426.7,1877.1,427.2,1874.2,427.9z M1901.4,426.6
                                c-3-0.4-6.1-0.6-9.1-0.6l0-2.3c3.1,0,6.3,0.2,9.3,0.6L1901.4,426.6z"/>
                            <path className="st25" d="M1968,477.8l-0.6-1.4c-0.6-1.4-1.2-2.7-1.8-4l2-1c0.4,0.9,0.9,1.8,1.3,2.7c0.8-0.7,1.6-1.3,2.4-1.9
                                l1.4,1.8c-1.2,0.9-2.3,1.8-3.4,2.8L1968,477.8z"/>
                            <path className="st25" d="M2062.7,505.7c-0.8-3-1.8-6-3-8.9l2.1-0.9c1.3,3,2.3,6.1,3.1,9.3L2062.7,505.7z M2055.3,488.4
                                c-1.7-2.6-3.6-5.1-5.7-7.5l1.7-1.5c2.2,2.4,4.2,5,6,7.8L2055.3,488.4z M2042.6,474.5c-2.5-1.9-5.1-3.7-7.8-5.2l1.1-2
                                c2.8,1.6,5.6,3.4,8.1,5.4L2042.6,474.5z M1980.5,468.8l-1.1-2c2.9-1.5,5.9-2.8,9-3.9l0.7,2.1
                                C1986.2,466.1,1983.3,467.4,1980.5,468.8z M2026.2,465.5c-3-1-6-1.8-9.1-2.4l0.4-2.2c3.2,0.6,6.4,1.4,9.5,2.5L2026.2,465.5z
                                M1998.3,462.9l-0.3-2.2c3-0.5,6.1-0.7,9.2-0.7l0.6,0l0,2.3l-0.6,0C2004.2,462.2,2001.2,462.5,1998.3,462.9z"/>
                            <path className="st25" d="M2070,520.5h-5.6v-1.1c0-1.5-0.1-3-0.2-4.4l2.2-0.2c0.1,1.1,0.1,2.3,0.2,3.5h3.4V520.5z"/>
                            <path className="st25" d="M2074.4,633.2h-9v-2.3h9V633.2z M2056.4,633.2h-9v-2.3h9V633.2z M2038.5,633.2h-9v-2.3h9V633.2z
                                M2020.5,633.2h-9v-2.3h9V633.2z M2002.5,633.2h-9v-2.3h9V633.2z M1984.6,633.2h-9v-2.3h9V633.2z M1966.6,633.2h-9v-2.3h9
                                V633.2z M1948.7,633.2h-9v-2.3h9V633.2z M1930.7,633.2h-9v-2.3h9V633.2z M1912.8,633.2h-9v-2.3h9V633.2z M1894.8,633.2h-9
                                v-2.3h9V633.2z M1876.8,633.2h-9v-2.3h9V633.2z M1858.9,633.2h-9v-2.3h9V633.2z M1840.9,633.2h-9v-2.3h9V633.2z M1823,633.2
                                h-9v-2.3h9V633.2z M2083.4,633.1l-0.1-2.2c2.9-0.2,5.8-0.6,8.7-1.3l0.5,2.2C2089.5,632.4,2086.5,632.9,2083.4,633.1z
                                M1804.9,633c-3-0.2-6.1-0.8-9-1.5l0.6-2.2c2.8,0.7,5.7,1.2,8.6,1.5L1804.9,633z M2101.2,628.9l-0.9-2.1
                                c2.7-1.1,5.3-2.5,7.7-4.1l1.2,1.9C2106.7,626.2,2104,627.7,2101.2,628.9z M1787.3,628.4c-2.8-1.3-5.5-2.8-8-4.5l1.3-1.9
                                c2.4,1.6,5,3.1,7.7,4.3L1787.3,628.4z M2116.5,618.9l-1.5-1.7c2.1-2,4.1-4.2,5.9-6.5l1.8,1.4
                                C2120.8,614.6,2118.7,616.9,2116.5,618.9z M1772.2,618.2c-2.2-2.1-4.2-4.4-6-6.9l1.8-1.3c1.7,2.3,3.7,4.6,5.8,6.6
                                L1772.2,618.2z M2127.6,604.5l-2-1.1c1.4-2.6,2.5-5.3,3.4-8.1l2.1,0.7C2130.3,598.9,2129.1,601.8,2127.6,604.5z M1761.3,603.5
                                c-1.4-2.7-2.5-5.6-3.4-8.5l2.2-0.7c0.8,2.8,1.9,5.5,3.3,8.1L1761.3,603.5z M2133.3,587.1l-2.2-0.3c0.3-2.4,0.5-4.8,0.5-7.2
                                V578h2.3v1.6C2133.8,582.1,2133.6,584.6,2133.3,587.1z M1756,586c-0.3-2.1-0.4-4.3-0.4-6.5v-2.6h2.3v2.6
                                c0,2.1,0.1,4.1,0.4,6.2L1756,586z M2131.5,569.1c-0.2-2.9-0.6-5.8-1.2-8.7l2.2-0.5c0.7,3,1.1,6,1.3,9.1L2131.5,569.1z
                                M1758.4,568.1l-2.2-0.3c0.4-3,1.1-6,2-8.9l2.2,0.7C1759.5,562.4,1758.8,565.2,1758.4,568.1z M2127.6,552.1
                                c-1.1-2.7-2.5-5.3-4.1-7.8l1.9-1.2c1.6,2.6,3.1,5.3,4.3,8.1L2127.6,552.1z M1763.6,551.4l-2-1c1.4-2.7,2.9-5.4,4.7-7.8
                                l1.8,1.3C1766.4,546.3,1764.9,548.8,1763.6,551.4z M2118.1,537.4c-2-2.2-4.1-4.2-6.4-6l1.4-1.8c2.4,1.9,4.7,4,6.7,6.2
                                L2118.1,537.4z M1773.8,537.1l-1.6-1.6c2.1-2.2,4.5-4.2,6.9-6l1.3,1.8C1778.1,533.1,1775.8,535.1,1773.8,537.1z M1787.9,526.7
                                l-1-2c2.7-1.4,5.5-2.6,8.4-3.5l0.7,2.1C1793.2,524.2,1790.5,525.4,1787.9,526.7z M2104.3,526.6c-2.6-1.4-5.3-2.6-8.1-3.5
                                l0.7-2.1c2.9,1,5.7,2.2,8.4,3.6L2104.3,526.6z M2087.8,521.1c-2.5-0.4-5-0.6-7.5-0.6h-1.3v-2.3h1.3c2.6,0,5.3,0.2,7.8,0.6
                                L2087.8,521.1z"/>
                        </g>
                    </g>
                </g>
                <g>
                    
                        <rect x="1929.7" y="507" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 3974.2686 1117.9036)" className="st26" width="114.8" height="103.8"/>
                    <g>
                        
                            <rect x="1924.9" y="507" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 3974.2686 1045.4181)" className="st1" width="124.5" height="31.3"/>
                        <g>
                            
                                <rect x="2031.8" y="515" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 4075.8899 1046.6624)" className="st26" width="12.3" height="16.6"/>
                            
                                <rect x="2013.3" y="515" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 4038.9612 1046.6624)" className="st26" width="12.3" height="16.6"/>
                            
                                <rect x="1994.9" y="515" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 4002.0322 1046.6624)" className="st26" width="12.3" height="16.6"/>
                        </g>
                    </g>
                    <g>
                        
                            <rect x="1924.9" y="544.3" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 3974.2686 1119.8485)" className="st1" width="124.5" height="31.3"/>
                        <g>
                            
                                <rect x="2031.8" y="552.2" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 4075.8899 1121.0927)" className="st26" width="12.3" height="16.6"/>
                            
                                <rect x="2013.3" y="552.2" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 4038.9612 1121.0927)" className="st26" width="12.3" height="16.6"/>
                            
                                <rect x="1994.9" y="552.2" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 4002.0322 1121.0927)" className="st26" width="12.3" height="16.6"/>
                        </g>
                    </g>
                    <g>
                        
                            <rect x="1924.9" y="581.5" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 3974.2686 1194.2789)" className="st1" width="124.5" height="31.3"/>
                        <g>
                            
                                <rect x="2031.8" y="589.5" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 4075.8899 1195.5231)" className="st26" width="12.3" height="16.6"/>
                            
                                <rect x="2013.3" y="589.5" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 4038.9612 1195.5231)" className="st26" width="12.3" height="16.6"/>
                            
                                <rect x="1994.9" y="589.5" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 4002.0322 1195.5231)" className="st26" width="12.3" height="16.6"/>
                        </g>
                    </g>
                </g>
            </g>
            <g>
                <defs>
                    <rect id="SVGID_1_" x="4419.6" y="1366.1" width="355.9" height="328.6"/>
                </defs>
                <g>
                    <g>
                        <path className="st1" d="M4747.4,1492.8c0.1,35.8-48.2,33.3-76.6,63.1c-28.5,29.9,6.6,62.5-64.4,78.2c-43.8,9.7-42.7-9.2-81.7,41.2
                            c-34.3,44.4-48.9-20.9-91.1-5.7c-54.5,19.7-71-90-21.8-117.2s77.8,0.1,82.1-47.3c4.3-47.3,37.1-68.6,82.4-63.1
                            c56,6.8,53.6,55.4,78.8,26C4680.2,1438.7,4747.3,1445.5,4747.4,1492.8z"/>
                        <path className="st28" d="M4357.6,1682.1c143.8-139.5,326.3-184,328.1-184.4c7-1.5,5.7-4.5-1.3-3c-1.9,0.4-188.4,40.7-334,181.9
                            L4357.6,1682.1z"/>
                    </g>
                    <g>
                        <path className="st29" d="M4553.4,1391.4c11.7,21.6-18.2,35.7-25.7,62.9s22.6,46.4-15.2,79c-23.3,20.1-37.5-11.6-58.2,20.8
                            c-20.7,32.4-8.1,39.7-28.4,49.9c-35.5,17.8-80.7-35.4-59.9-67.7s54.9-25.4,42.1-55.4c-12.8-30-17.3-42.8,11.7-54.2
                            c36-14.1,64.1,5.7,69.7-20.2S4538,1362.9,4553.4,1391.4z"/>
                        <path className="st30" d="M4357.6,1682.1l-7-4.6c41.9-132.4,164.6-263.7,165.6-264.5c4.3-3.6,8,0.9,3.8,4.5
                            C4518.9,1418.3,4399,1551.4,4357.6,1682.1z"/>
                    </g>
                </g>
            </g>
            <path className="st15" d="M288.1,1706.8h4417.6c2.8,0,5.1-2.3,5.1-5.1l0,0c0-2.8-2.3-5.1-5.1-5.1H288.1c-2.8,0-5.1,2.3-5.1,5.1l0,0
                C283.1,1704.6,285.3,1706.8,288.1,1706.8z"/>
            <g>
                <g id="tablet_2_">
                    <path className="st13" d="M2213.8,1665V531.6c0-17.1,13.9-31,31-31h748.3c17.1,0,31,13.9,31,31V1665c0,17.1-13.9,31-31,31h-748.3
                        C2227.7,1696,2213.8,1682.1,2213.8,1665z"/>
                    <path d="M2254.1,516.6h729.6c13.6,0,24.7,11,24.7,24.7v1110.3c0,13.6-11,24.7-24.7,24.7h-729.6c-13.6,0-24.7-11-24.7-24.7V541.2
                        C2229.5,527.6,2240.5,516.6,2254.1,516.6z"/>
                    <path className="st13" d="M2735.7,1654.8h-233.5c-4,0-7.2-3.2-7.2-7.2l0,0c0-4,3.2-7.2,7.2-7.2h233.5c4,0,7.2,3.2,7.2,7.2l0,0
                        C2742.9,1651.5,2739.6,1654.8,2735.7,1654.8z"/>
                    <g>
                        
                            <rect x="2101.7" y="739.9" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 3718.3083 -1519.5731)" className="st3" width="1034.5" height="718.8"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path className="st31" d="M2594.7,985.6H2318c-9.3,0-16.8-7.5-16.8-16.8v-215c0-9.3,7.5-16.8,16.8-16.8h276.7
                            c9.3,0,16.8,7.5,16.8,16.8v215C2611.4,978.1,2603.9,985.6,2594.7,985.6z"/>
                        <path className="st23" d="M2597.1,932.8h-265.7V749.6c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4V930h262.9
                            c0.8,0,1.4,0.6,1.4,1.4S2597.8,932.8,2597.1,932.8z"/>
                        <rect x="2357.8" y="875.3" className="graphBar1SmartFactory" width="29.3" />
                        <rect x="2398.7" y="862.5" className="graphBar2SmartFactory" width="29.3"/>
                        <rect x="2439.6" y="836.6" className="graphBar3SmartFactory" width="29.3"/>
                        <rect x="2480.5" y="818.5" className="graphBar4SmartFactory" width="29.3" />
                        <rect x="2521.3" y="779.4" className="graphBar5SmartFactory" width="29.3" />
                    </g>
                    <g>
                        <g>
                            <path className="st31" d="M2927.9,985.6h-276.7c-9.3,0-16.8-7.5-16.8-16.8v-215c0-9.3,7.5-16.8,16.8-16.8h276.7
                                c9.3,0,16.8,7.5,16.8,16.8v215C2944.6,978.1,2937.1,985.6,2927.9,985.6z"/>
                            <path className="st23" d="M2930.2,932.8h-265.7V759.5c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4V930h262.9c0.8,0,1.4,0.6,1.4,1.4
                                S2931,932.8,2930.2,932.8z"/>
                        </g>
                        <path className="st23" d="M2665.9,931.4c44.5-3.9,50.7-182.9,116.8-102.6c62.7,76.2,66-65.3,134.2-65.3v166.4
                            C2916.9,929.8,2665.9,931.4,2665.9,931.4z"/>
                    </g>
                    <g>
                        <path className="st31" d="M2944.8,692.1h-643.3v-54.8h643.3V692.1z M2303.1,690.5h640.2v-51.7h-640.2V690.5z"/>
                        <rect x="2313.3" y="655.4" className="loadingBarSmartFactory" height="18.4"/>
                    </g>
                    <g>
                        <rect x="2300.7" y="1020.1" className="st32" width="221.3" height="17.4"/>
                        <rect x="2298.9" y="1122.1" className="st32" width="221.3" height="17.4"/>
                        <rect x="2300.7" y="1084" className="st32" width="193.5" height="17.4"/>
                        <rect x="2300.7" y="1155.3" className="st32" width="193.5" height="17.4"/>
                        <rect x="2300.7" y="1051.6" className="st32" width="335.8" height="17.4"/>
                    </g>
                    <g>
                        <path className="st31" d="M2924.5,1545.4h-613.2c-9.3,0-16.8-7.5-16.8-16.8v-301.9c0-9.3,7.5-16.8,16.8-16.8h613.2
                            c9.3,0,16.8,7.5,16.8,16.8v301.9C2941.3,1537.9,2933.7,1545.4,2924.5,1545.4z"/>
                        <path className="st3" d="M2877.2,1305.8h-530.5c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h530.5c0.4,0,0.8,0.3,0.8,0.8
                            C2878,1305.5,2877.7,1305.8,2877.2,1305.8z"/>
                        <path className="st3" d="M2877.2,1418.3h-530.5c-0.4,0-0.8-0.3-0.8-0.8s0.4-0.8,0.8-0.8h530.5c0.4,0,0.8,0.3,0.8,0.8
                            S2877.7,1418.3,2877.2,1418.3z"/>
                        <path className="st3" d="M2877.2,1363.5h-530.5c-0.4,0-0.8-0.3-0.8-0.8s0.4-0.8,0.8-0.8h530.5c0.4,0,0.8,0.3,0.8,0.8
                            S2877.7,1363.5,2877.2,1363.5z"/>
                        <path className="st3" d="M2877.2,1475.3h-532l-0.7-200.5c0-0.9,0.7-1.6,1.6-1.6c0,0,0,0,0,0c0.9,0,1.6,0.7,1.6,1.6l0.7,197.4h528.9
                            c0.9,0,1.6,0.7,1.6,1.6C2878.8,1474.6,2878.1,1475.3,2877.2,1475.3z"/>
                        <path className="st3" d="M2620.5,1325.7l-120.7,116l-62.2-55l-92,85.9c-0.6,0.6-0.7,1.6-0.1,2.2c0.3,0.3,0.7,0.5,1.1,0.5
                            c0.4,0,0.8-0.1,1.1-0.4l89.9-84l62.2,55l120.6-115.9V1325.7z"/>
                        
                            <ellipse transform="matrix(0.2298 -0.9732 0.9732 0.2298 525.8115 3442.3235)" className="st3" cx="2437.7" cy="1389" rx="8.4" ry="8.4"/>
                        <circle className="st3" cx="2500.9" cy="1443" r="8.4"/>
                        
                            <ellipse transform="matrix(0.2298 -0.9732 0.9732 0.2298 728.1362 3573.1628)" className="st3" cx="2621.5" cy="1326.6" rx="8.4" ry="8.4"/>
                    </g>
                    <g>
                        <path className="st13" d="M2756.2,1018.5c0,0-0.1,0-0.1,0c-40.1,0-72.6,32.5-72.6,72.6s32.5,72.6,72.6,72.6c12.2,0,23.6-3,33.7-8.3
                            l-33.6-64.3V1018.5z"/>
                        <path className="st8" d="M2789.8,1155.4c23.1-12.1,38.9-36.4,38.9-64.3h-72.5L2789.8,1155.4z"/>
                        <path className="st32" d="M2756.2,1018.5v72.6h72.5C2828.7,1051,2796.3,1018.6,2756.2,1018.5z"/>
                    </g>
                    <g>
                        <rect x="2875.9" y="1052.7" className="st31" width="61.4" height="9.7"/>
                        <rect x="2875.9" y="1074" className="st31" width="61.4" height="9.7"/>
                        <rect x="2875.9" y="1095.3" className="st31" width="61.4" height="9.7"/>
                        <rect x="2875.9" y="1116.7" className="st31" width="61.4" height="9.7"/>
                    </g>
                </g>
            </g>
            <g>
                <rect x="646.2" y="1449.5" className="st13" width="379.4" height="169.2"/>
                <rect x="997.8" y="1465.3" className="st33" width="12.6" height="134.6"/>
                <rect x="969.7" y="1465.3" className="st33" width="12.6" height="134.6"/>
                <rect x="941.7" y="1465.3" className="st33" width="12.6" height="134.6"/>
                <rect x="913.7" y="1465.3" className="st33" width="12.6" height="134.6"/>
                <rect x="885.6" y="1465.3" className="st33" width="12.6" height="134.6"/>
                <rect x="857.6" y="1465.2" className="st33" width="12.6" height="134.6"/>
                <rect x="829.6" y="1465.2" className="st33" width="12.6" height="134.6"/>
                <rect x="801.5" y="1465.2" className="st33" width="12.6" height="134.6"/>
                <rect x="773.5" y="1465.2" className="st33" width="12.6" height="134.6"/>
                <rect x="745.5" y="1465.2" className="st33" width="12.6" height="134.6"/>
                <rect x="717.4" y="1465.1" className="st33" width="12.6" height="134.6"/>
                <rect x="689.4" y="1465.1" className="st33" width="12.6" height="134.6"/>
                <rect x="661.4" y="1465.1" className="st33" width="12.6" height="134.6"/>
                <path className="st15" d="M642.4,1644.2l168.2,0.1l17.4,0c6.5-13.3,19.4-22.6,34.5-23.1c10.2-0.3,20.1,3.6,27.7,10.8
                    c3.7,3.6,6.7,7.7,8.9,12.3l7.6,0l9.8,0h7.6c6.5-13.3,19.4-22.6,34.5-23.1c10.2-0.3,20.1,3.6,27.7,10.8c3.7,3.6,6.7,7.7,8.9,12.3
                    l17.4,0l23.9,0l0-27l-42.5,0l-68.7,0l-27.5,0l-68.6,0l-186.8-0.1L642.4,1644.2z"/>
                <g>
                    <ellipse className="st15" cx="959" cy="1661.3" rx="36.2" ry="36.3"/>
                    <ellipse className="st13" cx="959" cy="1661.3" rx="18.2" ry="18.3"/>
                </g>
                <g>
                    <ellipse className="st15" cx="863.9" cy="1661.3" rx="36.2" ry="36.3"/>
                    <ellipse className="st13" cx="863.9" cy="1661.3" rx="18.2" ry="18.3"/>
                </g>
                <g>
                    <ellipse className="st15" cx="556.5" cy="1661.3" rx="36.2" ry="36.3"/>
                    <ellipse className="st13" cx="556.5" cy="1661.3" rx="18.2" ry="18.3"/>
                </g>
                <rect x="645.9" y="1627" className="st15" width="112.6" height="43.4"/>
                <path className="st15" d="M903.5,1662.5c0,0.9,0,1.8,0,2.7l16.1-0.4c-0.2-7.3,1.4-14.3,4.4-20.4c6.5-13.3,19.4-22.6,34.5-23.1
                    c10.2-0.3,20.1,3.6,27.7,10.8c3.7,3.6,6.7,7.7,8.9,12.3c2.7,5.5,4.1,11.7,4.3,18.1l16.1-0.4c-0.2-6.1-1.2-12.1-3-17.7
                    c-3-9.1-8.2-17.3-15.2-24.1c-1.1-1.1-2.2-2.1-3.4-3c-10.2-8.3-22.8-12.6-35.9-12.3c-12.4,0.3-23.7,4.9-32.8,12.2
                    c-5.5,4.4-10.1,9.9-13.7,16.1c-2,3.4-3.6,7.1-4.9,10.9C904.7,1650.1,903.6,1656.2,903.5,1662.5z"/>
                <path className="st15" d="M807.4,1665.1l16.1-0.4c-0.2-7.3,1.4-14.3,4.4-20.4c6.5-13.3,19.4-22.6,34.5-23.1
                    c10.2-0.3,20.1,3.6,27.7,10.8c3.7,3.6,6.7,7.7,8.9,12.3c2.7,5.5,4.1,11.7,4.3,18.1l0.2,0l16-0.4c-0.1-6.1-1.2-12.1-3-17.7
                    c-1.3-3.8-2.9-7.5-4.9-10.9c-2.8-4.8-6.2-9.2-10.3-13.1c-1.1-1.1-2.2-2.1-3.4-3c-10.2-8.3-22.8-12.6-35.8-12.3
                    c-12.4,0.3-23.7,4.9-32.8,12.2c-8.5,6.9-15.1,16.3-18.7,27C808.3,1650.8,807.2,1657.8,807.4,1665.1z"/>
                <g>
                    <polygon className="st13" points="627.5,1470.7 627.4,1616.9 447.4,1616.8 447.5,1562.7 528.7,1471.6 				"/>
                    <g>
                        <path className="st15" d="M435.7,1643.8l68.1,0l17.4,0c6.5-13.4,19.4-22.6,34.5-23.1c10.2-0.3,20.1,3.6,27.7,10.8
                            c3.7,3.6,6.7,7.7,8.9,12.3l17.4,0l20.9,0l0-27l-39.5,0l-68.7,0l-86.7-0.1L435.7,1643.8z"/>
                    </g>
                    <path className="st15" d="M500.7,1664.6l16.1-0.4c-0.2-7.3,1.4-14.3,4.4-20.4c6.5-13.4,19.4-22.6,34.5-23.1
                        c10.2-0.3,20.1,3.6,27.7,10.8c3.7,3.6,6.7,7.7,8.9,12.3c2.7,5.5,4.1,11.7,4.3,18.1l16.1-0.4c-0.2-6.1-1.2-12.1-3-17.7
                        c-3-9.1-8.2-17.3-15.2-24.1c-1.1-1-2.2-2-3.4-3c-10.2-8.3-22.8-12.7-35.9-12.3c-12.4,0.3-23.7,4.9-32.8,12.2
                        c-8.5,6.9-15,16.3-18.6,27C501.6,1650.3,500.5,1657.4,500.7,1664.6z"/>
                    <path className="st19" d="M502.9,1512.7l21.2-24.2c0.7-0.7,1.6-1.2,2.6-1.2l24.6,0c1.9,0,3.4,1.6,3.4,3.5l0,49.2
                        c0,1.9-1.5,3.5-3.4,3.5l-48.4,0L502.9,1512.7z"/>
                    <path className="st19" d="M609.9,1543.5l-39.9,0c-1.4,0-2.6-1.2-2.6-2.6l0-52.5c0-1.4,1.2-2.6,2.6-2.6l39.9,0c1.4,0,2.6,1.2,2.6,2.6
                        l0,52.5C612.5,1542.4,611.3,1543.5,609.9,1543.5z"/>
                    <path className="st19" d="M486.1,1571L486.1,1571c3.4,0,6.1-2.7,6.1-6.1l0-22.8c0-3.4-2.7-6.1-6.1-6.1l0,0c-3.4,0-6.1,2.7-6.1,6.1
                        l0,22.8C480,1568.3,482.7,1571,486.1,1571z"/>
                    <rect x="442.8" y="1562.4" className="st19" width="19.2" height="31.9"/>
                </g>
            </g>
            <g>
                <g>
                    
                        <ellipse transform="matrix(4.621791e-02 -0.9989 0.9989 4.621791e-02 -878.9586 1941.9491)" className="st1" cx="577.5" cy="1431.3" rx="13.9" ry="14.1"/>
                    <g>
                        <path className="signaBar3" d="M632.6,1386.6L632.6,1386.6c1.7-1.7,1.7-4.5,0-6.3c-31.1-30.7-79.1-31.8-111.6-2.6
                            c-1.8,1.6-1.9,4.4-0.3,6.2v0c1.7,1.9,4.5,2.1,6.4,0.4c29-25.6,71.3-24.6,99.1,2.3C628,1388.4,630.9,1388.4,632.6,1386.6z"/>
                        <path className="signaBar2" d="M619.5,1400L619.5,1400c1.8-1.8,1.7-4.7-0.1-6.4c-24.2-22.9-60.6-23.7-85.7-2c-1.9,1.6-2.1,4.5-0.4,6.4
                            l0,0c1.6,1.8,4.4,2,6.3,0.5c21.7-18.3,52.7-17.6,73.6,1.7C615,1401.9,617.8,1401.8,619.5,1400z"/>
                        <path className="signaBar1" d="M606.8,1413.1L606.8,1413.1c1.8-1.9,1.7-4.8-0.2-6.5c-17.4-15.5-42.7-16.1-60.8-1.4
                            c-2,1.6-2.2,4.5-0.5,6.4l0,0c1.6,1.8,4.3,2,6.2,0.5c14.7-11.4,34.8-10.9,48.9,1.2C602.3,1414.9,605.1,1414.8,606.8,1413.1z"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
    <g id="people">
        <g id="male_04">
            <path className="st34" d="M1194.5,1232.5l52.7,28.7l26.8-66.3l13.8,3.5c0,0-8.9,77-23.3,91.1c-14.3,14.1-87.5-17-87.5-17
                C1159.1,1261.1,1175.4,1224.7,1194.5,1232.5z"/>
            <g>
                <path className="st34" d="M1274.4,1204.2c0,0-9.6-12.1-10-16.1c-0.4-3.9,12-12.8,13.2-11.3c1.2,1.5,3.8,5.2-4.6,10.2
                    C1280.7,1190.9,1290.7,1200.1,1274.4,1204.2z"/>
                <g>
                    <path className="st34" d="M1273.8,1205c0,0-4.7-12.1-4.1-17.6c0.8-7.4,7.4-14,7.4-14s17.2,7,18.2,8.8c1,1.9-7.2,17.4-7.2,17.4
                        S1281.8,1210.4,1273.8,1205z"/>
                    <path className="st34" d="M1270.8,1188.3c0,0-5.5-31.9-0.3-32.4c2.4-0.2,7.7,30.2,7.7,30.2L1270.8,1188.3z"/>
                    <path className="st35" d="M1271.7,1200.7c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.2,0-0.2c-2.6-3.6-7-10.1-7.2-12.4c-0.3-3,7.7-9.4,11.4-11
                        c1.1-0.5,1.4-0.3,1.4-0.2c0.8,1,1.5,2.2,1.2,3.8c-0.4,2-2.3,4.1-5.7,6.2l-0.3,0.2l0.3,0.1c3.2,1.6,7.2,4.4,9.1,7.4
                        c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.1-0.2,0.1-0.2c-1.8-3-5.8-5.8-8.9-7.4c3.4-2.1,5.3-4.2,5.6-6.3c0.4-2-0.8-3.4-1.3-4.1
                        c-0.3-0.3-0.9-0.3-1.8,0.1c-3.8,1.6-12,8-11.7,11.3c0.2,2.4,4.7,8.9,7.3,12.5C1271.6,1200.7,1271.6,1200.7,1271.7,1200.7z"/>
                    <path className="st35" d="M1286.8,1190c0.1,0,0.1,0,0.2,0l0.1,0l0-0.1c1.1-3.7,5.5-6.3,5.5-6.4c0.1,0,0.1-0.2,0.1-0.2
                        c0-0.1-0.2-0.1-0.2-0.1c-0.2,0.1-4.4,2.7-5.6,6.4c0,0,0,0,0,0c-3.2,0-9.4-5.8-9.5-5.9c-0.1-0.1-0.2-0.1-0.2,0
                        c-0.1,0.1-0.1,0.2,0,0.2C1277.3,1184.3,1283.4,1190,1286.8,1190z"/>
                </g>
            </g>
            <path className="st36" d="M1184.9,1229.2c0,0,11,1.5,37.5,18.4c-2.9,11.4-11.8,41.2-11.8,41.2s-40.5-12.5-46-26.1
                C1159.1,1249,1165,1223.7,1184.9,1229.2z"/>
            <g>
                <g>
                    <path className="st37" d="M1055.6,1693.1h41.8c0.9-0.5,1.5-1,1.9-1.7c2.2-31.1,5.3-46.1,5.3-46.1l-29.1-1.2l-6.6,23.1
                        C1059.1,1674,1047.4,1688.3,1055.6,1693.1z"/>
                    <path className="st37" d="M1100.6,1693.7H1051c-1.3,0-0.8-6.9,0.4-6.9l49.3,0.3C1102,1687.1,1101.9,1693.7,1100.6,1693.7z"/>
                </g>
                <path className="st37" d="M1117.9,1361.8c-14.6,22.4-25.2,130.8-45.3,293.9l29.7,1.6c8.9-43.4,19-65.5,29.2-128.1
                    c24.5-55.3,37.2-107.7,43.7-161.6C1181.5,1326.9,1131.6,1305.5,1117.9,1361.8z"/>
            </g>
            <g>
                <path className="st37" d="M1153,1369.5c2.1,79.3,16.2,146.7,16.2,146.7c3.2,66.4,7.6,64.2,13.8,141.6l26.8-0.4
                    c2.3-33.2,20.2-187.8,6.3-287.1C1209.4,1325.7,1148.7,1310.9,1153,1369.5z"/>
                <path className="st37" d="M1183,1657.8c-2.4,12.8-4.1,22.8-3.1,34.4l65,1.3c0,0,6.4-6.3-1.7-15.7c-8.1-9.4-23.4-4-33.4-20.4
                    L1183,1657.8z"/>
                <path className="st37" d="M1247.9,1693.7h-69.1c-1.3,0-0.9-6.9,0.3-6.9l68.8,0.3C1249.2,1687.1,1249.2,1693.7,1247.9,1693.7z"/>
            </g>
            <path className="st36" d="M1214.1,1365.7c0,13.7-97.4,9.7-97.4-4c-5.6-119.7-34.5-107.5-27.1-119.5c12.8-20.8,77.2-19.6,100.5-10.7
                c9.2,3.5,21.6,18,25.6,41c2,11.8-6.7,25.6-8.1,35.2C1204.3,1330,1213.5,1362.6,1214.1,1365.7z"/>
            <path className="st34" d="M1153.4,1241c-16.4,2.4-26.7-9.1-29.1-13.9l1.2-33.3l18.9-1.8c5.5,0.5,10.9,1.8,15.6,3.6l4.2,21.9l0.9,4.8
                C1167.3,1226.9,1169.8,1238.6,1153.4,1241z"/>
            <path className="st35" d="M1131.8,1201.3c3,4.6,15.3,16.2,32.4,16.2l-3.7-19L1131.8,1201.3z"/>
            <g>
                <g>
                    <path className="st34" d="M1173.5,1157.5c1.5,6.6,4.5,21,4,27.6c-0.8,10.1-10.2,21.3-18.4,22.8c-9.5,1-22.3-1.9-29.3-7.9
                        c-3.9-4.5-11.4-15.3-14.8-24.8c-7.2-19.7,6.7-36.9,24.3-40.9C1157,1130.3,1168.9,1137.1,1173.5,1157.5z"/>
                    <path className="st38" d="M1120.9,1188.2c-3.7-6.5,1.2-9.1,6.6-4.1c-0.9-4.9-6.5-19.2-1.6-24c5.6-5.5,30.1-3.1,43.3-16.8
                        c0,0-9.8-21.5-42.1-8.8C1090.7,1153.9,1120.9,1188.2,1120.9,1188.2z"/>
                    
                        <ellipse transform="matrix(0.7691 -0.6391 0.6391 0.7691 -499.2197 990.0154)" className="st34" cx="1120.7" cy="1186" rx="6.2" ry="7.9"/>
                </g>
            </g>
            <path className="st39" d="M1096.3,1235.5c11-1.3,21.7,51.6,14.2,66.1c0,1.1,0,2.2-0.1,3.2c-0.1,4.1-0.2,8.4-0.3,12.7
                c-0.1,1.9-0.1,3.8-0.2,5.7c-0.1,4.2-0.3,8.5-0.5,12.7c-1.3,32.7-3.4,63.2-3.4,63.2h114.1c-0.9-22.4-4.2-45.4-6.4-63.2
                c-0.6-4.6-1.1-8.9-1.5-12.7c-0.2-2.1-0.4-4-0.5-5.7c-0.3-4.2-0.3-7.6,0.1-9.9c0.1-0.8,0.2-1.7,0.3-2.8
                c0.6-16.7-10.7-63.5-18.8-72.2c-11.4-5.3-23.8-5.7-23.8-5.7c0.8,23.8-8.7,45.1-8.7,45.1s-24.2-20.7-37.3-45.4
                C1111.9,1228.5,1101.4,1230.8,1096.3,1235.5z"/>
            <path className="st3" d="M1211.7,1317.5c-0.3-4.2-0.3-7.6,0.1-9.9c0.1-0.8,0.2-1.7,0.3-2.8h-101.7c-0.1,4.1-0.2,8.4-0.3,12.7H1211.7z"
                />
            <path className="st3" d="M1109.4,1335.9h104.3c-0.6-4.6-1.1-8.9-1.5-12.7h-102.4C1109.7,1327.5,1109.6,1331.7,1109.4,1335.9z"/>
            <path className="st40" d="M1109.5,1169.9c-1.4-10.3-3-36.7,20.4-43.5c20.8-6,35.7,6.5,42.7,14.7c3,0.5,11.3-1.8,12.7-0.1
                c0.3,1,0,3-8.3,6.3c0,0.1,0,0.1,0,0.1c-30.2,6.6-55.9,15-66.8,29.5c0,0-2,0.9-3.1,0.9C1106.1,1177.8,1108.6,1173.8,1109.5,1169.9z
                "/>
            <path className="st41" d="M1151,1147.9c0,0-9.5-22.4-32-16.4l-0.4-1c0,0,5.7-3.6,12.1-5.5c7.1-1.1,23.6,1.6,33.1,18.9
                C1158.9,1146.5,1151,1147.9,1151,1147.9z"/>
            <g>
                <path className="st42" d="M1109.2,1295l-15.9,35.4l64.8,32.3l-1.2,13.6c0,0-85.5-10.9-93-29.6c-7.5-18.8,22.9-99.2,22.9-99.2
                    C1095.3,1227,1106.8,1277.9,1109.2,1295z"/>
                <path className="st42" d="M1157.8,1363.6l31.7,0.8c0,0-9.7,15.6-31.9,12.8C1128.5,1373.4,1140,1362.4,1157.8,1363.6z"/>
                <g>
                    <path className="st43" d="M1265.1,1364v1.6c0,0.1,0,0.2,0,0.2c-0.2,1-1.4,1.9-2.9,1.9h-135.4c-1.5,0-2.8-0.8-2.9-1.9
                        c0-0.1,0-0.2,0-0.2v-1.6H1265.1z"/>
                    <path className="st14" d="M1265.1,1365.8c-0.2,1-1.4,1.9-2.9,1.9h-135.4c-1.5,0-2.8-0.8-2.9-1.9H1265.1z"/>
                    <g>
                        <polygon className="st14" points="1280.8,1271.4 1281.6,1272.1 1281.2,1272.7 1280.2,1271.3 					"/>
                        <path className="st43" d="M1263.2,1363.7h-109.8c-1.9,0-3.1-1.5-2.8-3.4l14.5-86.4c0.3-1.9,2.1-3.4,4-3.4h109.8
                            c1.9,0,3.1,1.5,2.8,3.4l-14.5,86.4C1266.8,1362.2,1265,1363.7,1263.2,1363.7z"/>
                    </g>
                    <path className="st14" d="M1264,1364.5h-109.8c-1.9,0-3.1-1.5-2.8-3.4l14.5-86.4c0.3-1.9,2.1-3.4,4-3.4h109.8c1.9,0,3.1,1.5,2.8,3.4
                        l-14.5,86.4C1267.6,1363,1265.8,1364.5,1264,1364.5z"/>
                    
                        <ellipse transform="matrix(0.1144 -0.9934 0.9934 0.1144 -232.9093 2379.2715)" className="st2" cx="1218.1" cy="1320.3" rx="15.1" ry="14.5"/>
                </g>
            </g>
            <path className="st36" d="M1109.3,1300.7l-37-18.7c0,0,10.8-38.3,23-45.6c6,3.6,8.9,11.8,12.1,26.6
                C1110.3,1275.7,1111.6,1288.6,1109.3,1300.7z"/>
        </g>
        <g id="female_02">
            <g>
                
                    <ellipse transform="matrix(0.9219 -0.3874 0.3874 0.9219 -303.7683 851.9854)" className="st44" cx="1961.5" cy="1179.5" rx="20" ry="20"/>
                <g>
                    <path className="st45" d="M2125.5,1196.1l0.2,0.4c0.6,1,1.9,1.3,2.9,0.7l6.6-5.2c4.9-3.8,3-5.7-2-3.1l-7,4.2
                        C2125.2,1193.8,2124.9,1195.1,2125.5,1196.1z"/>
                    <g>
                        <g>
                            <path className="st45" d="M2111.2,1216.9c0,0,21.6-1.5,18.5-22.5c-1.3-8.8-25.3,2.3-25.3,2.3s3.1-14-2.1-12.7
                                c-2.3,9.7-4.4,19-6.3,27.6C2096,1215,2105,1219.7,2111.2,1216.9z"/>
                        </g>
                        <path className="st45" d="M2004.5,1228.4c-8.7-16.5,8.2-36.3,26.1-19.9l36.6,41.7l29.7-40.3c3.6,5.1,5.9,7.5,11.2,7.2
                            c0,0-20,56.7-35.5,60.3C2054.1,1281.7,2011.3,1239.5,2004.5,1228.4z"/>
                    </g>
                    <g>
                        <g>
                            <path d="M2134,1178.3c0.2-2.4-3-4.3-3.4-2.8l-16.1-4.1c-1.1-0.3-2.3,0.5-2.6,1.7l-11,42.3c-0.3,1.3,0.3,2.6,1.5,2.9l16.4,4.4
                                c0,0.2,0.2,0.5,0.4,0.7c1.9,0.5,3-0.2,3.6-1.1c0.2-0.3,0.3-0.6,0.4-0.9C2123.3,1220.8,2134,1178.8,2134,1178.3z"/>
                            <path d="M2119.1,1223.3l-18.8-5c-1.2-0.3-1.8-1.6-1.5-2.9l11.3-43.5c0.3-1.3,1.5-2,2.7-1.8l18.5,4.7c1.2,0.3,1.8,1.6,1.5,2.8
                                l-11,43.8C2121.5,1222.8,2120.3,1223.6,2119.1,1223.3z"/>
                            <path d="M2119.4,1223.1c-0.1,0-0.2,0-0.2,0l-18.8-5c-1-0.3-1.6-1.4-1.3-2.6l11.3-43.4c0.3-1.1,1.3-1.8,2.3-1.5l18.5,4.7
                                c0,0,0.1,0,0.1,0c0.5,0.1,0.8,0.5,1.1,0.9c0.2,0.5,0.3,1,0.2,1.5l-11,43.8C2121.3,1222.5,2120.4,1223.2,2119.4,1223.1z"/>
                            <polygon className="st1" points="2131.8,1177.7 2120.9,1221.3 2099.7,1215.7 2111,1172.3 						"/>
                        </g>
                        <g>
                            <path className="st45" d="M2121.1,1204.2L2121.1,1204.2c0.7,1.1,2.1,1.5,3.2,0.8l9.2-5.5c1.1-0.7,1.5-2.1,0.8-3.2l0,0
                                c-0.7-1.1-2.1-1.5-3.2-0.8l-9.2,5.5C2120.8,1201.6,2120.5,1203.1,2121.1,1204.2z"/>
                            <path className="st45" d="M2119.2,1209.9L2119.2,1209.9c0.7,1.1,2.1,1.5,3.2,0.8l9.2-5.5c1.1-0.7,1.5-2.1,0.8-3.2l0,0
                                c-0.7-1.1-2.1-1.5-3.2-0.8l-9.2,5.5C2118.9,1207.4,2118.5,1208.8,2119.2,1209.9z"/>
                            <path className="st45" d="M2121.2,1213L2121.2,1213c0.5,0.9,1.7,1.2,2.5,0.6l7.3-4.4c0.9-0.5,1.2-1.7,0.6-2.5l0,0
                                c-0.5-0.9-1.7-1.2-2.5-0.6l-7.3,4.4C2121,1211,2120.7,1212.1,2121.2,1213z"/>
                        </g>
                    </g>
                </g>
                <g>
                    <path className="st36" d="M1949.7,1269.1c2.7,9.5,6.1,19.1,9.3,27.6c4.9,13,9.4,33.9,10.6,38.8l73,1.7c1-37,1.1-37,1.8-49
                        c0.5-7.8,0.9-16.3,1.2-24.2c0.4-12.4,0.2-23-1.1-26.5c-3.9-9.9-16.3-23.5-26.8-33.5c-10.6-10-74.5,5.8-72,23.9
                        C1942.8,1238.7,1945.4,1253.8,1949.7,1269.1z"/>
                    <g>
                        <path className="st45" d="M2003.1,1200.9c0,0,15.2,15.9,4.2,30.7c-28-3.5-40.7-21.6-41.2-22.3
                            C1982,1192.9,2003.1,1200.9,2003.1,1200.9z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path className="st46" d="M2004.5,1372.5c-15,94.4-48.2,279.9-48.2,279.9l-24.4-1.4l11.9-273.2
                            C1948.2,1338.8,2005,1316.1,2004.5,1372.5z"/>
                        <g>
                            <path className="st46" d="M1918.4,1698c-5.6-4.4,6.5-9.9,6.5-9.9l5.3-40.2l0.1-1.1l25.7,0l1.8,16.1l-15.2,35.1L1918.4,1698z"/>
                            <polygon className="st46" points="1952.2,1698 1941.7,1660.8 1957.8,1662.8 1955.9,1698 						"/>
                        </g>
                    </g>
                    <g>
                        <path className="st46" d="M1995.4,1371.7c4.7,95.5,26.5,284.1,26.5,284.1l24.4-1.3l10.4-269.1
                            C2056.3,1335.5,2001,1315.6,1995.4,1371.7z"/>
                        <g>
                            <path className="st46" d="M2059.8,1700.2c5.6-4.4-6.5-9.9-6.5-9.9l-5.2-40.2l-0.1-1.1l-25.7-0.1l-1.8,16.1l15.1,35.1
                                L2059.8,1700.2z"/>
                            <polygon className="st46" points="2026,1700.1 2036.6,1663 2020.5,1665 2022.3,1700.1 						"/>
                        </g>
                    </g>
                    <path className="st46" d="M1968,1324.3c0,0-23.5,15.7-24.5,61.2c30,5.3,112.3,8.7,112.3,8.7c1.8-27.7-2.6-53.1-13.7-69.1
                        C1981.4,1322.1,1968,1324.3,1968,1324.3z"/>
                </g>
                <g>
                    <g>
                        <path className="st45" d="M1992.2,1169.4l1.2,6.7l4.3,23.9l1.5,8.1c-0.5,8.8-23.4,10-29.9,3.9c-0.1-1.6-0.3-5.3-0.6-9.7
                            c-0.5-8.3-1.2-19.3-1.5-24l-0.1-1.2l9.9-3C1982.6,1172.4,1988.7,1170.5,1992.2,1169.4z"/>
                        <path className="st47" d="M1993.4,1176.1l4.5,25c-21.7,1.4-30.1-17.4-30.1-17.4l0.2-1.9c0,0,10.5-3.9,19.9-5.8
                            C1989.6,1175.6,1991.5,1175.7,1993.4,1176.1z"/>
                    </g>
                    <path className="st45" d="M2014.4,1146.5c7.5,18.8,0.1,38.2-9.2,42.4c-15.1,6.8-39.1-1.3-46.5-20.1s-1.1-39,14.3-45.1
                        S2006.9,1127.7,2014.4,1146.5z"/>
                    <path className="st44" d="M1997.7,1124.9c0,0-28.6,17-30.4,62.9c-23.7-18.9-29.5-38.6-17.3-56.3
                        C1961,1115.6,1983.8,1114.5,1997.7,1124.9z"/>
                    <path className="st44" d="M1995.6,1126.4c0,0,10.3,1.9,18.5,19.6c-4.3-15.9-22.5-43.3-53.3-24.2S1995.6,1126.4,1995.6,1126.4z"/>
                    <path className="st45" d="M1975.6,1165.4c1.9,3.4,0.6,7.7-2.8,9.6c-3.4,1.9-7.7,0.6-9.6-2.8c-1.9-3.4-0.6-7.7,2.8-9.6
                        C1969.4,1160.7,1973.7,1161.9,1975.6,1165.4z"/>
                </g>
                <g>
                    <path className="st40" d="M1942.8,1163.8c-3.4-10.6-10-38.2,13.5-49.8c20.9-10.2,38.9,0.2,47.9,7.6c3.3-0.1,11.6-4,13.4-2.4
                        c0.5,1,0.6,3.1-7.6,8.2c0,0,0.1,0.1,0.1,0.1c-30.7,12.6-56.3,26.3-65.2,43.6c0,0-1.9,1.3-3.1,1.5
                        C1940.7,1172.8,1942.6,1168.1,1942.8,1163.8z"/>
                    <path className="st41" d="M1982.7,1132.8c0,0-14.2-21.9-36.9-11.4l-0.6-0.9c0,0,5.3-4.8,11.8-8.1c7.3-2.5,25.3-2.7,38.5,13.9
                        C1990.7,1129.8,1982.7,1132.8,1982.7,1132.8z"/>
                </g>
                <g>
                    <path className="st45" d="M1999.9,1288.2c13,4.9,51.6-63,58.1-75.8c7-18.5-52.8,44.1-54.1,42.6c-5.8-8.2-27.5-24.8-33.3-30.5
                        c-11-10.8-26.4,1.6-21.4,12.2C1949.2,1236.7,1979.1,1282.7,1999.9,1288.2z"/>
                    <path className="st45" d="M2051.8,1222l-7.3-1.7l6.3-20.2c0,0,2.4-1,5.7-1c3.3,0,6.5,1.7,6.5,1.7s2.3,7.4,2.5,8.6
                        C2066.7,1218.3,2051.8,1222,2051.8,1222z"/>
                    <path className="st45" d="M2048.6,1207.1c0-0.4,7-23.2,7-23.2s2,0.9,2.1,4.5c0.1,3.6-1.8,18.7-1.8,18.6L2048.6,1207.1z"/>
                    <path className="st35" d="M2063.6,1203.4c-3-1.4-5.3-1-5.3-1l-0.1-0.4c0.1,0,2.4-0.4,5.6,1.1L2063.6,1203.4z"/>
                    <path className="st35" d="M2064.4,1206.2c-3-1.4-5.3-1-5.3-1l-0.1-0.4c0.1,0,2.4-0.4,5.6,1.1L2064.4,1206.2z"/>
                    <path className="st35" d="M2065.2,1209.7c-3-1.4-5.3-1-5.3-1l-0.1-0.4c0.1,0,2.4-0.4,5.6,1.1L2065.2,1209.7z"/>
                </g>
            </g>
            <g>
                <path className="st13" d="M2125.6,1140.1c-4,3.5-10,3.2-13.6-0.8c-3.5-3.9-3.2-9.9,0.7-13.4c4-3.5,10-3.2,13.6,0.8
                    C2129.9,1130.6,2129.6,1136.6,2125.6,1140.1z"/>
                <g>
                    <path className="signaBar1" d="M2157.1,1102.9L2157.1,1102.9c1.2-1.2,1.2-3.1,0-4.3c-21.1-21.1-53.8-22.2-76.1-2.5
                        c-1.2,1.1-1.3,3-0.2,4.3l0,0c1.1,1.3,3.1,1.4,4.4,0.3c19.9-17.3,48.8-16.3,67.6,2.2C2153.9,1104.1,2155.9,1104.1,2157.1,1102.9z
                        "/>
                    <path className="signaBar1" d="M2148,1112L2148,1112c1.2-1.2,1.2-3.2,0-4.4c-16.4-15.8-41.2-16.6-58.5-1.9c-1.3,1.1-1.5,3.1-0.3,4.3l0,0
                        c1.1,1.3,3,1.4,4.3,0.4c14.9-12.4,36.1-11.7,50.2,1.6C2144.9,1113.2,2146.9,1113.2,2148,1112z"/>
                    <path className="signaBar1" d="M2139.3,1120.8L2139.3,1120.8c1.2-1.3,1.2-3.3-0.1-4.4c-11.8-10.7-29-11.2-41.4-1.4
                        c-1.4,1.1-1.5,3.1-0.4,4.4l0,0c1.1,1.2,2.9,1.4,4.2,0.4c10.1-7.7,23.8-7.2,33.4,1.1C2136.3,1122,2138.1,1122,2139.3,1120.8z"/>
                </g>
            </g>
        </g>
        <g id="male_03">
            <g>
                <g>
                    <path className="st48" d="M4165.4,1696.7c-1.7-3.6-4-14.4,1-17c7.1-3.6,35.9,3,35.9,3s1.6,9.7,2.4,14H4165.4z"/>
                    <path className="st49" d="M4190.1,1556.7c0,1.4,12.3,125.2,12.3,125.2c-0.9,6.4-20.2,2.1-25.1-0.5l-38.2-124.2L4190.1,1556.7z"/>
                </g>
                <path className="st50" d="M4217.8,1369.7l-17.7-31.3c-3.5-11.8-34.2-2.6-30.7,9.2l4.6,35.2L4217.8,1369.7z"/>
                <g>
                    <path className="st51" d="M4120.8,1697c-0.5-2.9-0.6-10.9,4.3-15.1c6.9-5.8,17.1-1.4,17.1-1.4l27.2-0.3c0,0,2.9,10.9,2.1,16.8
                        H4120.8z"/>
                    <path className="st49" d="M4260.1,1509.1c0,0,21.4,62-78.3,58.4c-56.3-2.1-54.9-8.5-54.9-8.5l39.5,122.1c-2.3,4.7-21.4,4.2-26.9,0.5
                        c0,0-70.3-114.1-66.7-140.4c3.6-26.4,49.9-32,77.6-32.8S4260.1,1509.1,4260.1,1509.1z"/>
                </g>
                <path className="st52" d="M4146.8,1510c34.8,43.1,130.4,38.4,129.1,2.5c0,0-2-87.7-31.2-133.2c-10.3-16.1-23-23.3-41.3-23
                    c-18.8,0.3-38.2,16.5-43.9,28.9c-1.4,3.1-2.9,12.6-4.4,25.1c-3,25.5-5.9,63.5-7.3,84.5C4147.1,1504.1,4146.8,1510,4146.8,1510z"
                    />
                <path className="st53" d="M4147.8,1494.8c28.1-17.3,40.7-83.8,40.7-83.8l-33.4-0.6C4152.1,1435.8,4149.3,1473.8,4147.8,1494.8z"/>
                <path className="st50" d="M4156.6,1300.6c-6.9,15.7-3.4,39.4,8.1,45.2c16.2,7.1,42,0.4,50.7-19.4c8.7-19.8,2.6-41.6-13.6-48.8
                    C4185.5,1270.5,4165.4,1280.8,4156.6,1300.6z"/>
                <g>
                    <path className="st50" d="M4096.5,1444.4c0,0-6.3-5.7-16.3-6.5c-10-0.8-18.7,18.2-18.7,18.2h34.5L4096.5,1444.4z"/>
                    <path className="st50" d="M4077.5,1438.1c0,0-15.7,4.4-17.9,5c-1,4.1,9.8,2.4,14.8,0.5C4079.9,1441.6,4077.5,1438.1,4077.5,1438.1z"
                        />
                </g>
                <path className="st52" d="M4193.9,1396c0,0-21.3,66.9-48,66.9c-26.7,0-57.3-6.6-57.3-6.6c-6.7-0.8-4.1-19.2,0.9-19.9l46.9-5.2
                    c0,0,9.9-41.9,35.3-59.2C4190,1368.5,4199.7,1383,4193.9,1396z"/>
                <path className="st3" d="M4089.7,1436.7l46.5-5.3l0.1-0.3c13.4-42.9,32.4-58,32.6-58.2l-0.5-0.7c-0.2,0.1-19.3,15.4-32.8,58.3
                    l-46,5.3L4089.7,1436.7z"/>
                <path className="st54" d="M4197.7,1350.6c4.7-0.7,12.1-9.2,19.9-27.2c9-20.8,3-38.7-16.3-49.4c-19-10.6-40.8,5.4-41.6,24
                    C4161.9,1328.3,4181.9,1352.8,4197.7,1350.6z"/>
                <path className="st50" d="M4161,1319.6c-0.5,4,1,7.5,3.4,7.8c2.4,0.3,4.8-2.7,5.3-6.7c0.5-4-1-7.5-3.4-7.8
                    C4163.9,1312.6,4161.5,1315.6,4161,1319.6z"/>
                <path className="st54" d="M4196,1271.6c0,0-8.5,36.4-47.4,23.6C4164,1262,4196,1271.6,4196,1271.6z"/>
                <g>
                    <path className="st39" d="M4218.1,1360.7l-6.1-10.4c-1.7-5.9-44.5,6.2-42.8,12.1l-0.5,15L4218.1,1360.7z"/>
                    <path className="st55" d="M4169.3,1372.7C4169.4,1372.7,4169.4,1372.7,4169.3,1372.7c25.5-12.8,46.1-14.7,46.3-14.7
                        c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-20.8,1.9-46.4,14.7c-0.1,0.1-0.1,0.2-0.1,0.3
                        C4169.2,1372.6,4169.3,1372.7,4169.3,1372.7z"/>
                </g>
                <path className="st3" d="M4266.9,1444l1.6-0.3c-0.1-0.5-8.9-48.7-29-72.7c-10.8-11.2-21.1-13.8-23.3-14.2l-3.4-7c0,0,0-0.1-0.1-0.1
                    c-0.1-0.1-1.6-2.1-6-1.9c-0.6-0.9-0.9-1.5-1.2-1.8c2.5-3.3,16-21.5,17-36.5c0.7-10.1-3.5-21.2-10.9-28.9
                    c-7.2-7.5-17-11.3-28.3-11l0,1.6c10.8-0.3,20.2,3.4,27.1,10.6c7,7.2,11.1,18.1,10.4,27.6c-1,15.7-16.9,35.9-17.1,36.1
                    c-0.2,0.3-0.2,0.8,0.1,1.1l0,0c0,0,0.4,0.4,1.6,2.5c0.2,0.3,0.4,0.4,0.7,0.4c3.5-0.3,4.8,1,5,1.2l3.5,7.2
                    c0.1,0.2,0.3,0.4,0.6,0.4c0.1,0,11.2,1.8,22.7,13.8C4258.1,1395.7,4266.8,1443.5,4266.9,1444z"/>
            </g>
            <g>
                <path className="st56" d="M4126.8,1559c10.7-0.1,50.5,10.4,56.4,0c5.9-10.4,18.8-138.2,18.8-138.2c1-7.2,7.1-12.5,14.3-12.5h129.8
                    c9.8,0,17.3,8.6,16,18.3l-18.5,135.3c-2.3,16.7-16.6,29.2-33.5,29.2h-49.1v0.1h-151.5v-15
                    C4109.6,1566.8,4116.1,1559.1,4126.8,1559z"/>
                <path className="st57" d="M4175.4,1591.2h134.9c16.9,0,31.2-12.5,33.5-29.2l18.5-135.3c1.3-9.7-6.2-18.3-16-18.3h-108
                    c-7.2,0-13.4,5.3-14.3,12.5c-7.8,69-13.6,124.3-22,157.1C4198.5,1591.3,4182.7,1591.2,4175.4,1591.2z"/>
                <g>
                    <path className="st49" d="M4311,1591.2c0,0.1,0.1,0.2,0.1,0.3c0,6.6-41.3,11.9-92.3,11.9c-51,0-92.3-5.3-92.3-11.9
                        c0-0.1,0.1-0.2,0.1-0.3H4311z"/>
                    <path className="st49" d="M4147.5,1673.5c0-0.1-0.1-0.2-0.1-0.3c0-6.6,32.6-11.9,72.7-11.9s72.7,5.3,72.7,11.9
                        c0,0.1-0.1,0.2-0.1,0.3H4147.5z"/>
                    
                        <rect x="4210.3" y="1600.2" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 8436.6709 3268.3186)" className="st49" width="16.1" height="67.9"/>
                    
                        <rect x="4162.6" y="1672" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 8333.3447 3359.2449)" className="st49" width="8.2" height="15.3"/>
                    
                        <rect x="4214.7" y="1672" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 8437.6201 3359.2449)" className="st49" width="8.2" height="15.3"/>
                    
                        <rect x="4277.5" y="1672" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 8563.1367 3359.2449)" className="st49" width="8.2" height="15.3"/>
                    <g>
                        <g>
                            <path className="st49" d="M4273.3,1687.2c0,5.5,2.8,9.9,6.3,9.9c3.5,0,6.3-4.4,6.3-9.9s-2.8-9.9-6.3-9.9
                                C4276.1,1677.3,4273.3,1681.7,4273.3,1687.2z"/>
                            <ellipse className="st49" cx="4273.5" cy="1687.2" rx="6.3" ry="9.9"/>
                            <path className="st49" d="M4271.1,1696.3c2.6,2,9.9,1.1,10.9,0.1l0-18.2c-2-1.7-8.8-1.6-10.9-0.1L4271.1,1696.3z"/>
                            <path className="st58" d="M4268.6,1686.9c0,3.7,1.9,6.8,4.3,6.8c2.4,0,4.3-3,4.3-6.8c0-3.7-1.9-6.8-4.3-6.8
                                C4270.5,1680.2,4268.6,1683.2,4268.6,1686.9z"/>
                        </g>
                        <path className="st49" d="M4270.7,1687.1c0,2.3,1.2,4.1,2.6,4.1c1.4,0,2.6-1.8,2.6-4.1c0-2.3-1.2-4.1-2.6-4.1
                            C4271.9,1683,4270.7,1684.9,4270.7,1687.1z"/>
                    </g>
                    <g>
                        <g>
                            <ellipse className="st49" cx="4220" cy="1687.2" rx="6.3" ry="9.9"/>
                            <path className="st49" d="M4232.4,1687.2c0,5.5-2.8,9.9-6.3,9.9c-3.5,0-6.3-4.4-6.3-9.9s2.8-9.9,6.3-9.9
                                C4229.6,1677.3,4232.4,1681.7,4232.4,1687.2z"/>
                            <path className="st49" d="M4228.5,1696.3c-2.6,2-9.9,1.1-10.9,0.1l0-18.2c2-1.7,8.8-1.6,10.9-0.1L4228.5,1696.3z"/>
                            <path className="st58" d="M4231,1686.9c0,3.7-1.9,6.8-4.3,6.8s-4.3-3-4.3-6.8c0-3.7,1.9-6.8,4.3-6.8S4231,1683.2,4231,1686.9z"/>
                        </g>
                        <path className="st49" d="M4228.9,1687.1c0,2.3-1.2,4.1-2.6,4.1c-1.4,0-2.6-1.8-2.6-4.1c0-2.3,1.2-4.1,2.6-4.1
                            C4227.7,1683,4228.9,1684.9,4228.9,1687.1z"/>
                    </g>
                    <g>
                        <g>
                            <ellipse className="st49" cx="4166.9" cy="1687.2" rx="6.3" ry="9.9"/>
                            <ellipse className="st49" cx="4173" cy="1687.2" rx="6.3" ry="9.9"/>
                            <path className="st49" d="M4175.4,1696.3c-2.6,2-9.9,1.1-10.9,0.1l0-18.2c2-1.7,8.8-1.6,10.9-0.1L4175.4,1696.3z"/>
                            <path className="st58" d="M4177.9,1686.9c0,3.7-1.9,6.8-4.3,6.8c-2.4,0-4.3-3-4.3-6.8c0-3.7,1.9-6.8,4.3-6.8
                                C4176,1680.2,4177.9,1683.2,4177.9,1686.9z"/>
                        </g>
                        <path className="st49" d="M4175.8,1687.1c0,2.3-1.2,4.1-2.6,4.1s-2.6-1.8-2.6-4.1c0-2.3,1.2-4.1,2.6-4.1
                            S4175.8,1684.9,4175.8,1687.1z"/>
                    </g>
                </g>
            </g>
        </g>
        <g id="male_02_1_">
            <g>
                <path className="st39" d="M3244,1474c-13.1,13.3-24.6,26.4-35.6,50.7c-3.1-7.9-2.3-6.2-9.7-23.6c-3.4-8.2-25.3-3.6-25.8,3.6
                    c0,0-3.9,50.2,19.2,69.9c23.2,19.8,88.5-62.7,88.5-62.7C3301.5,1483.4,3266.5,1456.2,3244,1474z"/>
                <g>
                    <path className="st59" d="M3412.2,1651c-24.6,48-204.2,30.9-235.2,4.8c-22.8-33.5,59.9-49.9,61.7-50c40.7-1.1,96.7,2.5,117.4,2.5
                        C3404.2,1615.7,3417.6,1632.7,3412.2,1651z"/>
                    <path className="st60" d="M3238.4,1630.8c33.4,7.9,63.1,10.5,99.5,4.9c0,0-55.8,24.7-56.1,25.6
                        C3281.4,1662.3,3238.4,1630.8,3238.4,1630.8z"/>
                    <path className="st59" d="M3407.9,1656.6c-36,39.8-189.5,36-160-0.2c26.8-3.4,100.4-21.3,106.9-24.8
                        C3377.5,1613.2,3422.5,1635,3407.9,1656.6z"/>
                    <g>
                        <g>
                            <path className="st61" d="M3384.8,1685c-1.8-8-11-13.1-20.9-16.3c-4.8,5.6-11.3,16.7-11.8,33.2
                                C3374.1,1706.8,3387.9,1698.8,3384.8,1685z"/>
                            <path className="st1" d="M3336.1,1665.1c0,0-9.2-1.6-11.9,9.4c-0.9,3.7-3.6,11.2,5.4,17c8.9,4.9,15.1,8.7,22.5,10.4
                                c0.4-16.5,7-27.6,11.8-33.2C3351.1,1664.5,3336.1,1665.1,3336.1,1665.1z"/>
                        </g>
                        <path className="st59" d="M3333.3,1666.4c17.5,4.2,8.5,25.8-0.5,24.5c9.8,1.4-174,1.4-156.1-54.2c0.1,0,0.1,0,0.1,0
                            c6.9-11.7,28.3-21.8,45.6-14.2C3262.6,1643.9,3300.5,1658.7,3333.3,1666.4z"/>
                        <g>
                            <path className="st1" d="M3339.8,1689.7c0,0-15.6-1.2-10.2-13.8s16.4-5.2,16.4-5.2S3351.9,1683.5,3339.8,1689.7z"/>
                            <path className="st3" d="M3340.6,1690.8c-0.4,0-0.7-0.3-0.7-0.7c-0.4-11.5,6.7-18.9,7-19.2c0.3-0.3,0.7-0.3,1,0
                                c0.3,0.3,0.3,0.7,0,1c-0.1,0.1-6.9,7.3-6.6,18.2C3341.3,1690.4,3341,1690.8,3340.6,1690.8
                                C3340.6,1690.8,3340.6,1690.8,3340.6,1690.8z"/>
                            <path className="st3" d="M3345,1692.5c-0.4,0-0.7-0.3-0.7-0.7c-0.4-11.5,6.7-18.9,7-19.2c0.3-0.3,0.7-0.3,1,0c0.3,0.3,0.3,0.7,0,1
                                c-0.1,0.1-6.9,7.3-6.6,18.2C3345.7,1692.1,3345.4,1692.5,3345,1692.5C3345,1692.5,3345,1692.5,3345,1692.5z"/>
                            <path className="st3" d="M3349.7,1693.6c-0.4,0-0.7-0.3-0.7-0.7c-0.4-11.5,6.7-18.9,7-19.2c0.3-0.3,0.7-0.3,1,0
                                c0.3,0.3,0.3,0.7,0,1c-0.1,0.1-6.9,7.3-6.6,18.2C3350.4,1693.3,3350.1,1693.6,3349.7,1693.6
                                C3349.7,1693.6,3349.7,1693.6,3349.7,1693.6z"/>
                        </g>
                    </g>
                    <path className="st60" d="M3335.1,1690.2c-3.7,2.1-98.5,6.5-151.2-27.5c-17.2-11.1-8.1-26.9-8.1-26.9S3188.1,1681.8,3335.1,1690.2z"
                        />
                    <path className="st60" d="M3324.5,1665.9c-0.1,0-0.2,0-0.3,0c-68.3-20.2-99.7-41.2-100-41.4c-0.5-0.3-0.6-1-0.3-1.5
                        c0.3-0.5,1-0.6,1.5-0.3c0.3,0.2,31.5,21,99.5,41.1c0.6,0.2,0.9,0.8,0.7,1.3C3325.4,1665.6,3325,1665.9,3324.5,1665.9z"/>
                    <path className="st60" d="M3281.3,1650.7c-0.5,0-1-0.4-1.1-0.9c-0.1-0.6,0.3-1.1,0.9-1.2c0.4-0.1,43.5-7.8,60.2-15
                        c0.5-0.2,1.2,0,1.4,0.6c0.2,0.5,0,1.2-0.6,1.4c-16.9,7.2-58.8,14.8-60.6,15.1C3281.4,1650.7,3281.4,1650.7,3281.3,1650.7z"/>
                </g>
                <path className="st3" d="M3358.7,1608.3c1.5,11.5-80.3,14.2-122.1-2.4c-6.3-25.9-27.8-143.9,36.6-143.6
                    C3342.9,1462.6,3352.1,1577,3358.7,1608.3z"/>
                <path className="st34" d="M3175.4,1504.3l-1-14.6l15.4-2.3l5.8,13.1C3196.3,1504.4,3176.1,1508.2,3175.4,1504.3z"/>
                <g>
                    <path className="st34" d="M3190.5,1489.6l3.7-23.7c0,0-5.4-1.5-5.9,4.9s-11,10.5-8.3,16.7
                        C3182.7,1493.9,3190.5,1489.6,3190.5,1489.6z"/>
                    <path className="st34" d="M3174.9,1490.3c0,0-11.5-17.6-12.5-27.6c-0.1-3.7,1.5-4.7,1.5-4.7l26.2,25.7c0,0,1.7,5.2-0.3,7.4
                        C3187.8,1493.3,3180.2,1496.6,3174.9,1490.3z"/>
                    <path className="st62" d="M3191.1,1484.1c0,0,0.3,4.2-2.1,3.3c-7-2.1-7.4-12.3-7.4-12.3L3191.1,1484.1z"/>
                    <g>
                        <path className="st63" d="M3201.4,1489.2l-48.8-45.3c0,0-1.7-0.1-2.8,0.6l51.4,47.2C3201.7,1490.6,3201.4,1489.2,3201.4,1489.2z"/>
                        <path d="M3149.9,1444.6c-0.7,0.5-1.2,1.4-0.7,3.1c1.4,4.1,44.4,43.8,48.8,45.3c2.1,0.7,3-0.1,3.3-1.2L3149.9,1444.6z"/>
                    </g>
                    <path className="st34" d="M3162.5,1460.3c0,0,0.5-4.1,7.8,0.9c8.9,6.1,14.9,16.9,12.9,19.7
                        C3180.7,1484.4,3160.6,1468.4,3162.5,1460.3z"/>
                </g>
                <path className="st39" d="M3260,1464.2c-46.2,10.1-33.8,120.6-30.5,144.5c8.7,3.5,37,10.4,48.1,12.2
                    C3255.4,1568.4,3243.7,1469.2,3260,1464.2z"/>
                <path className="st34" d="M3287.2,1465.8c-5.1,25.1-32.7,24.4-33.1,10.4c-0.1-0.4-0.1-0.8-0.1-1.1l-2.2-30.4l-0.3-3.7l2.4-0.5
                    l21.9-4.4l0.8-0.1l0.6,1.9l2.8,7.9L3287.2,1465.8z"/>
                <path className="st39" d="M3286.5,1463.9c-8.7,20.5,2.8,127.4,10.8,159.3c21.6,7.3,54.1-3.4,62.8-14.3
                    C3354,1579.7,3344.1,1477.8,3286.5,1463.9z"/>
                <g>
                    <path className="st39" d="M3284.4,1501.1c-3.4,18.4,3,50.3,8,66.9c13.2,9.7,33.7,4.7,33.7-10.2c0.6-13.5-0.6-28.6-3.9-45.7
                        C3319.1,1473.6,3286,1474,3284.4,1501.1z"/>
                    <g>
                        <path className="st39" d="M3264.1,1525.9c-13.6-4.9-20.6,14.6-10.7,21.4c23.6,15.9,60.2,37.3,66.7,23.7
                            C3329.6,1551,3295.1,1535.7,3264.1,1525.9z"/>
                    </g>
                    <g>
                        <path className="st34" d="M3251.6,1541.2l-10.1-5.3c-6.3-4.9,4.8-15,9.3-13.4l7.8,4.9C3262.6,1531.2,3257,1540.8,3251.6,1541.2z"/>
                        <g>
                            <g>
                                <path className="st34" d="M3242.5,1536.1c-8.2-1.8-5.4-10.1-3.5-19.6C3232.3,1488,3268.6,1536.9,3242.5,1536.1z"/>
                            </g>
                            <path className="st34" d="M3246.1,1512.9c0,0-2.7-4.2-8.6-13.6c-2.7,0.5-4.1,5.2-0.5,9.3
                                C3240.1,1512.2,3244.7,1514.7,3246.1,1512.9z"/>
                            <g>
                                <defs>
                                    <path id="XMLID_1_" d="M3238,1510.4c-6-3.4-10.2,6.4-8.6,14.1c2.4,11.7,11.1,5.9,13.7,2.8
                                        C3247.9,1521.6,3245.3,1514.5,3238,1510.4z"/>
                                </defs>
                            </g>
                        </g>
                    </g>
                    <path className="st3" d="M3285.1,1534.1c-0.3,0-0.6-0.2-0.7-0.6c0-0.3-4.3-26.3,1-43.9c0.1-0.4,0.5-0.6,0.9-0.5
                        c0.4,0.1,0.6,0.5,0.5,0.9c-5.1,17.3-1,43-0.9,43.2c0.1,0.4-0.2,0.8-0.6,0.8C3285.2,1534.1,3285.2,1534.1,3285.1,1534.1z"/>
                    <path className="st3" d="M3310,1574.2c-11.8,0-25.7-7.4-26.5-7.8c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3
                        c0.3,0.1,27.1,14.4,36.9,3.6c8.6-9.4,2.2-50.3,2.1-50.7c-0.1-0.4,0.2-0.8,0.6-0.8c0.4-0.1,0.8,0.2,0.8,0.6
                        c0.3,1.7,6.6,42-2.5,51.9C3319.1,1573,3314.7,1574.2,3310,1574.2z"/>
                    <path className="st3" d="M3300.4,1540.2c-0.1,0-0.2,0-0.3-0.1c-17.2-7.6-38.7-14.6-38.9-14.6c-0.4-0.1-0.6-0.5-0.5-0.9
                        c0.1-0.4,0.5-0.6,0.9-0.5c0.2,0.1,21.8,7,39.1,14.7c0.4,0.2,0.5,0.6,0.4,0.9C3300.9,1540.1,3300.7,1540.2,3300.4,1540.2z"/>
                </g>
                <path className="st64" d="M3254,1475.5c-0.6-6.7-4.3-32-0.1-35.1l21.9-4.4C3280.9,1463.6,3262.2,1477.4,3254,1475.5z"/>
            </g>
            <g>
                <path className="st34" d="M3284.1,1431.1c-1.1,22.6-21.2,40.1-36.1,39.3c-15-0.8-33.3-19.9-32.3-42.5c1.1-22.6,17.2-40.2,36.1-39.3
                    C3270.7,1389.5,3285.1,1408.5,3284.1,1431.1z"/>
                <path className="st44" d="M3263.7,1391.8c-18.9-15.5-31.7-4.7-43.5,7c-8.3,8.2-14.2,14.1-23.3,14.4c14.5,23.8,50.6,13.7,64.1-6.6
                    c-0.5,12.5,12.8,24.5,23.1,24.6C3289,1417.8,3282.9,1388.8,3263.7,1391.8z"/>
                <path className="st34" d="M3288.2,1433c-0.5,4.3-4.4,7.3-8.7,6.8c-4.3-0.5-7.3-4.4-6.8-8.7c0.5-4.3,4.4-7.3,8.7-6.8
                    C3285.7,1424.8,3288.8,1428.7,3288.2,1433z"/>
            </g>
        </g>
        <g id="female_01">
            <g>
                <g>
                    <g>
                        <g>
                            <path className="st52" d="M2950.1,1396.2l-14.4,8.1l-3.7-6.6c0,0,0,0,0,0l-25.9-46.6l0,0l-13.4-14.2l-25.7-27.1
                                c-13.2-17.4,9.5-38.5,24.8-23.4c15.7,15.5,30,38.5,36.6,50.6c5.2,9.5,15.7,40.8,19.9,53.6l0.2,0.5
                                C2949.5,1394.3,2950.1,1396.2,2950.1,1396.2z"/>
                            <path className="st65" d="M2954.2,1408.2l-6.3-18.8c-2.7,5.1-13.1,8.7-15.9,8.2c0,0,7.5,14.4,9.5,17
                                C2943.4,1417.2,2953.2,1414.5,2954.2,1408.2z"/>
                        </g>
                        <g>
                            <path className="st65" d="M2939.7,1411.4c0,0.1,0,0.3,0,0.4c-0.4,6.5,4.2,10.2,5.4,11.1c0.1,0.1,0.2,0.1,0.3,0.2
                                c-4.5-1.5-6.7,4.5-4.5,6.3c6.3,5.5,16.8,0.4,18.2-3.4c1.3-3.8-4.8-17.8-4.8-17.8C2950.6,1404.4,2940.3,1403.6,2939.7,1411.4z"
                                />
                            <path className="st66" d="M2939.6,1411.8c-0.6,7.3,5.5,11.1,5.6,11.2c3.9,0.4,5.2,0.3,8.9,0.8c-2.2-1.5-6.8-5.2-4.1-11
                                C2946.7,1409.5,2940.7,1411.3,2939.6,1411.8z"/>
                            <path className="st65" d="M2939.6,1411c-1.8,5.9-2.4,14.5-1.5,18.4c0,0,3.7,0.7,4.2-6.3c0.1-1.8,0.9-5.7,2.9-6.8
                                c6-2.9,7.3-6.2,5.6-9.2C2948.6,1403.1,2940.5,1405.9,2939.6,1411z"/>
                            <path className="st65" d="M2954.6,1429.6c-3.3,1.7-11.7,4.7-12.8,3.6s6.1-7.1,10.3-7.5S2956.3,1428.7,2954.6,1429.6z"/>
                        </g>
                    </g>
                    <path className="st67" d="M2906,1351l-13.4-14.2l2.9-28.3C2897.3,1325.2,2900.9,1339.3,2906,1351z"/>
                    <path className="st65" d="M2833.2,1259.3l5.4,21.5l1,4c1.1,5.1,34.7,2.9,32.3-3l-2-4.4l-10.3-22.7L2833.2,1259.3z"/>
                    <g>
                        <g>
                            <path className="st65" d="M2813.6,1227.4c-2.9,23.9,4.9,42.3,25.1,39.3c16.8-2.5,35.7-11.6,36.3-39.6
                                c0.4-17.9-13.9-32.3-30.9-32.2C2827.2,1194.9,2816.1,1206.3,2813.6,1227.4z"/>
                        </g>
                    </g>
                    <path className="st10" d="M2824.4,1668.7c-2-2.1-11.4-2.1-12.3,1c-0.9,3.1,0.8,5.4-3.2,10.3c2.3,0.7,10.4,0.7,12.9,0
                        C2824.3,1679.2,2826.4,1670.8,2824.4,1668.7z"/>
                    <path className="st68" d="M2837.5,1678.4h-18.2c-1.7-77-1.4-160.9-0.8-224c0.2-22.6,0.8-34.1,4.9-47.6c8.3-27.4,47.2-19,50,5.9
                        c1.2,13.8,9.1,40.8-16.4,132.3C2856.7,1605.5,2841.7,1633.3,2837.5,1678.4z"/>
                    <path className="st10" d="M2804.2,1680.8c3.4-0.2,11.3-3.4,12.8-5.7c2.2,2.5,6.8,3.1,12.3,2.3c6.5-1,7.4-4,12-3.6
                        c2,5.5,6.8,17.8,2.1,20.6c0,0-27.4,0-48.2,0C2790.8,1690.6,2790.9,1682.2,2804.2,1680.8z"/>
                    <g>
                        <path className="st10" d="M2914.6,1669.3c-1.8-1.9-10.4-1.9-11.1,0.9s0.7,4.9-2.9,9.4c2.1,0.6,9.4,0.7,11.7,0
                            S2916.4,1671.2,2914.6,1669.3z"/>
                        <path className="st68" d="M2857.8,1411.9c3.7-28.4,54.2-25.5,57.6-0.7c1.2,13.3,6.5,29.1-3,128.2c20,37.8,12.8,96.5,18.8,136.4
                            c0.7,6.1-16.6,7.5-17.9,2.1c-18.3-74.1-44-157.3-53.5-218.9C2856.3,1436.7,2856,1426,2857.8,1411.9z"/>
                        <path className="st10" d="M2896.2,1678.2c3.6-0.2,9-4.9,10.6-7.4c2.4,2.6,6.9,6.2,12.8,5.3c7-1,8-4.3,13-3.9
                            c2.1,5.9,7.3,19.2,2.3,22.2c0,0-28.1,0-50.6,0C2879.6,1690.2,2881.9,1679.6,2896.2,1678.2z"/>
                    </g>
                    <g>
                        <path className="st65" d="M2715.4,1270.7l5.8-7.7c4.4,2.3,11.9,5,19.7,9l-8.5,13.5C2725.4,1279.6,2719.3,1274.3,2715.4,1270.7z"/>
                        <path className="st52" d="M2737.7,1270.5l48.2,13.4l58.2-2.7c14.9-0.9,22.5,51.7-9.5,44.7l-42.6-13.2c0,0-43.3-13.6-59.6-27.2
                            L2737.7,1270.5z"/>
                        <g>
                            <path className="st65" d="M2705.3,1270.7c-2.8-6.1,2.8-14,3.2-14.6l12.5,6.7c6.3,3.1,2.9,11.1-4.1,11.8
                                C2716.8,1274.5,2708.3,1276.2,2705.3,1270.7z"/>
                            <path className="st65" d="M2707.7,1260.8c-12.7-9.3-9.5-11.3-9.5-11.3s4.7,2.7,12.9,8C2715.6,1260.7,2711.1,1262.8,2707.7,1260.8z
                                "/>
                        </g>
                    </g>
                    <path id="XMLID_3_" className="st52" d="M2818.9,1327.3c-3.2-15.5-4.9-30.9,11.1-40.5c4.6-14.9,45.7-23.5,54.3-5.1
                        c14.4,6.4,11.3,34.5,14,78.7c9.9,22.1,14.2,29.3,17.2,50.9c0,0-5.3,16.2-42.6,16.2c-17,0-41.9-1.5-53.3-10.1
                        c1.1-14.7,7.3-28.8,9.8-49.6C2829.3,1367.8,2819.1,1329.1,2818.9,1327.3z"/>
                    <path className="st65" d="M2814.6,1233.8c0,0-7.4,5.1-3.5,8.3c3.9,3.2,6.9,0.8,6.9,0.8L2814.6,1233.8z"/>
                </g>
                <path className="st49" d="M2815.4,1215.6c0,0,9.9,47.9,47,45.9c19-20.3,24.5-60.6-4.9-69.2C2825,1182.8,2815.1,1209.1,2815.4,1215.6z
                    "/>
                <polygon className="st49" points="2812.4,1219.3 2809.8,1235.2 2811.1,1235.6 2813.6,1219.6 			"/>
                <path className="st49" d="M2829.9,1244.9c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.1-0.5,0.4-0.5c1.7-0.3,3.2-2,3.6-4.3c0.4-1.9,0-4.4-2.3-5.7
                    c-2.6-1.5-19.2-5.5-19.4-5.5c-0.2-0.1-0.4-0.3-0.3-0.5c0.1-0.2,0.3-0.4,0.5-0.3c0.7,0.2,16.9,4,19.6,5.6c2.6,1.6,3.1,4.5,2.7,6.7
                    C2833.9,1242.5,2832.1,1244.6,2829.9,1244.9C2830,1244.9,2830,1244.9,2829.9,1244.9z"/>
                <path className="st65" d="M2822.8,1235.1c-1.7,3-1.3,6.6,0.8,7.9c2.1,1.3,5.2-0.1,6.9-3.1s1.3-6.6-0.8-7.9
                    C2827.6,1230.6,2824.5,1232,2822.8,1235.1z"/>
            </g>
            <g>
                <path className="st69" d="M2865.1,1212.8c0,0-5.1-2.4-2.7-8.8c2.4-6.4,14.3-5.3,15.3,1.2c1,6.5-1.7,6.1-1.7,6.1L2865.1,1212.8z"/>
                <path className="st49" d="M2864.4,1208.3c0,0,13.7,65.1,8.7,149.1c0.6,16.8,40.1,15.4,39.5-1.4c0,0-3.3-106.6-38.8-151.6
                    C2873.7,1204.5,2867.7,1201.1,2864.4,1208.3z"/>
            </g>
        </g>
        <g id="male_01_1_">
            <g>
                <polygon points="2446,1672.1 2457.2,1672.1 2590,942.2 2578.9,942.2 			"/>
                <polygon points="2280.3,1672.1 2291.4,1672.1 2424.3,942.2 2413.1,942.2 			"/>
                <polygon points="2400.9,1013.2 2575,1013.2 2577.2,1001.4 2403,1001.4 			"/>
                <polygon points="2386.8,1090.5 2560.9,1090.5 2563.1,1078.7 2389,1078.7 			"/>
                <polygon points="2372.7,1167.8 2546.9,1167.8 2549,1156 2374.9,1156 			"/>
                <polygon points="2358.7,1245.2 2532.8,1245.2 2535,1233.3 2360.8,1233.3 			"/>
                <polygon points="2344.6,1322.5 2518.7,1322.5 2520.9,1310.6 2346.7,1310.6 			"/>
                <polygon points="2330.5,1399.8 2504.7,1399.8 2506.8,1388 2332.7,1388 			"/>
                <polygon points="2316.5,1477.1 2490.6,1477.1 2492.7,1465.3 2318.6,1465.3 			"/>
                <polygon points="2302.4,1554.4 2476.5,1554.4 2478.7,1542.6 2304.5,1542.6 			"/>
                <polygon points="2288.3,1631.7 2462.4,1631.7 2464.6,1619.9 2290.5,1619.9 			"/>
            </g>
            <path className="st3" d="M2348.5,1387.6c-1.4-5.9-1.7-13.1,0.8-23.6l34,0c0,0,35.6,1.9,30.8,23.5H2348.5z"/>
            <path className="st3" d="M2371.6,1387.6c-1.4-5.9-1.7-13.1,0.8-23.6l34,0c0,0,35.6,1.9,30.8,23.5H2371.6z"/>
            <path d="M2349.7,1365.5h37c4.3-28.4,32.9-220.2,35.5-277.3h-68.6l15.9,117C2362.6,1257.1,2353,1319.9,2349.7,1365.5z"/>
            <g>
                <g>
                    <path className="st70" d="M2481.4,899.5c1.1-1.6,6.3-6.1,11.2-7.1c4.4-0.9,9,8.6,8.7,10.4c-0.3,1.8-2.9,7.2-9.2,5.8
                        C2485.8,907.1,2480,904.7,2481.4,899.5z"/>
                    <path className="st71" d="M2479.6,918.8c0,0,0.1-17.2,1.8-19.3c1.7-2.1,6-5.9,9.4-2.9c0.5,15,8.2,5.7,12.3,13.1
                        c-0.4,3.2-8.1,13.6-13.9,14.6C2483.5,925.4,2479.6,918.8,2479.6,918.8z"/>
                    <path className="st71" d="M2484.3,902.4c0,0,0.9-22.2,3.3-24.9s4.3,19.7,2.8,23.2C2488.9,904.3,2485.9,904.9,2484.3,902.4z"/>
                    <path className="st71" d="M2493.5,907.6l5.5-4.1c0,0-2.9-2.8-2.8-6.3c0.1-3.4,2.6-3.5,2.6-3.5s5.8,7.3,5.8,10.9
                        c0,3.7-3.3,9.7-6.8,12.8C2495.9,919.2,2488.7,913.3,2493.5,907.6z"/>
                </g>
                <path className="st52" d="M2496.6,923.6c0,0-8.5,75-33.8,72.3c-4.3-0.5-8.9-1.1-13.5-1.7h0c-5.9-0.9-11.8-1.8-17.2-2.8
                    c-15.2-2.7-27.2-5.4-27.2-5.4s-32.6-7.9-24.1-32.8c8.4-24.9,36.7-12.9,36.7-12.9l43,16.1l19.2-37.6L2496.6,923.6z"/>
                <path className="st52" d="M2475.4,927l4.2-8.3c6.7,0.4,12.9,2,18.2,5.2v10.8C2489.8,929.4,2482.2,926.2,2475.4,927z"/>
            </g>
            <g>
                <polygon className="st71" points="2416.8,960.5 2378.9,947.1 2400.9,899.2 2428.6,911 			"/>
                <g>
                    <path className="st71" d="M2449.7,876.4c5.7,20.5-1.1,44.6-14.4,46.7c-18.2,2.8-44.2-8.2-50-28.8c-5.7-20.5,4.1-41.2,21.8-46.1
                        C2425,843.2,2444,855.9,2449.7,876.4z"/>
                    <path d="M2393.3,915c0,0-16.8-17.3-16.5-41.4c0.3-24.1,14.9-32.7,34.2-34.7c19.2-2.1,32.9,12.3,55.1,8.8
                        c0.2,7.8-9.1,22-20.6,20.5c-12.8,3.4-15.8,11.9-16.8,30.4c0,0-2.7-11.4-8.1-9.7c-6.2,2.1-4.8,10.5-1.5,13.6
                        C2416.1,908.1,2397.7,912.7,2393.3,915z"/>
                </g>
            </g>
            <path d="M2373.1,1365.5h36.9c4.3-29,33.6-229,35.7-281.1h-69.2l12.6,132C2382.3,1268.4,2376.7,1320.3,2373.1,1365.5z"/>
            <path className="st52" d="M2449.2,1095l-97.3-1c0,0,3.6-114.4,7.5-130.3c3.9-15.9,21.7-34,21.7-34l39.9,9.6
                c7.8,14.2,16.6,52,22.3,83.9c0.3,1.5,0.6,3.2,0.8,5.1c1.8,11.9,3.7,29.7,4.5,38.9c0.3,3.4,0.4,5.7,0.3,6
                C2446.5,1080.5,2449.2,1095,2449.2,1095z"/>
            <path className="st72" d="M2448.4,1067c-4.8,0.5-10.9,0.5-18.6-0.7c-25.1-3.7-57.7-77.2-57.7-77.2l52.1,39h19.8
                C2445.8,1040,2447.7,1057.9,2448.4,1067z"/>
            <path className="st52" d="M2381.1,929.7l2.8-7.3c16,3.6,29.8,9.4,42.2,16.8l3.3,18.3C2413.6,944.7,2397.6,933.7,2381.1,929.7z"/>
            <g>
                <path className="st50" d="M2494.1,1033.9c0,0,16.3-3,19.4-13.1c3.1-10-35.4-7.1-32.1,5.4C2483.6,1034.7,2494.1,1033.9,2494.1,1033.9z
                    "/>
                <g>
                    <polygon className="st73" points="2488.9,1030.1 2479.4,997.2 2546.1,977.2 2555.5,1010.1 				"/>
                    <polygon className="st3" points="2487.6,1028.8 2478.1,995.9 2544.7,975.9 2554.2,1008.8 				"/>
                    <polygon className="st74" points="2544.1,988 2548.9,986.6 2552.2,998.5 2547.2,1000 				"/>
                </g>
                <g>
                    <path className="st52" d="M2490.7,1020.3l-52-5.5l-21.5-40.2c0,0-10.4-28.9-34.4-18.2c-24,10.7-7.7,39.9-7.7,39.9s21.2,36.9,42,51.5
                        c20.8,14.6,74.8-13.5,74.8-13.5C2497.3,1033.9,2496,1019.9,2490.7,1020.3z"/>
                    <path className="st72" d="M2487.1,1020.4C2487.1,1020.4,2487.1,1020.4,2487.1,1020.4l-48.5-4.9l-0.1-0.3
                        c-9.8-22.5-19.2-39.4-29.6-53.3c-0.2-0.2-0.1-0.6,0.1-0.8c0.2-0.2,0.6-0.1,0.8,0.1c10.4,13.9,19.8,30.8,29.6,53.2l47.9,4.8
                        c0.3,0,0.5,0.3,0.5,0.6C2487.6,1020.2,2487.4,1020.4,2487.1,1020.4z"/>
                </g>
                <path className="st50" d="M2493.6,1028.1c0,0,9.9-14.9,13.1-12.9c3.4,2.2-2.5,12.8-6.1,15.1C2497,1032.5,2493.8,1031.4,2493.6,1028.1
                    z"/>
                <path className="st52" d="M2484.1,1018c0,0,5.1,3.3,6.4,8.7c1.4,5.4-1.9,9.7-1.9,9.7l5.1-1.9c0,0,2.3-4.1,2-7.5
                    c-0.5-5.2-3.4-7.9-3.4-7.9L2484.1,1018z"/>
            </g>
        </g>
    </g>
    </svg>
    )
}

export default SmartFactory